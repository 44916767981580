import React, { useEffect, useState } from 'react';
import ReportService from "../api/reportService"; 

import { ValidatorForm, TextValidator,SelectValidator } from 'react-material-ui-form-validator';
import { makeStyles,useTheme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { Button, MenuItem, TextField } from '@material-ui/core';
import _ from 'lodash'
import { ToastContainer, toast } from "react-toastify";
import { DataGrid } from '@material-ui/data-grid';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/Edit';
import axios from 'axios';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import GetAppIcon from '@material-ui/icons/GetApp';
import reportService from '../api/reportService';
import Modal from '@material-ui/core/Modal';
import {
  Typography
 
} from "@material-ui/core";

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}



const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;


const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
      position: 'absolute',
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      width: 600,
    },
  }));
  




export default function Reports() {

  const classes = useStyles();
  const theme = useTheme();
  const [rows,setRows] = useState([]);
  const [selectedSensors, setSelectedSensors] = React.useState([]);
  const [finalRows,setFinalRows] = useState([]);
  const [sensors,setSensors] = useState([]);
  const [isAdmin,setIsAdmin] = useState(false);
  const [clients,setClients] = useState([]);
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);
  const [file,setFile] = useState();
  const [currentClient,setCurrentClient] = useState("");
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const uploadFile = ()=>{
      if(!file){
          alert("Please Choose file")
          return false;
      }
      if(currentClient === ''){
        alert("Please Select Client")
        return false;
    }
      const userName = sessionStorage.getItem("user_name");
        const formData = new FormData();
        formData.append("file",file);
        formData.append("client",currentClient);
      const options = {
          headers: {"user_name": userName}
      }
      axios.post("/api/v1/reports/upload", formData,options).then((responseData)=>{
            if(responseData.status === 200){
              fetechAllScalingFactors()
            }
        });
      }


      const download = (id)=>{
        window.open("/api/v1/reports/"+id+"/file/download");
    }

    const deleteFile = (id)=>{
      ReportService.deleteFile(id).then((responseData)=>{
          fetechAllScalingFactors();
        })
    }
  
  
    

  const columns = [
      { field: 'id', headerName: 'ID', width: 90 },
      { field: 'fileName', headerName: 'File Name', width: 400 },
      { field: 'uploadedDate', headerName: 'Uploaded Date', width: 300 },
      { field: 'uploadedBy', headerName: 'Uploaded By', width: 300 },
      { field: 'client', headerName: 'Client', width: 300 },
    
      { field: 'Action', headerName: 'Action',flex: 0.3,renderCell: (params) =>(
          <React.Fragment>
              
              <GetAppIcon onClick = {(event)=>download(params.row.id)}  style = {{cursor :'pointer'}} fontSize = "small"/> 
              {params.row.canDelete && <DeleteOutlineIcon onClick = {(event)=>deleteFile(params.row.id)}  style = {{cursor :'pointer'}} fontSize = "small"/> }
              
              
          </React.Fragment>
         
          )},
      
    ];

  const fetechAllScalingFactors = ()=>{
      ReportService.getAll().then((responseData)=>{
        setRows(responseData.data);
        setFinalRows(responseData.data);
      })
  }

 
  const searchHandler = (event)=>{
      let searchString = event.target.value.toLowerCase();
      if(searchString!=''){
          const filtered = rows.filter(entry => Object.values(entry).some(val => typeof val === "string" && val.toLowerCase().includes(searchString)));
          setRows(filtered);
      }else{
         
          setRows(finalRows)
      }
     

  }
  const checkForIsAdmin = ()=>{
    reportService.checkForSystemAdmin().then((responseData)=>{
      setIsAdmin(responseData.data.isAdmin);
    })
  }

  const getAllClients = ()=>{
    reportService.getAllClients().then((responseData)=>{
        if(responseData.data.length ===1){
          setCurrentClient(responseData.data[0]);
        }
        setClients(responseData.data)
    })
  }

  useEffect(() => {
      fetechAllScalingFactors();
      checkForIsAdmin();
      getAllClients();
  }, [])
  const handleChange = (event) => {
      
      setSelectedSensors(event.target.value);
      const tempFilters = event.target.value;

      if(event.target.value.length===0){
          setRows(finalRows);
      }else{
          const tempRows = finalRows.filter(function (el)
          {
            return tempFilters.includes(el.sensorId);
          }
          );
          setRows(tempRows);
      }

     
  };

  const changeHandler = (event)=>{
    setCurrentClient(event.target.value);
  }

  const uploadChangeHandler = (event)=>{
    setFile(event.target.files[0])
  }

  const body = (
    <div style={modalStyle} className={classes.paper}>
    
      <h2 id="simple-modal-title">Upload Reports</h2>
      <Grid container spacing={1}>
        <Grid item xs={12}>
        <form className={classes.root} noValidate autoComplete="off">
          <p id="simple-modal-description">
              <input type = "file" name = "" onChange = {uploadChangeHandler} />
          </p>

        <TextField style = {{width:'300px'}}
          id="standard-select-currency"
          select
          label="Assign to clients"
          value={currentClient}
          onChange={changeHandler}
         
        >
          {clients.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>

        
        </form>
       </Grid>
       <Grid item xs={12}>
         <br/>
        <Button variant="contained" onClick = {uploadFile} color="primary">
            Upload
        </Button>

       </Grid>

    </Grid> 
      
     
      
      
    </div>
  );


  return <React.Fragment>

<Grid container spacing={1} style={{ padding: "22px 18px 0px 24px" }}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                <Typography
                  variant="h6"
                  gutterBottom
                  style={{ color: "#4E98B9", fontSize: "20px" }}
                >
                  Reports
                </Typography>
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <div
          style={{
            flexGrow: 1,
            display: "flex",
            height: "-webkit-fill-available",
          }}
        >
          <div style={{ width: "100%" }}>
            <div
              style={{
                backgroundColor: "white",
                marginTop: "1%",
                marginLeft: "1%",
                height: "100%",
                boxShadow:
                  "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
              }}
            >
             
         <Grid container spacing={3} style = {{marginLeft:'12px'}}>
                  
                  <Grid item xs={3}>
                      <FormControl className={classes.formControl} style = {{width:'100%'}}>
                          
                           <TextField id="standard-basic" onChange = {searchHandler} label = "Search"/>
                       </FormControl>
                  </Grid>  
                  <Grid item xs={3}>
                      <Button style = {{marginTop:'12px'}} variant="contained" color="primary" onClick = {handleOpen}>Upload Report</Button>
                  </Grid> 
                 
                  
          </Grid>   
         
          <div style={{ height: 400, padding:'15px',marginTop:'40px' }}>
               <DataGrid rows={rows} columns={columns} pageSize={5}  />

               <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
          </div>
          </div>
          </div></div>
  </React.Fragment> 

}
