import {
  Button,
  Card,
  CardContent,
  Chip,
  Container,
  Drawer,
  Grid
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {
  DataGrid,
  GridToolbarDensitySelector,
  GridToolbarFilterButton
} from '@material-ui/data-grid';
import { Typography } from 'antd';

import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import SettingsService from '../../settings/SettingsService';
import moment from 'moment';
import Moment from 'react-moment';
const ReactHighcharts = require('react-highcharts');
require('highcharts-more')(ReactHighcharts.Highcharts);

const useStyles = makeStyles({
  table: {
    minWidth: 400,
    width: '90%',
    marginLeft: '12px',
    marginBottom: '48px'
  }
});

const calculateTimeRange = time => {
  let timeFormat = 'HH:mm';

  if (time === '14d' || time === '30d') {
    return 'MM/DD HH:mm';
  }
  return timeFormat;
};

const RenderDawer = props => {
  const { data, toggle, open } = props;
  const [singleAlert, setSingleAlert] = useState([]);
  const [alertTimeLine, setAlertTimeLine] = useState([]);
  const [currentTime, setCurrentTime] = useState();
  const closeDrawer = () => {
    toggle();
  };
  useEffect(() => {
    if (data && data.alertId) {
      SettingsService.getSingleAlert(data).then(responseData => {
        if (responseData.data && responseData.data.alertHistory) {
          setCurrentTime(data.time);
          setSingleAlert(responseData.data.alertHistory);
          setAlertTimeLine(responseData.data.alertTimeLine);
        }
      });
    }
  }, [data]);

  return (
    <>
      <Drawer anchor="right" open={open} onClose={toggle}>
        <div style={{ width: '1000px' }}>
          <Container maxWidth="xl" style={{ width: '100%', marginTop: '12px' }}>
            Alert Details
          </Container>

          <Card variant="outlined">
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography
                  style={{ marginLeft: '25px', marginTop: '12px' }}
                  variant="srOnly"
                  component="h6"
                >
                  <strong>Name </strong> :{' '}
                  <span style={{ fontWeight: '400' }}>{data.name}</span>
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  style={{ marginLeft: '25px', marginTop: '12px' }}
                  variant="srOnly"
                  component="h6"
                >
                  <strong>Time </strong> :{' '}
                  <span style={{ fontWeight: '400' }}>
                    <Moment format="HH:mm:ss MMM DD YYYY">{data.time}</Moment>
                  </span>
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  style={{ marginLeft: '25px', marginTop: '12px' }}
                  variant="srOnly"
                  component="h6"
                >
                  <strong>Priority </strong> :{' '}
                  {data && data.priority === 'High' && (
                    <Chip
                      variant="outlined"
                      label={data.priority}
                      style={{ color: 'orange' }}
                    />
                  )}
                  {data && data.priority === 'Critical' && (
                    <Chip
                      variant="outlined"
                      label={data.priority}
                      style={{ color: 'red' }}
                    />
                  )}
                  {data && data.priority === 'Medium' && (
                    <Chip
                      variant="outlined"
                      label={data.priority}
                      style={{ color: 'yellow' }}
                    />
                  )}
                  {data && data.priority === 'Low' && (
                    <Chip
                      variant="outlined"
                      label={data.priority}
                      style={{ color: 'blue' }}
                    />
                  )}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  style={{ marginLeft: '25px', marginTop: '12px' }}
                  variant="srOnly"
                  component="h6"
                >
                  <strong>Client </strong> :{' '}
                  <span style={{ fontWeight: '400' }}>{data.client}</span>
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  style={{ marginLeft: '25px', marginTop: '12px' }}
                  variant="srOnly"
                  component="h6"
                >
                  <strong>Parameter </strong> :{' '}
                  <span style={{ fontWeight: '400' }}>
                    {data.agg}({data.parameterName})
                  </span>
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  style={{ marginLeft: '25px', marginTop: '12px' }}
                  variant="srOnly"
                  component="h6"
                >
                  <strong>Value </strong> :{' '}
                  <span style={{ fontWeight: '400' }}>
                    {data.parameterValue}
                  </span>
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <Typography
                  style={{ marginLeft: '25px', marginTop: '12px' }}
                  variant="srOnly"
                  component="h6"
                >
                  <strong>Time </strong> :{' '}
                  <span style={{ fontWeight: '400' }}>
                    {data.timeValue} {data.timeUnit}
                  </span>
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  style={{ marginLeft: '25px', marginTop: '12px' }}
                  variant="srOnly"
                  component="h6"
                >
                  <strong>Notification </strong> :{' '}
                  <span style={{ fontWeight: '400' }}>{data.email}</span>
                </Typography>
              </Grid>
            </Grid>

            <RenderAlertTimeLine
              data={alertTimeLine}
              currentTime={currentTime}
            />

            <RenderAlertHistory tableData={singleAlert} />
          </Card>
        </div>
      </Drawer>
    </>
  );
};

const RenderAlertTimeLine = props => {
  const { data, currentTime } = props;
  const dateFormt = calculateTimeRange('30m');
  const chartConfig = {
    chart: {
      height: 300
    },
    title: {
      text: 'Alert Timeline'
    },
    subtitle: {
      text: ''
    },
    xAxis: {
      crosshair: true,
      // tickmarkPlacement: "on",
      type: 'datetime',
      plotLines: [
        {
          color: '#FF0000',
          width: 2,
          value: currentTime
        }
      ],
      labels: {
        formatter: function () {
          var monthStr = ReactHighcharts.Highcharts.dateFormat(
            '%e. %b %H:%M',
            this.value
          );
          return monthStr;
        }
      }
    },
    yAxis: {
      floor: 0,
      allowDecimals: false,
      title: {
        enabled: false,
        text: ''
      },
      labels: {
        formatter: function () {
          var monthStr = moment(this.value).format(dateFormt);
          return monthStr;
        }
      }
    },

    tooltip: {
      valueDecimals: 0
    },
    plotOptions: {
      series: {
        marker: {
          enabled: false,
          symbol: 'circle'
        },
        connectNulls: false
      }
    },
    series: []
  };
  if (data) {
    chartConfig['series'] = data;
  }

  return (
    <ReactHighcharts config={chartConfig} class="chartCss"></ReactHighcharts>
  );
};

const RenderAlertHistory = props => {
  const { tableData } = props;
  const classes = useStyles();
  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            {tableData &&
              tableData.length > 0 &&
              Object.keys(tableData[0]).map(value => {
                return <TableCell>{value}</TableCell>;
              })}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableData &&
            tableData.map((row, index) => {
              return (
                <TableRow key={index}>
                  {Object.keys(row).map(value => {
                    if (value === 'value') {
                      return <TableCell>{row[value].toFixed()}</TableCell>;
                    }
                    return <TableCell>{row[value]}</TableCell>;
                  })}
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const RenderAlertData = props => {
  console.log('alerts>>>');
  const { data } = props;
  const [tempData, setTempData] = useState({});
  const [open, setOpen] = useState(false);

  const toggleDrawer = (event, tableData) => {
    event.preventDefault();
    setTempData(tableData);
    setOpen(true);
  };

  const toggle = () => {
    setOpen(!open);
  };

  const columns = [
    {
      field: 'time',
      headerName: 'Time',
      width: 250,
      renderCell: param => {
        return <Moment format="HH:mm:ss MMM DD YYYY">{param.row.time}</Moment>;
      }
    },
    {
      field: 'name',
      headerName: 'Name',
      width: 800,
      renderCell: param => {
        return (
          <a href="#" onClick={event => toggleDrawer(event, param.row)}>
            {param.row.name}
          </a>
        );
      }
    },
    {
      field: 'sensors',
      headerName: 'Sensor',
      width: 300,
      renderCell: param => {
        if (param.row.sensors) {
          if (param.row.sensors.length > 5) {
            return <span>{param.row.sensors.slice(0, 5).join(',')}</span>;
          } else {
            return <span>{param.row.sensors.join(',')}</span>;
          }
        }
      }
    },

    { field: 'client', headerName: 'Client', width: 150 },

    {
      field: 'priority',
      headerName: 'Priority',
      width: 150,

      renderCell: param => {
        if (param.row.priority === 'High') {
          return (
            <Chip
              variant="outlined"
              label={param.row.priority}
              style={{ color: 'orange' }}
            />
          );
        } else if (param.row.priority === 'Critical') {
          return (
            <Chip
              variant="outlined"
              label={param.row.priority}
              style={{ color: 'red' }}
            />
          );
        } else if (param.row.priority === 'Medium') {
          return (
            <Chip
              variant="outlined"
              label={param.row.priority}
              style={{ color: 'yellow' }}
            />
          );
        } else if (param.row.priority === 'Low') {
          return (
            <Chip
              variant="outlined"
              label={param.row.priority}
              style={{ color: 'blue' }}
            />
          );
        }
      }
    }
  ];
  return (
    <Container
      maxWidth="xl"
      style={{ height: 700, width: '100%', marginTop: '12px' }}
    >
      <DataGrid
        pageSize={10}
        rowsPerPageOptions={[10]}
        rows={data}
        columns={columns}
        disableColumnFilter={false}
      />
      <RenderDawer data={tempData} open={open} toggle={toggle} />
    </Container>
  );
};

export default RenderAlertData;
