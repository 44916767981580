import {
  Card,
  CardHeader,
  FormControl,
  Grid,
  makeStyles,
  MenuItem,
  Select,
} from "@material-ui/core";
import _ from "lodash";
import { useState, useEffect } from "react";
import dashboardService from "../../dashboard/dashboard-service";
const ReactHighcharts = require("react-highcharts");
require("highcharts-more")(ReactHighcharts.Highcharts);
const chartConfig = {
  chart: {
    height: 300,
  },
  title: {
    text: "",
  },
  subtitle: {
    text: "",
  },
  xAxis: {
    crosshair: true,
    // tickmarkPlacement: "on",
    type: "datetime",
    labels: {
      formatter: function () {
        var monthStr = ReactHighcharts.Highcharts.dateFormat(
          "%e. %b %H:%M",
          this.value
        );
        return monthStr;
      },
    },
  },
  yAxis: {
    floor: 0,
    allowDecimals: false,
    title: {
      enabled: false,
      text: "",
    },
    labels: {
      format: "{value} ",
      style: {
        color: ReactHighcharts.Highcharts.getOptions().colors[0],
      },
    },
  },

  tooltip: {
    valueDecimals: 0,
  },
  plotOptions: {
    series: {
      marker: {
        enabled: false,
        symbol: "circle",
      },
      connectNulls: true,
    },
  },
  series: [],
};
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 440,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const RenderTimeSeriesDND = () => {
  const [time, setTime] = useState("24h");
  const [config, setConfig] = useState(chartConfig);
  const classes = useStyles();
  useEffect(() => {
    const data = { time: time };
    dashboardService.getDNDRealTimeData(data).then((responseData) => {
      const data = Object.keys(responseData.data);

      const tempData = [];

      tempData.push({ name: "mode_size", data: responseData.data.mode_size });
      tempData.push({
        name: "total_concentration",
        data: responseData.data.total_concentration,
      });
      const tempValue = _.cloneDeep(chartConfig);
      tempValue.series = tempData;
      setConfig(tempValue);
    });
  }, []);

  const getDNDRealTimeData = (time) => {
    const data = { time: time.target.value };
    setTime(time.target.value);
    dashboardService.getDNDRealTimeData(data).then((responseData) => {
      const data = Object.keys(responseData.data);

      const tempData = [];

      tempData.push({ name: "mode_size", data: responseData.data.mode_size });
      tempData.push({
        name: "total_concentration",
        data: responseData.data.total_concentration,
      });
      const tempValue = _.cloneDeep(chartConfig);
      tempValue.series = tempData;
      setConfig(tempValue);
    });
  };

  return (
    <Card variant="outlined" style={{ height: "350px" }}>
      <form className={classes.form} noValidate>
        <span>Mode vs total concentration</span>
        <FormControl style={{ float: "right" }} className={classes.formControl}>
          <Select
            autoFocus
            onChange={getDNDRealTimeData}
            inputProps={{
              name: "max-width",
              id: "max-width",
            }}
            placeholder="Select date"
            value={time}
          >
            <MenuItem value="30m">Last 30 mins</MenuItem>
            <MenuItem value="1h">Last 1 hour</MenuItem>
            <MenuItem value="4h">Last 4 hours</MenuItem>
            <MenuItem value="6h">Last 6 hours</MenuItem>
            <MenuItem value="24h">Last 24 hours</MenuItem>
            <MenuItem value="14d">Last 14 days</MenuItem>
            <MenuItem value="30d">Last 30 days</MenuItem>
          </Select>
        </FormControl>
      </form>

      <ReactHighcharts config={config} class="chartCss"></ReactHighcharts>
    </Card>
  );
};

export default RenderTimeSeriesDND;
