// @ts-nocheck

import { G2, Heatmap } from '@ant-design/charts';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import analyticsService from '../api/analyticsService';
import ColorScale from './ColorScale';

G2.registerShape('polygon', 'boundary-polygon', {
  draw: function draw(cfg, container) {
    var group = container.addGroup();
    var attrs = {
      stroke: '#85144b',
      lineWidth: 1,
      fill: cfg.color,
      path: []
    };
    var points = cfg.points;
    var path = [
      ['M', points[0].x, points[0].y],
      ['L', points[1].x, points[1].y],
      ['L', points[2].x, points[2].y],
      ['L', points[3].x, points[3].y],
      ['Z']
    ];
    attrs.path = this.parsePath(path);
    group.addShape('path', { attrs: attrs });
    if (cfg.data.lastWeek) {
      var linePath = [
        ['M', points[2].x, points[2].y],
        ['L', points[3].x, points[3].y]
      ];
      group.addShape('path', {
        attrs: {
          path: this.parsePath(linePath),
          lineWidth: 4,
          stroke: '#404040'
        }
      });
      if (cfg.data.lastDay) {
        group.addShape('path', {
          attrs: {
            path: this.parsePath([
              ['M', points[1].x, points[1].y],
              ['L', points[2].x, points[2].y]
            ]),
            lineWidth: 4,
            stroke: '#404040'
          }
        });
      }
    }
    return group;
  }
});

const DemoHeatmapHours2 = props => {
  const { data } = props;
  const [trendsData, setTrendsData] = useState({});
  const [filterData, setFilterData] = useState([]);
  const [loading, setLoading] = useState(false);
  const onInputChange = (min, max) => {
    const tempData = _.filter(trendsData, function (o) {
      return o[data.parameter] >= min && o[data.parameter] <= max;
    });
    setFilterData(tempData);
  };
  useEffect(() => {
    var res2 = [];
    const finalData = data;
    finalData['type'] = 'weekelyHeat';
    setLoading(true);
    analyticsService.getTrends(finalData).then(responseData => {
      for (const [key, value] of Object.entries(
        responseData.data.heatMapResponse
      )) {
        var rObj2 = {};
        var extractDateHr = key.split(' ');
        // var eWeekNumber = extractDateHr[1];
        // var yHr = extractDateHr[2];

        // eslint-disable-next-line no-useless-concat
        rObj2['dayNumber'] = extractDateHr[1];
        rObj2['hour'] = extractDateHr[2];
        rObj2[data.parameter] = parseInt(value);
        // rObj2["day"] = value;
        res2.push(rObj2);
      }
      setTrendsData(res2);
      setFilterData(res2);
    });
  }, [data]);

  var config = {
    data: filterData,
    height: 500,
    autoFit: false,
    xField: 'dayNumber',
    legend: false,
    yField: 'hour',
    colorField: data.parameter,
    reflect: 'y',
    color: ['#060CB1', '#1792FF', '#5BFF99', '#FBF115', '#B61511'],
    shape: 'boundary-polygon',
    meta: {
      dayNumber: {
        type: 'cat',
        values: ['1', '2', '3', '4', '5', '6', '7']
      },
      hour: {
        type: 'cat',
        values: [
          '0',
          '1',
          '2',
          '3',
          '4',
          '5',
          '6',
          '7',
          '8',
          '9',
          '10',
          '11',
          '12',
          '13',
          '14',
          '15',
          '16',
          '17',
          '18',
          '19',
          '20',
          '21',
          '22',
          '23'
        ]
      }
    },
    tooltip: {
      title: 'date',
      fields: [data.parameter],
      showMarkers: false
    },

    xAxis: {
      position: 'top',
      tickLine: null,
      line: null,
      label: {
        offset: 12,
        style: {
          fontSize: 12,
          fill: '#666',
          textBaseline: 'top'
        },
        formatter: function formatter(weekDay) {
          // console.log(typeof weekDay);
          if (weekDay == 1) {
            return 'Monday';
          } else if (weekDay == 2) {
            return 'Tuesday';
          } else if (weekDay == 3) {
            return 'Wednesday';
          } else if (weekDay == 4) {
            return 'Thursday';
          } else if (weekDay == 5) {
            return 'Friday';
          } else if (weekDay == 6) {
            return 'Saturday';
          } else if (weekDay == 7) {
            return 'Sunday';
          }
        }
      }
    }
  };

  return (
    <>
      <ColorScale
        data={trendsData}
        filterData={filterData}
        parameter={data.parameter}
        onInputChange={onInputChange}
      />
      <Heatmap {...config} />
    </>
  );
};

export default DemoHeatmapHours2;
