import { arrayIncludes } from '@material-ui/pickers/_helpers/utils';
import chroma from 'chroma-js';
import _ from 'lodash';
import * as React from 'react';
import './analytics.css';
const ColorScale = props => {
  const { data, parameter, onInputChange } = props;
  const [colors, setColors] = React.useState([]);
  const [minValue, setMinValue] = React.useState(0);
  const [maxValue, setMaxValue] = React.useState(0);

  const chagneHandler = (event, type) => {
    if (type === 'min' && event.target.value != '') {
      onInputChange(parseInt(event.target.value), maxValue);
      setMinValue(parseInt(event.target.value));
    } else if (event.target.value != '') {
      onInputChange(minValue, parseInt(event.target.value));
      setMaxValue(parseInt(event.target.value));
    }
  };
  React.useEffect(() => {
    if (Array.isArray(data)) {
      const tempData = data.map(value => value[parameter]);
      let minValue = _.min(tempData);
      let maxValue = _.max(tempData);

      const particale_color_scale = chroma
        .scale(['#060CB1', '#1792FF', '#5BFF99', '#FBF115', '#B61511'])
        .domain([minValue, maxValue], 1, 'log');
      const tempColors = particale_color_scale.colors(50);
      setColors(tempColors);
      setMinValue(minValue);
      setMaxValue(maxValue);
    }
  }, [data, parameter]);

  return (
    <div className="gradient" style={{ marginLeft: '39%' }}>
      {colors.map(value => {
        return (
          <span
            className="grad-step"
            style={{ background: `${value}`, width: getWidth(colors.length) }}
          ></span>
        );
      })}
      <input
        onChange={event => chagneHandler(event, 'min')}
        type="number"
        name="colorMin"
        value={minValue}
        style={{ width: '50px' }}
        className="domain-min"
      />
      <input
        onChange={event => chagneHandler(event, 'max')}
        name="colorMax"
        type="number"
        value={maxValue}
        style={{ width: '52px' }}
        className="domain-max"
      />
    </div>
  );
};

const getWidth = numberOfPoints => {
  if (numberOfPoints < 10) {
    return 13;
  }
  if (numberOfPoints < 10 && numberOfPoints >= 20) {
    return 10;
  }
  if (numberOfPoints > 1) {
    return 5;
  }
};

export default ColorScale;
