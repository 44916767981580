import {
  Checkbox,
  Chip,
  FormControl,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Select
} from '@material-ui/core';
import _ from 'lodash';
import { useEffect, useState } from 'react';

const RenderGeoMapSensors = props => {
  const { data, onGeoMapSensorChange } = props;

  const onSensorChange = event => {
    setSelected(event.target.value);
    onGeoMapSensorChange(event.target.value);
  };

  const [selected, setSelected] = useState([]);
  const [serialNumber, setSerialNumber] = useState([]);

  useEffect(() => {
    if (data.geoMap) {
      var serialNumbers = _.mapValues(
        _.groupBy(data.geoMap, 'serial_number'),
        clist => clist.map(car => _.omit(car, 'serial_number'))
      );
      setSerialNumber(Object.keys(serialNumbers));
    }
  }, [data.geoMap]);

  return (
    <FormControl fullWidth>
      <InputLabel>Sensors</InputLabel>
      {data && data.geoMap && (
        <Select
          name="rangetype"
          onChange={onSensorChange}
          value={selected}
          multiple
          nput={<Input />}
          renderValue={selected => (
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {selected.map(value => (
                <Chip
                  color={'primary'}
                  key={value}
                  label={value}
                  size="small"
                  style={{ margin: '1px' }}
                />
              ))}
            </div>
          )}
        >
          {serialNumber.map(client => (
            <MenuItem key={client} value={client} fullWidth>
              <Checkbox checked={selected.indexOf(client) > -1} />
              <ListItemText primary={client} />
            </MenuItem>
          ))}
        </Select>
      )}
    </FormControl>
  );
};

export default RenderGeoMapSensors;
