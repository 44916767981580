import React, { useState } from 'react';
import {
  TextField,
  Typography,
  Button,
  Paper,
  makeStyles
} from '@material-ui/core';
import RegisterFormData from './RegiserFormData';
import { authenticationService } from './service/authenticatioin-service';
import deviceService from '../api/deviceService';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

const useStyles = makeStyles(theme => ({
  backImg: {
    backgroundImage: "url('./img/dark_background.jpg')"
  }
}));
export default function Register(props) {
  const customClasses = useStyles();
  const registerData = {
    userName: '',
    password: '',
    confirmpassword: '',
    firstName: '',
    lastName: '',
    serialNumber: ''
  };

  const formRegisterData = new RegisterFormData(registerData);

  const [formData, setFormData] = useState(formRegisterData);
  const [registerpage, setRegisterPage] = useState(true);
  const [verificationCode, setVerificationCode] = useState(null);
  const registerUser = data => {
    data.preventDefault();
    if (formData.password === formData.confirmpassword) {
      authenticationService.register(
        formData.userName,
        formData.password,
        formData.firstName,
        formData.lastName
      );
    } else {
      alert('Passwords are not matching');
    }
  };

  const handleRegisterFormChange = event => {
    const newFormData = Object.assign({}, formData);
    newFormData[event.target.name] = event.target.value;
    setFormData(newFormData);
  };

  return (
    <div id="login" className={customClasses.backImg}>
      <div style={{ paddingRight: '5%', marginBottom: '3%' }}>
        <div className="absolutePositionCss">
          <span>
            <img src="./img/telosair_white.png" alt="Telosair" />
          </span>
        </div>
        <div className="mt-mr">
          <span className="deeperCss">Deeper Insights |</span>
          <span className="healthierCss"> Healthier Air</span>
        </div>
      </div>
      <Paper elevation={3} class="cardHeight">
        <form onSubmit={registerUser}>
          <Typography class="welcomeCss">
            <b>Register </b>
          </Typography>
          <div class="userNameCss">
            <TextField
              variant="standard"
              label="Email"
              fullWidth
              name="userName"
              onChange={handleRegisterFormChange}
            />
          </div>
          <div class="userNameCss">
            <TextField
              type="password"
              variant="standard"
              label="Password"
              fullWidth
              name="password"
              required
              onChange={handleRegisterFormChange}
            />
          </div>
          <div class="userNameCss">
            <TextField
              type="password"
              variant="standard"
              label="Re-Enter Password"
              fullWidth
              required
              name="confirmpassword"
              onChange={handleRegisterFormChange}
            />
          </div>

          <div class="userNameCss">
            <TextField
              required
              variant="standard"
              label="First Name"
              fullWidth
              name="firstName"
              onChange={handleRegisterFormChange}
            />
          </div>
          <div class="userNameCss">
            <TextField
              required
              variant="standard"
              label="Last Name"
              fullWidth
              name="lastName"
              onChange={handleRegisterFormChange}
            />
          </div>
          <br />
          <div class="userNameCss">
            <Link to="/login">
              <Button
                style={{ float: 'left' }}
                href="login"
                type="button"
                variant="contained"
                color="primary"
              >
                Back
              </Button>
            </Link>
            &nbsp;
            <Button
              variant="contained"
              color="primary"
              type="submit"
              className="pull-right"
            >
              Register
            </Button>
          </div>
        </form>
        <br />
      </Paper>
      {/* <Paper elevation={3} class="cardHeight">
                <form onSubmit={verifyUser}>
                    {" "}
                    <Typography class="welcomeCss">
                        <b> Confirmation Code </b>
                    </Typography>
                    <p>Please check your email for the validation code and enter it here:</p>
                    <div class="userNameCss">
                        <TextField
                            variant="standard"
                            label="code"
                            fullWidth
                            name="verificationCode"
                            onChange={(event) => setVerificationCode(event.target.data)}
                        />
                    </div>
                    <br />
                    <div class="userNameCss">
                        <Link to="/register">
                            <Button style={{ float: "left" }} type="button" variant="contained" color="primary">
                                Back
                            </Button>
                        </Link>
                        &nbsp;
                        <Button variant="contained" color="primary" type="submit" className="pull-right">
                            Verify
                        </Button>
                    </div>
                </form>
                <br />
            </Paper>
            )} */}
      <div></div>
    </div>
  );
}
