import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserPool
} from 'amazon-cognito-identity-js';
import { ClientId, DEV_DOMAIN_URL, UserPoolId } from './../../environment';

import { BehaviorSubject } from 'rxjs';
import Register from './../register';
import axiosInstance from '../../_helpers/interceptor';
import deviceService from '../../api/deviceService';

const currentUserSubject = new BehaviorSubject(
  JSON.parse(localStorage.getItem('currentUser'))
);

const poolData = {
  UserPoolId: UserPoolId,
  ClientId: ClientId
};

const userPool = new CognitoUserPool(poolData);

var AmazonCognitoIdentity = require('amazon-cognito-identity-js');
var cognitoUser;
export const authenticationService = {
  login,
  logout,
  register,
  confirmuser,
  resendCode,
  forgotPassword,
  restPassword,
  currentUser: currentUserSubject.asObservable(),
  get currentUserValue() {
    return currentUserSubject.value;
  }
};

function login(username, password) {
  sessionStorage.clear();
  const authenticationData = {
    Username: username,
    Password: password
  };
  const authenticatinDetails = new AuthenticationDetails(authenticationData);

  const userData = {
    Username: username,
    Pool: userPool
  };

  const cognitoUser = new CognitoUser(userData);

  cognitoUser.authenticateUser(authenticatinDetails, {
    onSuccess: function (result) {
      localStorage.setItem('auth_token', result.idToken.jwtToken);
      loginToApplication(username, password, result);
    },
    onFailure: function (error) {
      alert(error.message);
    },
    newPasswordRequired: function (userAttributes, result) {
      userAttributes['name'] = username;
      cognitoUser.completeNewPasswordChallenge(password, userAttributes, {
        onSuccess: result => {
          localStorage.setItem('auth_token', result.accessToken);
          loginToApplication(username, password, result);
        },
        onFailure: err => {
          //obs.error(err);
        }
      });
    }
  });
}

function loginToApplication(userName, password, result) {
  const data = { userName: userName, password: password };
  axiosInstance.post('/custom/login', data).then(responseData => {
    //axiosInstance.post('/backend/custom/login', data).then(responseData => {
    if (responseData.status === 200) {
      sessionStorage.setItem('userData', JSON.stringify(result));
      sessionStorage.setItem('user_name', userName);
      let filterDataTemp = {
        clients: [],
        facility: [],
        zones: [],
        rooms: [],
        sensors: [],
        selectedTime: '24hrs',
        comparisionOption: 'Particle Concentration',
        floorRangeType: 'Temperature',
        timeSeries: ['particleConcentration'],
        timeSeriesType: 'pn03_m',
        detailed: [],
        selectedSerialNumber: 0
      };
      localStorage.setItem('filters', JSON.stringify(filterDataTemp));
      localStorage.setItem('timeTrendsFilters', JSON.stringify(filterDataTemp));
      window.location.href = '/dashboard';
    }
  });
}

function register(username, password, firstName, lastName) {
  const attributeList = [];
  var dataName = {
    Name: 'name',
    Value: firstName + ' ' + lastName
  };

  var dataEmail = {
    Name: 'email',
    Value: username
  };

  var attributeName = new AmazonCognitoIdentity.CognitoUserAttribute(dataName);
  var attributeEmail = new AmazonCognitoIdentity.CognitoUserAttribute(
    dataEmail
  );

  attributeList.push(attributeName);
  attributeList.push(attributeEmail);

  userPool.signUp(username, password, attributeList, null, (err, result) => {
    if (err) {
      alert(err.message);
      return false;
    } else {
      cognitoUser = result.user;
      window.location.href = '/verification-code?username=' + username;
    }
  });
}

function logout() {
  var userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
  var userData = {
    Username: 'username',
    Pool: userPool
  };

  var cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
  userPool.localStorage // remove user from local storage to log user out
    .removeItem('currentUser');
  currentUserSubject.next(null);
}

function forgotPassword(username) {
  // var userPool = new AmazonCognitoIdentity.CognitoUser(poolData);
  var userData = {
    Username: username,
    Pool: userPool
  };

  const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
  cognitoUser.forgotPassword({
    onSuccess: function (data) {
      // successfully initiated reset password request
      console.log('CodeDeliveryData from forgotPassword: ' + data);
    },
    onFailure: function (err) {
      alert(err.message || JSON.stringify(err));
    },
    //Optional automatic callback
    inputVerificationCode: function (data) {
      window.location.href = '/forgot-password?username=' + username;
    }
  });
}
function restPassword(username, code, password) {
  var userData = {
    Username: username,
    Pool: userPool
  };
  const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);

  cognitoUser.confirmPassword(code, password, {
    onSuccess() {
      window.location.href = '/login';
    },
    onFailure(err) {
      alert('Something went wrong, Please try again ');
    }
  });
}

function confirmuser(code, username) {
  const user = {
    Username: username,
    Pool: userPool
  };
  const cognitoUser = new AmazonCognitoIdentity.CognitoUser(user);
  const data = { userName: username };
  cognitoUser.confirmRegistration(code, true, function (err, result) {
    if (err) {
      alert('wrong');
    } else {
      deviceService
        .registerUser(data)
        .then(responseData => {
          if (responseData.status === 200) {
            window.location.href = '/login';
          }
        })
        .catch(e => {
          alert('unable to register user!!');
        });
    }
  });
}

function resendCode(username) {
  const user = {
    Username: username,
    Pool: userPool
  };

  const cognitoUser = new AmazonCognitoIdentity.CognitoUser(user);
  cognitoUser.resendConfirmationCode(function (err, result) {
    if (err) {
      alert(err.message || JSON.stringify(err));
      return;
    } else {
      alert(result);
    }
  });
}
