import React, { useEffect, useState } from 'react';
import SettingsService from '../../settings/SettingsService';
import dayjs from 'dayjs';
import { TimePicker } from 'antd';

import {
  ValidatorForm,
  TextValidator,
  SelectValidator
} from 'react-material-ui-form-validator';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {
  Button,
  Container,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@material-ui/core';
import _ from 'lodash';
import { toast } from 'react-toastify';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { subHours } from 'date-fns';
import { Checkbox } from '@mui/material';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary
  }
}));

const RenderAlertForm = props => {
  const classes = useStyles();

  const [formData, setFormData] = useState({
    id: 0,
    alertName: '',
    alertDescription: '',
    priority: '',
    sensorNumber: 0,
    parameterValue: '',
    parameterName: '',
    aggType: 'avg',
    timeUnit: 'Hours',
    clientName: '',
    groupBy: '',
    operator: '',
    mobileNumber: '',
    reportDataMissing: false,
    alertType: 'threshold',

    fromValue: '',
    toValue: '',
    alertSupressionTime: '1',
    alertSupressionCount: '1',
    timeValue: '1',
    sensors: ['0'],
    days: ['-1'],
    createLogEntry: true
  });

  const [devices, setDevices] = useState([]);

  const [clients, setClients] = useState([]);

  const [allClients, setAllClients] = useState([]);

  const [timeString, setTimeString] = useState([]);

  const [timeRange, setTimeRange] = useState([]);

  const timeChangeHandler = (time, stringTime) => {
    setTimeString(stringTime);
    setTimeRange(time);
  };

  useEffect(() => {
    //fetchDevices();

    if (props.editData.id) {
      fetchAllClients(props.editData.clientName);

      const tempData = props.editData;
      if (tempData.deviceId) {
        tempData['sensors'] = tempData.deviceId.split(',');
      } else {
        tempData['sensors'] = ['0'];
      }
      if (tempData.dayOfWeek) {
        tempData['days'] = tempData.dayOfWeek.split(',');
      } else {
        tempData['days'] = ['0'];
      }
      tempData['createLogEntry'] =
        props.editData.createLogEntry &&
        props.editData.createLogEntry === 'true';
      if (tempData.startDate && tempData.endTime) {
        const startDate = '11/23/2023 ' + tempData.startDate;
        const endDate = '11/23/2023 ' + tempData.endTime;
        setTimeRange([
          dayjs(startDate, 'HH:mm:ss'),
          dayjs(endDate, 'HH:mm:ss')
        ]);
        console.log(dayjs(startDate, 'HH:mm:ss'));
      }

      setFormData(tempData);
    } else {
      fetchAllClients();
    }
  }, [props.editData]);

  const fetchAllClients = clientName => {
    SettingsService.getClients().then(responseData => {
      const data = _.uniqBy(responseData.data, 'client');
      setClients(data);
      if (clientName) {
        const allClientData = responseData.data;
        const sensors = _.filter(allClientData, {
          client: clientName
        });
        const tempDevices = _.uniqBy(sensors, 'serial_number');
        setDevices(tempDevices);
      }
      setAllClients(responseData.data);
    });
  };

  const handleSubmit = () => {
    if (formData.sensors.length >= 1) {
      formData['deviceId'] = formData.sensors
        .filter(p => p != '0')
        .join(',')
        .toString();
    } else {
      formData['deviceId'] = '0';
    }

    if (formData.days.length >= 1) {
      formData['dayOfWeek'] = formData.days.join(',').toString();
    } else {
      formData['dayOfWeek'] = '-1';
    }

    if (timeString && timeString.length == 2) {
      formData['startDate'] = timeString[0];
      formData['endTime'] = timeString[1];
    }
    SettingsService.saveAlerts(formData)
      .then(responseData => {
        if (responseData.data.status) {
          toast.success('Saved Successfully', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
        } else {
          toast.error('Unable to Save Data', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
        }
      })
      .catch(function (error) {
        toast.error(`unable to save the data ${error.response.data.message}`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
      });
  };

  let startDate = subHours(new Date(), 24).getTime();
  let endDate = new Date().getTime();

  if (localStorage.getItem('alertStartDate')) {
    startDate = localStorage.getItem('alertStartDate');
    endDate = localStorage.getItem('alertEndDate');
  }

  const handleChange = event => {
    const tempFormData = _.cloneDeep(formData);
    if (
      event.target.name === 'reportDataMissing' &&
      event.target.value === ''
    ) {
      tempFormData[event.target.name] = 'true';
    } else {
      tempFormData[event.target.name] = event.target.value.toString();
    }
    if (event.target.name === 'sensors') {
      if (event.target.value.indexOf('0') != -1) {
        tempFormData[event.target.name] = ['0'];
      } else {
        tempFormData[event.target.name] = event.target.value;
      }
    }

    if (event.target.name === 'days') {
      tempFormData[event.target.name] = event.target.value;
    }

    if (event.target.name === 'priority') {
      if (event.target.value === 'Critical') {
        tempFormData['timeValue'] = '1';
        tempFormData['timeUnit'] = 'Minutes';
      } else if (event.target.value === 'High') {
        tempFormData['timeValue'] = '10';
        tempFormData['timeUnit'] = 'Minutes';
      } else if (event.target.value === 'Medium') {
        tempFormData['timeValue'] = '1';
        tempFormData['timeUnit'] = 'Hours';
      } else if (event.target.value === 'Low') {
        tempFormData['timeValue'] = '4';
        tempFormData['timeUnit'] = 'Hours';
      }
    }
    if (
      event.target.name === 'parameterName' &&
      event.target.value === 'sensor_status'
    ) {
      tempFormData['timeValue'] = '30';
      tempFormData['timeUnit'] = 'Minutes';
    }
    if (event.target.name === 'clientName') {
      const sensors = _.filter(allClients, { client: event.target.value });
      const tempDevices = _.uniqBy(sensors, 'serial_number');
      setDevices(tempDevices);
    }
    if (event.target.name === 'createLogEntry') {
      tempFormData['createLogEntry'] = event.target.checked;
    }
    setFormData(tempFormData);
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={8} lg={8} md={8}>
          <h4 style={{ marginTop: '12px', marginLeft: '19px' }}>Create Rule</h4>
        </Grid>
        <Grid item xs={4} lg={4} md={4}>
          <Button
            style={{ float: 'right', marginTop: '12px', marginRight: '26px' }}
            variant="outlined"
            onClick={props.toggle}
            startIcon={<ArrowBackIcon />}
          >
            Back
          </Button>
        </Grid>
      </Grid>
      <hr />
      <Container
        maxWidth="xl"
        style={{ height: 600, width: '100%', marginTop: '12px' }}
      >
        <ValidatorForm onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <SelectValidator
                style={{ width: '50%' }}
                label="Client"
                onChange={event => handleChange(event)}
                name="clientName"
                value={formData.clientName}
                validators={['required']}
                errorMessages={['this field is required']}
              >
                {clients &&
                  clients.map(value => {
                    return (
                      <MenuItem value={value.client}>{value.client}</MenuItem>
                    );
                  })}
              </SelectValidator>
            </Grid>
            <Grid item xs={6}>
              <SelectValidator
                style={{ width: '50%' }}
                label="Sensors"
                onChange={event => handleChange(event)}
                name="sensors"
                value={formData.sensors}
                validators={['required']}
                errorMessages={['this field is required']}
                SelectProps={{ multiple: true }}
              >
                <MenuItem value="0">All</MenuItem>
                {devices &&
                  devices.map(value => {
                    return (
                      <MenuItem value={value.serial_number.toString()}>
                        {value.serial_number.toString()}
                      </MenuItem>
                    );
                  })}
              </SelectValidator>
            </Grid>

            <Grid item xs={6}>
              <SelectValidator
                style={{ width: '50%' }}
                label="Priority"
                onChange={event => handleChange(event)}
                name="priority"
                value={formData.priority}
                validators={['required']}
                errorMessages={['this field is required']}
              >
                <MenuItem value="Critical">Critical</MenuItem>
                <MenuItem value="High">High</MenuItem>
                <MenuItem value="Medium">Medium</MenuItem>
                <MenuItem value="Low">Low</MenuItem>
              </SelectValidator>
            </Grid>

            <>
              {formData.parameterName &&
                formData.parameterName != 'sensor_status' && (
                  <Grid item xs={6}>
                    <SelectValidator
                      style={{ width: '50%' }}
                      label="Aggregation"
                      onChange={event => handleChange(event)}
                      name="aggType"
                      value={formData.aggType}
                      validators={['required']}
                      errorMessages={['this field is required']}
                    >
                      <MenuItem value="avg">Mean</MenuItem>
                      {/* <MenuItem value="Median">Median</MenuItem> */}
                    </SelectValidator>
                  </Grid>
                )}
              <Grid item xs={6}>
                <SelectValidator
                  style={{ width: '50%' }}
                  label="Parameter Name"
                  onChange={event => handleChange(event)}
                  name="parameterName"
                  value={formData.parameterName}
                  validators={['required']}
                  errorMessages={['this field is required']}
                >
                  <MenuItem value="Pressure">Pressure</MenuItem>
                  <MenuItem value="Particle Concentration" selected>
                    Particle Concentration
                  </MenuItem>
                  <MenuItem value="Temperature">Temperature</MenuItem>
                  <MenuItem value="Relative Humidity">
                    Relative Humidity
                  </MenuItem>
                  <MenuItem value="CO2">CO2</MenuItem>
                  <MenuItem value="VOCs">VOCs</MenuItem>
                  <MenuItem value="pn25_m">PM2.5</MenuItem>
                  <MenuItem value="pm10_m">PM10</MenuItem>
                  <MenuItem value="sensor_status">Sensor status</MenuItem>
                </SelectValidator>
              </Grid>
              {formData.parameterName &&
                formData.parameterName != 'sensor_status' && (
                  <Grid item xs={6}>
                    <Grid container spacing={2}>
                      <Grid item xs={3}>
                        <TextValidator
                          style={{ width: '100%' }}
                          label="Lower threshold"
                          onChange={event => handleChange(event)}
                          name="fromValue"
                          value={formData.fromValue}
                          validators={['required']}
                          helperText="Enter the minimum threshold value to monitor"
                          errorMessages={['this field is required']}
                        ></TextValidator>
                      </Grid>
                      <Grid item xs={3}>
                        <TextValidator
                          style={{ width: '100%' }}
                          label="Higher threshold"
                          onChange={event => handleChange(event)}
                          name="toValue"
                          value={formData.toValue}
                          validators={['required']}
                          helperText="Enter the maximum threshold value to monitor"
                          errorMessages={['this field is required']}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                )}
            </>

            <Grid item xs={6}>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <TextValidator
                    style={{ width: '100%' }}
                    label="Time Window"
                    onChange={event => handleChange(event)}
                    name="timeValue"
                    value={formData.timeValue}
                    validators={['required']}
                    errorMessages={['this field is required']}
                    helperText="Aggregation time"
                  ></TextValidator>
                </Grid>
                <Grid item xs={3}>
                  <SelectValidator
                    style={{ width: '100%' }}
                    label="Aggregation Window"
                    onChange={event => handleChange(event)}
                    name="timeUnit"
                    value={formData.timeUnit}
                    validators={['required']}
                    errorMessages={['this field is required']}
                    helperText="Time units"
                  >
                    <MenuItem value="Seconds">Seconds</MenuItem>
                    <MenuItem value="Minutes">Minutes</MenuItem>
                    <MenuItem value="Hours">Hours</MenuItem>
                  </SelectValidator>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              {!_.isEqual(formData.priority, 'Low') && (
                <TextField
                  style={{ width: '50%' }}
                  label="Notification Email"
                  onChange={event => handleChange(event)}
                  name="notification"
                  value={formData.notification}
                  validators={['required']}
                  errorMessages={['this field is required']}
                  helperText="Enter comma separated email address to receive alerts"
                />
              )}
            </Grid>
            <Grid item xs={6}>
              {_.isEqual(formData.priority, 'Critical') && (
                <TextValidator
                  style={{ width: '50%' }}
                  label="Mobile Number"
                  onChange={event => handleChange(event)}
                  name="mobileNumber"
                  value={formData.mobileNumber}
                  validators={['required']}
                  errorMessages={['this field is required']}
                  helperText="Enter comma separated mobile numbers to receive alerts"
                />
              )}
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <RenderDateTimePicker
                    timeString={timeString}
                    timeChangeHandler={timeChangeHandler}
                    timeRange={timeRange}
                    days={formData.days}
                    changeHandler={handleChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formData.createLogEntry}
                        onChange={handleChange}
                        name="createLogEntry"
                      />
                    }
                    label="Log Entry in Actvity Log"
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={6} justifyContent="flex-end"></Grid>
            <Grid item xs={3} justifyContent="flex-end">
              <Button
                fullWidth
                variant="contained"
                color="primary"
                type="submit"
              >
                Save Alert
              </Button>
            </Grid>
          </Grid>

          {/* <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <TextValidator
                  style={{ width: '100%' }}
                  label="Minimum time between alerts in hours"
                  onChange={event => handleChange(event)}
                  name="alertSupressionTime"
                  value={formData.alertSupressionTime}
                  validators={['required']}
                  errorMessages={['this field is required']}
                ></TextValidator>
              </Grid>
            </Grid>
          </Grid> */}
          <br />
        </ValidatorForm>
      </Container>
    </div>
  );
};

const RenderDateTimePicker = ({
  timeRange,
  timeChangeHandler,
  days,
  changeHandler
}) => {
  return (
    <React.Fragment>
      <Grid container spacing={1}>
        <Grid item xs={3}>
          <TimePicker.RangePicker
            onChange={timeChangeHandler}
            value={timeRange}
            style={{ border: '0px', width: '100%' }}
            size="small"
          />
          <p className="MuiFormHelperText-root" style={{ marginTop: '12px' }}>
            Time period for active alerting
          </p>
        </Grid>
        <Grid item xs={8}>
          <FormControl style={{ width: '40%' }}>
            <InputLabel id="demo-mutiple-name-label">Days</InputLabel>
            <Select
              value={days}
              fullWidth
              name="days"
              onChange={event => changeHandler(event)}
              labelId="demo-mutiple-name-label"
              id="demo-mutiple-name"
              multiple
            >
              <MenuItem key="0" value="-1">
                All
              </MenuItem>
              <MenuItem key="Sunday" value="7">
                Sunday
              </MenuItem>
              <MenuItem key="Monday" value="1">
                Monday
              </MenuItem>
              <MenuItem key="Tuesday" value="2">
                Tuesday
              </MenuItem>
              <MenuItem key="Wednesday" value="3">
                Wednesday
              </MenuItem>
              <MenuItem key="Thrusday" value="4">
                Thrusday
              </MenuItem>
              <MenuItem key="Friday" value="5">
                Friday
              </MenuItem>
              <MenuItem key="Saturday" value="6">
                Saturday
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default RenderAlertForm;
