import './dashboard.css';
import 'leaflet/dist/leaflet.css';

import * as moment from 'moment-timezone';
import _ from 'lodash';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Input,
  InputLabel,
  ListItemText,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography
} from '@material-ui/core';
import {
  DatePicker,
  DateTimePicker,
  MuiPickersUtilsProvider,
  TimePicker
} from '@material-ui/pickers';

import RenderColorScale from './ColorRange';

import Alert from '@material-ui/lab/Alert';
import BarLoader from 'react-bar-loader';
import CircularProgress from '@material-ui/core/CircularProgress';
import DashboardService from './dashboard-service';
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import DescriptionIcon from '@material-ui/icons/Description';
import DetaildSeriesChart from './charts/DetaildSeriesChart';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import L from 'leaflet';
import Moment from 'react-moment';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import React from 'react';
import SettingsService from './../settings/SettingsService';
import TimeSeriesChart from './charts/TimeSeriesChart';
import { areaChart } from './charts/area-chart';
import { columnRange } from './charts/column-range';
import deviceService from '../api/deviceService';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons/faCaretDown';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons/faCaretRight';
import { fromUnixTime, subSeconds } from 'date-fns';
import h337 from 'heatmap.js';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { lineChart } from './charts/line-chart';
import subDays from 'date-fns/subDays';
import subHours from 'date-fns/subHours';
import subMilliseconds from 'date-fns/subMilliseconds';
import subMinutes from 'date-fns/subMinutes';
import dashboardService from './dashboard-service';
import { Link } from 'react-router-dom';
import GeoMap from './GeoMap';

import ComparisionChart from './charts/ComparisionChart';
import RenderGeoMapSensors from './GeoMapSensor';

const ReactHighcharts = require('react-highcharts');

require('highcharts-more')(ReactHighcharts.Highcharts);

var HighchartsExporting = require('highcharts-exporting');
HighchartsExporting(ReactHighcharts.Highcharts);

const temperature = () => Math.floor(Math.random() * (16 - 7 + 1)) + 7;

const flooData = [];
let yCor = 10;
let mainPointer = 80;
for (let i = 0; i < 30000; ++i) {
  mainPointer = mainPointer + 0.003;
  yCor = yCor + 0.003;

  flooData.push([mainPointer, yCor, temperature().toString()]);
}

ReactHighcharts.Highcharts.setOptions({
  global: {
    timezoneOffset: 5 * 60
  }
});

let interval;
var bounds = [
  [100, 0],
  [0, 100]
];

export default class Dashboard extends React.Component {
  constructor() {
    super();
    const savedFilterData = JSON.parse(localStorage.getItem('filters'));
    this.state = {
      loading: false,
      geoData: [],
      kafkaData: {
        topicName: '',
        data: ''
      },
      particleData: {
        inside_3MCP: 0,
        inside_2_5MCP: 0,
        out_3MCP: 0,
        out_2_5MCP: 0,
        temp: 0,
        humidity: 0,
        pressure: 0,
        out_temp: 0,
        out_humidity: 0,
        out_pressure: 0,
        rawethanol: 0,
        out_rawethanol: 0,
        tvoc: 0,
        out_tvoc: 0
      },
      userSettings: {
        pnType: '1l',
        pmType: 'ug/m3',
        pressure: 'h20',
        relativeHumidity: '%',
        temperature: 'deg',
        tvoc: 'ppb',
        co2: 'ppm',
        timeZone: 'America/New_York'
      },
      colorScaleMinValue: 0,
      colorScaleMaxValue: 0,
      clients: [],
      selectedClients:
        savedFilterData.clients.length == 0 ? [] : savedFilterData.clients,
      facility: [],
      selectedFacility:
        savedFilterData.facility.length == 0 ? [] : savedFilterData.facility,
      zones: [],
      selectedZones:
        savedFilterData.zones.length == 0 ? [] : savedFilterData.zones,
      selectedRooms:
        savedFilterData.rooms.length == 0 ? [] : savedFilterData.rooms,
      sensors: [],
      selectedSensors:
        savedFilterData.sensors.length == 0 ? [] : savedFilterData.sensors,
      timeChartOptions: {},
      rangeChartOptions: columnRange,
      timeSeriesSelector: 'pn03_m',
      timeSeriesType: savedFilterData.timeSeriesType,
      rooms: [],
      floorPlanImage: 'ames2.png',
      comparisionOption: savedFilterData.comparisionOption,
      floorRangeType: savedFilterData.floorRangeType,
      customDateRange: '',
      selectedDateRange:
        savedFilterData.selectedTime == undefined
          ? '24hrs'
          : savedFilterData.selectedTime,

      startDate: savedFilterData.startDate,
      endDate: savedFilterData.endDate,
      detailed: JSON.parse(localStorage.getItem('filters')).detailed,
      showLoaderForOverview: true,
      showLoaderForFloorMap: false,
      timeChartType: 'line',
      alertMesages: [],
      floorPlanData: flooData,
      timeSeiesFilters: [],
      selectedClientsData: [],
      detailedChartOptions: {},
      showLoaderForDetailed: true,
      showLoaderForBoxChart: true,
      allSerialNumbers: [],
      lastRefreshed: new Date(),
      selectedSerialNumber:
        savedFilterData.selectedSerialNumber == 0
          ? 0
          : savedFilterData.selectedSerialNumber,
      typeDetails: [],
      geoSensorSelector: '',
      isAutoScaleOn: true,
      mapType: 'agg',
      chageMarkerSize: false
    };

    this.handleChangeClients = this.handleChangeClients.bind(this);
    this.handleChangeFacility = this.handleChangeFacility.bind(this);
    this.handleChangeZones = this.handleChangeZones.bind(this);
    this.handleChangeSensors = this.handleChangeSensors.bind(this);
    this.handleChangeDateFilter = this.handleChangeDateFilter.bind(this);
    this.applyFilters = this.applyFilters.bind(this);
    this.removeFilters = this.removeFilters.bind(this);
    this.handleChangeRooms = this.handleChangeRooms.bind(this);
    this.onDateChangeHandler = this.onDateChangeHandler.bind(this);
    this.showAlertMessage = this.showAlertMessage.bind(this);
    this.getSensors = this.getSensors.bind(this);
    this.showDetailedChart = this.showDetailedChart.bind(this);
    this.onTimeChartEventHandler = this.onTimeChartEventHandler.bind(this);
    this.getFacilityBasedOnClients = this.getFacilityBasedOnClients.bind(this);
    this.getZones = this.getZones.bind(this);
    this.getRooms = this.getRooms.bind(this);
    this.changeOverviewGraph = this.changeOverviewGraph.bind(this);
    this.handleClientsClose = this.handleClientsClose.bind(this);
    this.getUserSettings = this.getUserSettings.bind(this);
    this.onKafkaTopicChange = this.onKafkaTopicChange.bind(this);
    this.sendDataToKafka = this.sendDataToKafka.bind(this);
    this.getGeoMap = this.getGeoMap.bind(this);
    this.changeMinMaxColorScale = this.changeMinMaxColorScale.bind(this);
    this.realTimeTicker = this.realTimeTicker.bind(this);
    this.applyFiltersForRealTime = this.applyFiltersForRealTime.bind(this);
    this.onGeoMapSensorChange = this.onGeoMapSensorChange.bind(this);
    this.onGeoMapAutoScaleChange = this.onGeoMapAutoScaleChange.bind(this);
    this.onMapTypeChange = this.onMapTypeChange.bind(this);
    this.applyForSensors = this.applyForSensors.bind(this);
  }

  onMapTypeChange(event) {
    this.setState({ mapType: event.target.value });
  }

  onGeoMapAutoScaleChange(event) {
    this.setState({ isAutoScaleOn: event.target.checked });
  }

  onGeoMapSensorChange(geoSensorSelector) {
    this.setState({ geoSensorSelector: geoSensorSelector });
  }

  changeMinMaxColorScale(min, max) {
    this.setState({ colorScaleMinValue: min, colorScaleMaxValue: max });
  }

  realTimeTicker() {
    interval = setInterval(() => {
      const stateDate = subMinutes(new Date(), 15).getTime();
      const endDate = new Date().getTime();
      this.applyFiltersForRealTime(stateDate, endDate);
      this.setState({ chageMarkerSize: true });
      let event = {
        target: {
          value: JSON.parse(localStorage.getItem('filters')).timeSeriesType
        }
      };
      this.onTimeChartEventHandler(event);
      let event1 = {
        target: {
          value: JSON.parse(localStorage.getItem('filters')).comparisionOption
        }
      };
      this.onRangeChangeEventHandler(event1);
      if (
        this.state.selectedSerialNumber != 0 &&
        this.state.selectedSerialNumber != undefined
      ) {
        this.getOverviewChart();
      }
    }, parseInt(30000));
  }

  changeOverviewGraph(event) {
    this.setState({ selectedSerialNumber: event.target.value });
    this.setState({ showLoaderForOverview: true });
    let data = JSON.parse(localStorage.getItem('filters'));
    data.selectedSerialNumber = event.target.value;
    localStorage.setItem('filters', JSON.stringify(data));
    // localStorage.setItem('timeTrendsFilters', JSON.stringify(data));

    setTimeout(() => {
      this.getOverviewChart();
    }, 500);
  }
  showAlertMessage(type, msg) {
    this.setState({ alertMesages: [{ type: type, msg: msg }] });
    setTimeout(() => {
      this.setState({ alertMesages: [] });
    }, 5000);
  }

  removeFilters = () => {
    this.setState({ selectedClients: [] });
    this.setState({ selectedFacility: [] });
    this.setState({ selectedZones: [] });
    this.setState({ selectedRooms: [] });
    this.setState({ selectedSensors: [] });
    let filterDataTemp = {
      clients: [],
      facility: [],
      zones: [],
      rooms: [],
      sensors: [],
      selectedTime: '24hrs',
      comparisionOption: 'Particle Concentration',
      floorRangeType: 'Particle Concentration',
      timeSeries: ['Particle Concentration (#/0.1l)'],
      timeSeriesType: 'pn03_m',
      detailed: [],
      selectedSerialNumber: this.state.selectedSerialNumber
    };
    localStorage.setItem('filters', JSON.stringify(filterDataTemp));
    // localStorage.setItem('timeTrendsFilters', JSON.stringify(filterDataTemp));
    setTimeout(() => {
      this.applyFilters();
      let event = {
        target: {
          value: JSON.parse(localStorage.getItem('filters')).timeSeriesType
        }
      };
      this.onTimeChartEventHandler(event);
      let event1 = {
        target: {
          value: JSON.parse(localStorage.getItem('filters')).comparisionOption
        }
      };
      this.onRangeChangeEventHandler(event1);
      if (
        this.state.selectedSerialNumber != 0 &&
        this.state.selectedSerialNumber != undefined
      ) {
        this.getOverviewChart();
      }
    }, 500);
  };

  applyFiltersForRealTime = (startDate, endDate) => {
    if (!endDate) {
      if (this.state.selectedDateRange === 'realtime') {
        startDate = subMinutes(new Date(), 15).getTime();
        endDate = new Date().getTime();
      }
    }

    let filterDataTemp = {
      clients: this.state.selectedClients,
      facility: this.state.selectedFacility,
      zones: this.state.selectedZones,
      rooms: this.state.selectedRooms,
      sensors: this.state.selectedSensors,
      selectedTime: this.state.selectedDateRange,
      startDate: startDate,
      endDate: endDate,
      floorRangeType: this.state.floorRangeType,
      timeSeries: JSON.parse(localStorage.getItem('filters')).timeSeries,
      timeSeriesType: this.state.timeSeriesType,
      detailed: JSON.parse(localStorage.getItem('filters')).detailed,
      comparisionOption: this.state.comparisionOption,
      selectedSerialNumber: this.state.selectedSerialNumber,
      selectedClientsData: this.state.selectedClients
    };

    let filterData = {
      clients: this.state.selectedClients,
      facility: this.state.selectedFacility,
      zones: this.state.selectedZones,
      sensors: this.state.selectedSensors,
      startDate: startDate,
      end: endDate,
      rooms: this.state.selectedRooms,
      type: this.state.comparisionOption,
      timeSeriesType: this.state.timeSeriesType,
      geoMapParameter: this.state.floorRangeType
    };

    localStorage.setItem('filters', JSON.stringify(filterDataTemp));

    this.getGeoMap(filterData);

    DashboardService.getChartData(filterData).then(responseData => {
      if (responseData.data) {
        if (responseData.data.particleData) {
          this.showParticleData(responseData);
          this.setOutdoorParticleData(responseData.data.outdoor);
        }
      }
    });
  };

  applyFilters = () => {
    const date = { startDate: 0, endDate: 0 };

    if (this.state.selectedDateRange === 'realtime') {
      date['startDate'] = subMinutes(new Date(), 15).getTime();
      date['endDate'] = new Date().getTime();
    }

    if (this.state.selectedDateRange === '15mins') {
      date['startDate'] = subMinutes(new Date(), 15).getTime();
      date['endDate'] = new Date().getTime();
    }
    if (this.state.selectedDateRange === '1hr') {
      date['startDate'] = subMinutes(new Date(), 60).getTime();
      date['endDate'] = new Date().getTime();
    }
    if (this.state.selectedDateRange === '4hr') {
      date['startDate'] = subHours(new Date(), 4).getTime();
      date['endDate'] = new Date().getTime();
    }
    if (this.state.selectedDateRange === '24hrs') {
      date['startDate'] = subHours(new Date(), 24).getTime();
      date['endDate'] = new Date().getTime();
    }
    if (this.state.selectedDateRange === '1week') {
      date['startDate'] = subDays(new Date(), 7).getTime();
      date['endDate'] = new Date().getTime();
    }
    if (this.state.selectedDateRange === '1month') {
      date['startDate'] = subDays(new Date(), 30).getTime();
      date['endDate'] = new Date().getTime();
    }
    if (this.state.selectedDateRange === 'custom') {
      if (
        this.state.startDate == undefined ||
        this.state.endDate == undefined
      ) {
        return;
      }
      date['startDate'] = this.state.startDate;
      date['endDate'] = this.state.endDate;
    }

    let filterDataTemp = {
      clients: this.state.selectedClients,
      facility: this.state.selectedFacility,
      zones: this.state.selectedZones,
      rooms: this.state.selectedRooms,
      sensors: this.state.selectedSensors,
      selectedTime: this.state.selectedDateRange,
      startDate: date.startDate,

      endDate: date.endDate,

      floorRangeType: this.state.floorRangeType,
      timeSeries: JSON.parse(localStorage.getItem('filters')).timeSeries,
      timeSeriesType: this.state.timeSeriesType,
      detailed: JSON.parse(localStorage.getItem('filters')).detailed,
      comparisionOption: this.state.comparisionOption,
      selectedSerialNumber: this.state.selectedSerialNumber,
      selectedClientsData: this.state.selectedClients
    };

    localStorage.setItem('filters', JSON.stringify(filterDataTemp));
    // localStorage.setItem('timeTrendsFilters', JSON.stringify(filterDataTemp));

    this.showAlertMessage('info', 'Loading...');
    this.setState({
      loading: true
    });
    let filterData = {
      clients: this.state.selectedClients,
      facility: this.state.selectedFacility,
      zones: this.state.selectedZones,
      sensors: this.state.selectedSensors,
      startDate: date.startDate,
      end: date.endDate,
      rooms: this.state.selectedRooms,
      type: this.state.comparisionOption,
      timeSeriesType: this.state.timeSeriesType,
      geoMapParameter: this.state.floorRangeType
    };

    this.getGeoMap(filterData);

    DashboardService.getChartData(filterData).then(responseData => {
      if (responseData.data) {
        if (responseData.data.particleData) {
          this.showParticleData(responseData);
          this.setOutdoorParticleData(responseData.data.outdoor);
        }
      }
      this.setState({
        loading: false,
        lastRefreshed: date.endDate,
        startDate: date.startDate,
        endDate: date.endDate
      });
    });

    setTimeout(() => {
      let event = {
        target: {
          value: JSON.parse(localStorage.getItem('filters')).timeSeriesType
        }
      };
      this.onTimeChartEventHandler(event);
      let event1 = {
        target: {
          value: JSON.parse(localStorage.getItem('filters')).comparisionOption
        }
      };
      this.onRangeChangeEventHandler(event1);
      if (
        this.state.selectedSerialNumber != 0 &&
        this.state.selectedSerialNumber != undefined
      ) {
        this.getOverviewChart();
      }
    }, 500);
  };

  showDetailedChart(res, startDate, endDate) {
    //this.setState({ showLoaderForDetailed: true });

    // this.setState({ detailedChartOptions: {} });
    this.setState({
      detailedChartOptions: res.data
      //showLoaderForDetailed: false
    });
  }

  getTimeSeriesChart(res, date) {
    if (res.data.timeChart) {
      const tempData = _.cloneDeep(res.data.timeChart.data);
      const tempData1 = tempData.filter(
        value =>
          value.name !== 'pm25_m' &&
          value.name !== 'co2' &&
          value.name !== 'pm100_m'
      );

      this.setState({ timeChartOptions: tempData1 });
      this.setState({ showLoaderForOverview: false });
    }
  }

  setOutdoorParticleData(data) {
    console.log(data);
    this.setState({
      particleData: {
        ...this.state.particleData,
        out_temp: !data.temp ? '--' : Math.round(data.temp)
      }
    });
    this.setState({
      particleData: {
        ...this.state.particleData,
        out_tvoc: !data.tvoc ? '--' : Math.round(data.tvoc)
      }
    });
    this.setState({
      particleData: {
        ...this.state.particleData,
        out_rawethanol: !data.rawethanol ? '--' : Math.round(data.rawethanol)
      }
    });
    this.setState({
      particleData: {
        ...this.state.particleData,
        out_pressure: !data.pressure ? '--' : Math.round(data.pressure)
      }
    });
    this.setState({
      particleData: {
        ...this.state.particleData,
        out_humidity: !data.hum ? '--' : Math.round(data.hum)
      }
    });
    this.setState({
      particleData: {
        ...this.state.particleData,
        outside_3MCP:
          !data.pn03_t || !data.pn03_b
            ? '--'
            : Math.min(Math.round(data.pn03_t), Math.round(data.pn03_b))
      }
    });
    this.setState({
      particleData: {
        ...this.state.particleData,
        outside_5MCP:
          !data.pm25_t || !data.pm25_b
            ? '--'
            : Math.min(Math.round(data.pm25_t), Math.round(data.pm25_b))
      }
    });
  }

  showParticleData(response) {
    const data = response.data.particleData;
    this.setState({
      particleData: {
        ...this.state.particleData,
        temp: data.temp === null ? '--' : Math.round(data.temp)
      }
    });
    this.setState({
      particleData: {
        ...this.state.particleData,
        tvoc: data.tvoc === null ? '--' : Math.round(data.tvoc)
      }
    });
    this.setState({
      particleData: {
        ...this.state.particleData,
        rawethanol:
          data.rawethanol === null ? '--' : Math.round(data.rawethanol)
      }
    });
    this.setState({
      particleData: {
        ...this.state.particleData,
        pressure: data.pressure === null ? '--' : Math.round(data.pressure)
      }
    });
    this.setState({
      particleData: {
        ...this.state.particleData,
        humidity: data.hum === null ? '--' : Math.round(data.hum)
      }
    });
    this.setState({
      particleData: {
        ...this.state.particleData,
        inside_3MCP:
          data.pn03_t === null || data.pn03_b === null
            ? '--'
            : Math.min(Math.round(data.pn03_t), Math.round(data.pn03_b))
      }
    });
    this.setState({
      particleData: {
        ...this.state.particleData,
        inside_2_5MCP:
          data.pm25_t === null || data.pm25_b === null
            ? '--'
            : Math.min(Math.round(data.pm25_t), Math.round(data.pm25_b))
      }
    });
  }

  getTypeDetails = () => {
    DashboardService.getTypeDetails().then(res => {
      this.setState({ typeDetails: res.data });
    });
  };

  getAllClients() {
    // alert("here");
    //

    deviceService.getAllDevices().then(response => {
      this.setState({ allClients: response.data });
      localStorage.setItem('masterClients', JSON.stringify(response.data));
      this.setState({ clients: _.uniq(_.map(response.data, 'client')) });

      setTimeout(() => {
        let temp = this.state.allClients;
        let serialNumbers = [];
        let clients = this.state.selectedClients;
        if (clients.length > 0) {
          for (let i = 0; i < temp.length; i++) {
            if (clients.includes(temp[i].client)) {
              serialNumbers.push(temp[i].serial_number);
            }
          }
          serialNumbers.sort();
          this.setState({ allSerialNumbers: serialNumbers });
          if (serialNumbers.length > 0) {
            this.setState({ selectedSerialNumber: serialNumbers[0] });
            // this.setState({selectedSensors:})
            setTimeout(() => {
              this.getOverviewChart();
            }, 750);
          }
        } else {
          this.setState({
            allSerialNumbers: _.uniq(
              _.map(response.data, 'serial_number')
            ).sort()
          });
          setTimeout(() => {
            this.getOverviewChart();
          }, 750);
        }
        setTimeout(() => {
          this.getFacilityBasedOnClients(false);
          this.getZones(false);
          this.getRooms(false);
        }, 500);

        if (this.state.selectedSerialNumber === 0) {
          this.setState({
            selectedSerialNumber: this.state.allSerialNumbers[0]
          });
        } else {
          this.setState({
            selectedSerialNumber: JSON.parse(localStorage.getItem('filters'))
              .selectedSerialNumber
          });
        }
      }, 500);
    });
  }

  getOverviewChart() {
    const date = { startDate: 0, endDate: 0 };
    this.setState({ showLoaderForOverview: true });

    if (this.state.selectedDateRange === 'realtime') {
      date['startDate'] = subMinutes(new Date(), 15).getTime();
      date['endDate'] = new Date().getTime();
    }

    if (this.state.selectedDateRange === '15mins') {
      date['startDate'] = subMinutes(new Date(), 15).getTime();
      date['endDate'] = new Date().getTime();
    }
    if (this.state.selectedDateRange === '1hr') {
      date['startDate'] = subMinutes(new Date(), 60).getTime();
      date['endDate'] = new Date().getTime();
    }
    if (this.state.selectedDateRange === '4hr') {
      date['startDate'] = subHours(new Date(), 4).getTime();
      date['endDate'] = new Date().getTime();
    }
    if (this.state.selectedDateRange === '24hrs') {
      date['startDate'] = subHours(new Date(), 24).getTime();
      date['endDate'] = new Date().getTime();
    }
    if (this.state.selectedDateRange === '1week') {
      date['startDate'] = subDays(new Date(), 7).getTime();
      date['endDate'] = new Date().getTime();
    }
    if (this.state.selectedDateRange === '1month') {
      date['startDate'] = subDays(new Date(), 30).getTime();
      date['endDate'] = new Date().getTime();
    }
    if (this.state.selectedDateRange == 'custom') {
      date['startDate'] = this.state.startDate;
      date['endDate'] = this.state.endDate;
      if (
        this.state.startDate == undefined ||
        this.state.endDate == undefined
      ) {
        return;
      }
    }
    let filterData = {
      clients: this.state.selectedClients,
      startDate: date.startDate,
      end: date.endDate,
      sensorNumber: this.state.selectedSerialNumber
    };
    DashboardService.getOverViewChart(filterData).then(responseData => {
      //columnRange.xAxis.categories = responseData.data.data.categories;
      this.getTimeSeriesChart(responseData, date);
      this.setState({ showLoaderForOverview: false });
    });
  }

  getFacilityBasedOnClients(status) {
    const selected = this.state.selectedClients;
    const facility = this.state.allClients
      .filter(e => selected.includes(e['client']))
      .map(d => d['facility']);
    const uniq = _.uniq(facility);
    this.setState({ facility: uniq });
    //this.setState({ selectedFacility: [] });
    // this.setState({ selectedZones: [] });
    //this.setState({ selectedRooms: [] });
    if (uniq.length == 1) {
      this.setState({ selectedFacility: uniq });
      setTimeout(() => {
        this.getZones(status);
      }, 500);
    } else if (uniq.length > 1 && status != false) {
      this.applyFilters();
      this.setState({ selectedSensors: [] });
    }
  }

  getZones(status) {
    const selectedClients = this.state.selectedClients;
    const selectedFacility = this.state.selectedFacility;
    const zones = this.state.allClients
      .filter(e => selectedClients.includes(e['client']))
      .filter(e => selectedFacility.includes(e['facility']))
      .map(d => d['zone']);
    const uniq = _.uniq(zones);
    this.setState({ zones: uniq });
    //this.setState({ selectedZones: [] });
    //this.setState({ selectedRooms: [] });
    if (uniq.length == 1) {
      this.setState({ selectedZones: uniq });
      setTimeout(() => {
        this.getRooms(false);
      }, 500);
    } else if (uniq.length > 1 && status != false) {
      if (this.state.selectedDateRange === 'realtime') {
        let startDate = subMinutes(new Date(), 15).getTime();
        let endDate = new Date().getTime();

        this.applyFiltersForRealTime(startDate, endDate);
      } else {
        this.applyFilters();
      }

      this.setState({ selectedSensors: [] });
    }
  }

  getRooms(status) {
    const selectedClients = this.state.selectedClients;
    const selectedFacility = this.state.selectedFacility;
    const selectedZones = this.state.selectedZones;
    const rooms = this.state.allClients
      .filter(e => selectedClients.includes(e['client']))
      .filter(e => selectedFacility.includes(e['facility']))
      .filter(e => selectedZones.includes(e['zone']))
      .map(d => d['room']);
    const uniq = _.uniq(rooms);
    this.setState({ rooms: uniq });
    // this.setState({ selectedRooms: [] });
    if (uniq.length == 1) {
      this.setState({ selectedRooms: uniq });
      setTimeout(() => {
        this.getSensors(status);
      }, 250);
    } else if (uniq.length > 1 && status != false) {
      if (this.state.selectedDateRange === 'realtime') {
        let startDate = subMinutes(new Date(), 15).getTime();
        let endDate = new Date().getTime();

        this.applyFiltersForRealTime(startDate, endDate);
      } else {
        this.applyFilters();
      }
      this.setState({ selectedSensors: [] });
    }
  }

  getSensors(status) {
    const selectedClients = this.state.selectedClients;
    const selectedFacility = this.state.selectedFacility;
    const selectedZones = this.state.selectedZones;
    const selectedRooms = this.state.selectedRooms;
    const sensors = this.state.allClients
      .filter(e => selectedClients.includes(e['client']))
      .filter(e => selectedFacility.includes(e['facility']))
      .filter(e => selectedZones.includes(e['zone']))
      .filter(e => selectedRooms.includes(e['room']))
      .map(d => d['serial_number']);
    const uniq = _.uniq(sensors);
    this.setState({ sensors: uniq });
    if (status !== false) {
      this.setState({ selectedSensors: [] });
    }
  }

  getRangeChart = (responseData, date) => {
    this.setState({ rangeChartOptions: _.cloneDeep(responseData) });
    this.setState({ showLoaderForBoxChart: false });
  };

  onShowFloorType = event => {
    this.setState({ showLoaderForFloorMap: true });
    this.setState({ floorRangeType: event.target.value });

    const date = { startDate: 0, endDate: 0 };
    this.setState({ showLoaderForOverview: true });
    if (this.state.selectedDateRange === 'realtime') {
      date['startDate'] = subMinutes(new Date(), 15).getTime();
      date['endDate'] = new Date().getTime();
    }
    if (this.state.selectedDateRange === '15mins') {
      date['startDate'] = subMinutes(new Date(), 15).getTime();
      date['endDate'] = new Date().getTime();
    }
    if (this.state.selectedDateRange === '1hr') {
      date['startDate'] = subMinutes(new Date(), 60).getTime();
      date['endDate'] = new Date().getTime();
    }
    if (this.state.selectedDateRange === '4hr') {
      date['startDate'] = subHours(new Date(), 4).getTime();
      date['endDate'] = new Date().getTime();
    }
    if (this.state.selectedDateRange === '24hrs') {
      date['startDate'] = subHours(new Date(), 24).getTime();
      date['endDate'] = new Date().getTime();
    }
    if (this.state.selectedDateRange === '1week') {
      date['startDate'] = subDays(new Date(), 7).getTime();
      date['endDate'] = new Date().getTime();
    }
    if (this.state.selectedDateRange === '1month') {
      date['startDate'] = subDays(new Date(), 30).getTime();
      date['endDate'] = new Date().getTime();
    }
    if (this.state.selectedDateRange == 'custom') {
      date['startDate'] = this.state.startDate;
      date['endDate'] = this.state.endDate;
      if (
        this.state.startDate == undefined ||
        this.state.endDate == undefined
      ) {
        return;
      }
    }

    let filterData = {
      clients: this.state.selectedClients,
      facility: this.state.selectedFacility,
      zones: this.state.selectedZones,
      sensors: this.state.selectedSensors,
      startDate: date.startDate,
      end: date.endDate,
      rooms: this.state.selectedRooms,
      type: this.state.comparisionOption,
      timeSeriesType: this.state.timeSeriesType,
      geoMapParameter: event.target.value
    };

    this.setState({ colorScaleMinValue: 0, colorScaleMaxValue: 0 });
    this.getGeoMap(filterData);
  };
  export = () => {
    this.showAlertMessage('info', 'Downloading PDF...');
    var doc = new jsPDF('p', 'mm', 'a4');
    var element = document.getElementById('dont-print');
    element.classList.add('displayNone');
    html2canvas(document.querySelector(`#dashboard-container`)).then(canvas => {
      var imgData = canvas.toDataURL('image/png');
      doc.addImage(imgData, 'PNG', 5, 5, 200, 285);
      doc.save('Dashboard.pdf');
    });
    element.classList.remove('displayNone');
  };

  exportRawToCSV = () => {
    const date = { startDate: 0, endDate: 0 };
    this.setState({
      loading: true
    });
    if (this.state.selectedDateRange === '15mins') {
      date['startDate'] = subMinutes(new Date(), 15).getTime();
      date['endDate'] = new Date().getTime();
    }
    if (this.state.selectedDateRange === '1hr') {
      date['startDate'] = subMinutes(new Date(), 60).getTime();
      date['endDate'] = new Date().getTime();
    }
    if (this.state.selectedDateRange === '4hr') {
      date['startDate'] = subHours(new Date(), 4).getTime();
      date['endDate'] = new Date().getTime();
    }
    if (this.state.selectedDateRange === '24hrs') {
      date['startDate'] = subHours(new Date(), 24).getTime();
      date['endDate'] = new Date().getTime();
    }
    if (this.state.selectedDateRange === '1week') {
      date['startDate'] = subDays(new Date(), 7).getTime();
      date['endDate'] = new Date().getTime();
    }
    if (this.state.selectedDateRange === '1month') {
      date['startDate'] = subDays(new Date(), 30).getTime();
      date['endDate'] = new Date().getTime();
    }
    if (this.state.selectedDateRange == 'custom') {
      date['startDate'] = this.state.startDate;
      date['endDate'] = this.state.endDate;
    }
    let filterData = {
      clients: this.state.selectedClients,
      facilities: this.state.selectedFacility,
      zones: this.state.selectedZones,
      sensors: this.state.selectedSensors,
      startDate: date.startDate,
      end: date.endDate,
      rooms: this.state.selectedRooms,
      type: this.state.comparisionOption,
      timeSeriesType: this.state.timeSeriesType,
      detailed: JSON.parse(localStorage.getItem('filters')).detailed
    };
    this.showAlertMessage('info', 'Downloading CSV...');
    DashboardService.exportToRawCSV(filterData).then(responseData => {
      const element = document.createElement('a');
      const file = new Blob([responseData.data], { type: 'text/plain' });
      element.href = URL.createObjectURL(file);
      element.download = 'Dashboard_' + new Date().getTime() + '.csv';
      document.body.appendChild(element); // Required for this to work in FireFox
      element.click();
      this.setState({
        loading: false
      });
    });
  };

  exportToCSV = () => {
    const date = { startDate: 0, endDate: 0 };
    this.setState({
      loading: true
    });
    if (this.state.selectedDateRange === '15mins') {
      date['startDate'] = subMinutes(new Date(), 15).getTime();
      date['endDate'] = new Date().getTime();
    }
    if (this.state.selectedDateRange === '1hr') {
      date['startDate'] = subMinutes(new Date(), 60).getTime();
      date['endDate'] = new Date().getTime();
    }
    if (this.state.selectedDateRange === '4hr') {
      date['startDate'] = subHours(new Date(), 4).getTime();
      date['endDate'] = new Date().getTime();
    }
    if (this.state.selectedDateRange === '24hrs') {
      date['startDate'] = subHours(new Date(), 24).getTime();
      date['endDate'] = new Date().getTime();
    }
    if (this.state.selectedDateRange === '1week') {
      date['startDate'] = subDays(new Date(), 7).getTime();
      date['endDate'] = new Date().getTime();
    }
    if (this.state.selectedDateRange === '1month') {
      date['startDate'] = subDays(new Date(), 30).getTime();
      date['endDate'] = new Date().getTime();
    }
    if (this.state.selectedDateRange == 'custom') {
      date['startDate'] = this.state.startDate;
      date['endDate'] = this.state.endDate;
    }
    let filterData = {
      clients: this.state.selectedClients,
      facilities: this.state.selectedFacility,
      zones: this.state.selectedZones,
      sensors: this.state.selectedSensors,
      startDate: date.startDate,
      end: date.endDate,
      rooms: this.state.selectedRooms,
      type: this.state.comparisionOption,
      timeSeriesType: this.state.timeSeriesType,
      detailed: JSON.parse(localStorage.getItem('filters')).detailed
    };
    this.showAlertMessage('info', 'Downloading CSV...');
    DashboardService.exportToCSV(filterData).then(responseData => {
      const element = document.createElement('a');
      const file = new Blob([responseData.data], { type: 'text/plain' });
      element.href = URL.createObjectURL(file);
      element.download = 'Dashboard_' + new Date().getTime() + '.csv';
      document.body.appendChild(element); // Required for this to work in FireFox
      element.click();
      this.setState({
        loading: false
      });
    });
  };

  onRangeChangeEventHandler = event => {
    this.setState({ comparisionOption: event.target.value });

    const date = { startDate: 0, endDate: 0 };

    if (this.state.selectedDateRange === 'realtime') {
      date['startDate'] = subMinutes(new Date(), 30).getTime();
      date['endDate'] = new Date().getTime();
    }

    if (this.state.selectedDateRange === '15mins') {
      date['startDate'] = subSeconds(new Date(), 30).getTime();
      date['endDate'] = new Date().getTime();
    }

    if (this.state.selectedDateRange === '15mins') {
      date['startDate'] = subMinutes(new Date(), 15).getTime();
      date['endDate'] = new Date().getTime();
    }
    if (this.state.selectedDateRange === '1hr') {
      date['startDate'] = subMinutes(new Date(), 60).getTime();
      date['endDate'] = new Date().getTime();
    }
    if (this.state.selectedDateRange === '4hr') {
      date['startDate'] = subHours(new Date(), 4).getTime();
      date['endDate'] = new Date().getTime();
    }
    if (this.state.selectedDateRange === '24hrs') {
      date['startDate'] = subHours(new Date(), 24).getTime();
      date['endDate'] = new Date().getTime();
    }
    if (this.state.selectedDateRange === '1week') {
      date['startDate'] = subDays(new Date(), 7).getTime();
      date['endDate'] = new Date().getTime();
    }
    if (this.state.selectedDateRange === '1month') {
      date['startDate'] = subDays(new Date(), 30).getTime();
      date['endDate'] = new Date().getTime();
    }
    if (this.state.selectedDateRange == 'custom') {
      date['startDate'] = this.state.startDate;
      date['endDate'] = this.state.endDate;
      if (
        this.state.startDate == undefined ||
        this.state.endDate == undefined
      ) {
        return;
      }
    }
    let filterData = {
      startDate: date.startDate,
      end: date.endDate,
      type: event.target.value,
      clients: this.state.selectedClients
    };
    var temp = JSON.parse(localStorage.getItem('filters'));
    temp.comparisionOption = event.target.value;

    localStorage.setItem('filters', JSON.stringify(temp));
    // localStorage.setItem('timeTrendsFilters', JSON.stringify(temp));

    DashboardService.getRangeChart(filterData).then(responseData => {
      if (responseData.data.status) {
        this.getRangeChart(responseData, date);
      }
    });
  };
  incrementCounter = () => {
    const date = { startDate: 0, endDate: 0 };
    date['startDate'] = subMilliseconds(new Date(), 30000).getTime();
    date['endDate'] = new Date().getTime();

    let filterData = {
      clients: this.state.selectedClients,
      facility: this.state.selectedFacility,
      zones: this.state.selectedZones,
      sensors: this.state.selectedSensors,
      startDate: date.startDate,
      end: date.endDate,
      rooms: this.state.selectedRooms,
      type: '',
      timeSeriesType: this.state.timeSeriesType
    };
    this.setState({
      loading: true
    });
    DashboardService.getRealTimeData(filterData).then(responseData => {
      if (
        responseData.data &&
        responseData.data.particleData &&
        responseData.data.particleData.data
      ) {
        this.setState({
          lastRefreshed: new Date()
        });
        this.showParticleData(responseData, date);
      }
      this.setState({
        loading: false
      });
    });
  };
  componentDidMount() {
    // this.getAllClients();
    this.getUserSettings();
    this.getAllClients();

    this.setState({ chageMarkerSize: false });
    if (this.state.selectedDateRange === 'realtime') {
      let startDate = subMinutes(new Date(), 15).getTime();
      let endDate = new Date().getTime();

      this.applyFiltersForRealTime(startDate, endDate);
      this.realTimeTicker();
    } else {
      this.applyFilters();
    }
    let event = {
      target: {
        value: JSON.parse(localStorage.getItem('filters')).timeSeriesType
      }
    };
    this.onTimeChartEventHandler(event);
    let event1 = {
      target: {
        value: JSON.parse(localStorage.getItem('filters')).comparisionOption
      }
    };
    this.onRangeChangeEventHandler(event1);
    // this.state.detailedChartOptions = areaChart;
    //this.state.timeChartOptions = lineChart;
  }
  componentWillUnmount() {
    clearInterval(interval);
  }

  getGeoMap(filterData) {
    dashboardService.getGeoMap(filterData).then(res => {
      if (res.status === 200) {
        this.setState({ geoData: res.data });
      }
      console.log(res);
    });
  }

  getUserSettings = () => {
    SettingsService.getUserSettings().then(
      res => {
        if (Object.values(res.data).length > 0) {
          if (res.data.liveRefreshTime && res.data.liveRefreshTime != '0') {
            //update code
          }

          this.setState({
            userSettings: {
              pnType: res.data.pnType,
              pmType: res.data.pmType,
              pressure: res.data.pressure,
              relativeHumidity: res.data.relativeHumidity,
              temperature: res.data.temperature,
              tvoc: res.data.tvoc,
              co2: res.data.co2,
              refresh: res.data.liveRefreshTime,
              timeZone: res.data.timeZone
            }
          });

          setTimeout(() => {
            console.table(this.state.userSettings);
          }, 1000);
        }
      },
      err => {}
    );
  };

  onDateChangeHandler = event => {
    let object = new Object();
    object[event.target.name] = new Date(event.target.value).getTime();
    if (event.target.name === 'startTime') {
      this.setState({ startDate: new Date(event.target.value).getTime() });
    } else {
      this.setState({ endDate: new Date(event.target.value).getTime() });
    }
  };

  showFloorData = res => {
    if (res.data.floorPlan.image) {
      this.setState({
        floorPlanImage: res.data.floorPlan.image,
        floorPlanData: res.data.floorPlan.data
      });
    }
  };

  handleChangeClients(event) {
    this.setState({ selectedClients: event.target.value });
    let filterData = JSON.parse(localStorage.getItem('filters'));
    filterData.selectedClientsData = event.target.value;
    filterData.clients = event.target.value;
    localStorage.setItem('filters', JSON.stringify(filterData));
    // localStorage.setItem('timeTrendsFilters', JSON.stringify(filterData));
  }

  handleChangeFacility(event) {
    this.setState({ selectedFacility: event.target.value });
  }

  handleChangeZones(event) {
    this.setState({ selectedZones: event.target.value });
  }

  handleChangeSensors(event) {
    this.setState({ selectedSensors: event.target.value });
    event.target.value = event.target.value[0];
    this.changeOverviewGraph(event);
  }

  calculateTimeRange = (startDate, endDate) => {
    var a = moment(startDate);
    var b = moment(endDate);

    var diff = b.diff(a, 'days');
    if (diff <= 1) {
      return 'HH:mm';
    } else if (diff > 1 && diff <= 30) {
      return 'MM/DD HH:mm';
    } else if (diff >= 30 && diff <= 365) {
      return 'MM/DD';
    } else if (diff >= 365) {
      return 'yyyy/MM/DD';
    }
  };

  calculateTimeRange1 = (startDate, endDate) => {
    var a = moment(startDate);
    var b = moment(endDate);

    var diff = (endDate - startDate) / 100;
    let finalDiff = diff / 60000;
    return finalDiff <= 2;
  };

  handleChangeDateFilter(event) {
    let targetValue = event.target.value;

    if (targetValue === 'realtime') {
      let startDate = subMinutes(new Date(), 15).getTime();
      let endDate = new Date().getTime();
      this.setState({
        selectedDateRange: event.target.value
      });
      this.applyFiltersForRealTime(startDate, endDate);
      this.realTimeTicker();
      setTimeout(() => {
        // localStorage.setItem("selectedTimeRange", this.state.selectedDateRange);
        let event = {
          target: {
            value: JSON.parse(localStorage.getItem('filters')).timeSeriesType
          }
        };
        let event1 = {
          target: {
            value: JSON.parse(localStorage.getItem('filters')).comparisionOption
          }
        };

        if (targetValue !== 'custom') {
          this.onTimeChartEventHandler(event);
          this.onRangeChangeEventHandler(event1);
          this.getOverviewChart();
        }
      }, 500);
    } else if (
      event.target.name === 'startTime' ||
      event.target.name === 'endTime'
    ) {
      clearInterval(interval);
      if (
        (event.target.name === 'startTime' && this.state.endDate) ||
        (event.target.name === 'endTime' && this.state.startDate)
      ) {
        let object = new Object();
        object[event.target.name] = new Date(event.target.value).getTime();

        if (event.target.name === 'startTime') {
          this.setState({ startDate: new Date(event.target.value).getTime() });
        } else {
          this.setState({ endDate: new Date(event.target.value).getTime() });
        }
        this.setState({ selectedDateRange: 'custom' });
      } else {
        let object = new Object();
        object[event.target.name] = new Date(event.target.value).getTime();

        if (event.target.name === 'startTime') {
          this.setState({ startDate: new Date(event.target.value).getTime() });
        } else {
          this.setState({ endDate: new Date(event.target.value).getTime() });
        }
        this.setState({ selectedDateRange: 'custom' });

        return false;
      }
    } else {
      clearInterval(interval);
      this.setState({ selectedDateRange: event.target.value });
      setTimeout(() => {
        // localStorage.setItem("selectedTimeRange", this.state.selectedDateRange);
        let event = {
          target: {
            value: JSON.parse(localStorage.getItem('filters')).timeSeriesType
          }
        };
        let event1 = {
          target: {
            value: JSON.parse(localStorage.getItem('filters')).comparisionOption
          }
        };

        if (targetValue !== 'custom') {
          // this.onTimeChartEventHandler(event);
          this.applyFilters();
          // this.onRangeChangeEventHandler(event1);
          //this.getOverviewChart();
        }
      }, 500);
    }
  }

  onKafkaTopicChange(event) {
    const data = this.state.kafkaData;
    data[event.target.name] = event.target.value;
    this.setState({ kafkaData: data });
  }

  sendDataToKafka() {
    console.log(this.state.kafkaData);
    dashboardService
      .sendDataToKafka(this.state.kafkaData)
      .then(responseData => {
        if (responseData.status === 200) {
          alert('Data sent succesfully to kafka');
        }
      });
  }

  applyForSensors() {
    this.applyFilters();
    let event = {
      target: {
        value: JSON.parse(localStorage.getItem('filters')).timeSeriesType
      }
    };
    this.onTimeChartEventHandler(event);
    let event1 = {
      target: {
        value: JSON.parse(localStorage.getItem('filters')).comparisionOption
      }
    };
    this.onRangeChangeEventHandler(event1);
    if (
      this.state.selectedSerialNumber != 0 &&
      this.state.selectedSerialNumber != undefined
    ) {
      this.getOverviewChart();
    }
  }

  onTimeChartEventHandler(event) {
    this.setState({ timeSeriesType: event.target.value });

    const date = { startDate: 0, endDate: 0 };

    if (this.state.selectedDateRange === 'realtime') {
      date['startDate'] = subMinutes(new Date(), 15).getTime();
      date['endDate'] = new Date().getTime();
    }

    if (this.state.selectedDateRange === '15mins') {
      date['startDate'] = subMinutes(new Date(), 15).getTime();
      date['endDate'] = new Date().getTime();
    }
    if (this.state.selectedDateRange === '1hr') {
      date['startDate'] = subMinutes(new Date(), 60).getTime();
      date['endDate'] = new Date().getTime();
    }
    if (this.state.selectedDateRange === '4hr') {
      date['startDate'] = subHours(new Date(), 4).getTime();
      date['endDate'] = new Date().getTime();
    }
    if (this.state.selectedDateRange === '24hrs') {
      date['startDate'] = subHours(new Date(), 24).getTime();
      date['endDate'] = new Date().getTime();
    }
    if (this.state.selectedDateRange === '1week') {
      date['startDate'] = subDays(new Date(), 7).getTime();
      date['endDate'] = new Date().getTime();
    }
    if (this.state.selectedDateRange === '1month') {
      date['startDate'] = subDays(new Date(), 30).getTime();
      date['endDate'] = new Date().getTime();
    }
    if (this.state.selectedDateRange == 'custom') {
      date['startDate'] = this.state.startDate;
      date['endDate'] = this.state.endDate;
      if (
        this.state.startDate == undefined ||
        this.state.endDate == undefined
      ) {
        return;
      }
    }
    let filterData = {
      clients: this.state.selectedClients,
      facility: this.state.selectedFacility,
      zones: this.state.selectedZones,
      sensors: this.state.selectedSensors,
      startDate: date.startDate,
      end: date.endDate,
      rooms: this.state.selectedRooms,
      type: this.state.comparisionOption,
      timeSeriesType: event.target.value,
      detailed: JSON.parse(localStorage.getItem('filters')).detailed,
      selectedSerialNumber: this.state.selectedSerialNumber
    };

    var temp = JSON.parse(localStorage.getItem('filters'));
    temp.timeSeriesType = event.target.value;
    localStorage.setItem('filters', JSON.stringify(temp));
    // localStorage.setItem('timeTrendsFilters', JSON.stringify(temp));

    DashboardService.getDetailedTimeChart(filterData).then(responseData => {
      if (responseData.data.status) {
        this.showDetailedChart(responseData, date.startDate, date.endDate);
      }
      this.setState({ showLoaderForDetailed: false });
    });
  }

  handleChangeRooms(event) {
    this.setState({ selectedRooms: event.target.value });
  }

  handleClientsClose() {
    this.getFacilityBasedOnClients();
    let event = {
      target: {
        value: JSON.parse(localStorage.getItem('filters')).timeSeriesType
      }
    };
    this.onTimeChartEventHandler(event);
    let event1 = {
      target: {
        value: JSON.parse(localStorage.getItem('filters')).comparisionOption
      }
    };
    this.onRangeChangeEventHandler(event1);

    let temp = this.state.allClients;
    let serialNumbers = [];
    let clients = this.state.selectedClients;
    if (clients.length > 0) {
      for (let i = 0; i < temp.length; i++) {
        if (clients.includes(temp[i].client)) {
          serialNumbers.push(temp[i].serial_number);
        }
      }
      serialNumbers.sort();
      this.setState({ allSerialNumbers: serialNumbers });
      if (serialNumbers.length > 0) {
        this.setState({ selectedSerialNumber: serialNumbers[0] });
        setTimeout(() => {
          this.getOverviewChart();
        }, 750);
      }
    } else {
      this.setState({
        allSerialNumbers: _.uniq(
          _.map(this.state.allClients, 'serial_number')
        ).sort()
      });
      setTimeout(() => {
        this.getOverviewChart();
      }, 750);
    }
  }

  render() {
    const {
      particleData,
      timeChartOptions,
      rangeChartOptions,
      alertMesages
    } = this.state;

    return (
      <div style={{ marginRight: '5px', flexGrow: 1, background: '#f5fafe' }}>
        {/* <Grid
          style={{ marginLeft: "12px" }}
          item
          xs={5}
          lg={5}
          md={5}
          sm={12}
          xs={12}
          className="text-center"
          id="dont1-print"
        >
          <Input
            label="Kafka Topic"
            fullWidth
            placeholder="Kafka Topic"
            name="kafkaTopic"
            onChange={(event) => this.onKafkaTopicChange(event)}
          />

          <Input
            fullWidth
            placeholder="Json DATA"
            label="Data"
            name="data"
            multiline
            onChange={(event) => this.onKafkaTopicChange(event)}
          />
        </Grid>
        <Grid
          style={{ marginTop: "12px", marginLeft: "12px" }}
          item
          xs={4}
          lg={4}
          md={4}
          sm={12}
          xs={12}
        >
          <Button
            variant="contained"
            onClick={this.sendDataToKafka}
            color="primary"
          >
            Send Data
          </Button>
        </Grid> */}
        <Grid container spacing={2}>
          <Grid
            item
            xs={2}
            lg={2}
            md={3}
            sm={12}
            xs={12}
            className="text-center"
            id="dont-print"
          >
            <br /> <br />
            {this.state.selectedDateRange === 'realtime' && (
              <Button
                variant="contained"
                onClick={this.applyFiltersForRealTime}
                color="primary"
              >
                Apply Filters
              </Button>
            )}
            {this.state.selectedDateRange !== 'realtime' && (
              <Button
                variant="contained"
                onClick={this.applyFilters}
                color="primary"
              >
                Apply Filters
              </Button>
            )}
            <br />
            <br />
            <Button color="primary" onClick={this.removeFilters}>
              Reset
            </Button>
            <Grid item xs={12}>
              <br />
              <Accordion>
                <AccordionSummary
                  expandIcon={<FontAwesomeIcon icon={faCaretDown} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Refine Location</Typography>
                </AccordionSummary>
                <AccordionDetails
                  style={{ display: 'block', overflow: 'eclipses' }}
                >
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel>Clients</InputLabel>
                      <Select
                        multiple
                        // displayEmpty
                        onChange={this.handleChangeClients}
                        value={this.state.selectedClients}
                        onClose={this.handleClientsClose}
                        input={<Input />}
                        renderValue={selected => (
                          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                            {this.state.selectedClients.map(value => (
                              <Chip
                                color={'primary'}
                                key={value}
                                label={value}
                                size="small"
                                style={{ margin: '1px' }}
                              />
                            ))}
                          </div>
                        )}
                      >
                        {this.state.clients.map(client => (
                          <MenuItem key={client} value={client} fullWidth>
                            <Checkbox
                              checked={
                                this.state.selectedClients.indexOf(client) > -1
                              }
                            />
                            <ListItemText primary={client} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel>Facility</InputLabel>
                      <Select
                        multiple
                        onChange={this.handleChangeFacility}
                        value={this.state.selectedFacility}
                        input={<Input />}
                        onClose={this.getZones}
                        renderValue={selected => (
                          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                            {this.state.selectedFacility.map(value => (
                              <Chip
                                color={'primary'}
                                key={value}
                                label={value}
                                size="small"
                                style={{ margin: '1px' }}
                              />
                            ))}
                          </div>
                        )}
                      >
                        {this.state.facility.map(facility => (
                          <MenuItem key={facility} value={facility} fullWidth>
                            <Checkbox
                              checked={
                                this.state.selectedFacility.indexOf(facility) >
                                -1
                              }
                            />
                            <ListItemText primary={facility} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel>Zones</InputLabel>
                      <Select
                        multiple
                        onChange={this.handleChangeZones}
                        value={this.state.selectedZones}
                        input={<Input />}
                        onClose={this.getRooms}
                        renderValue={selected => (
                          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                            {this.state.selectedZones.map(value => (
                              <Chip
                                size="small"
                                color={'primary'}
                                key={value}
                                label={value}
                                style={{ margin: '1px' }}
                              />
                            ))}
                          </div>
                        )}
                      >
                        {this.state.zones.map(zone => (
                          <MenuItem key={zone} value={zone}>
                            <Checkbox
                              checked={
                                this.state.selectedZones.indexOf(zone) > -1
                              }
                            />
                            <ListItemText primary={zone} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel>Rooms</InputLabel>
                      <Select
                        multiple
                        onChange={this.handleChangeRooms}
                        value={this.state.selectedRooms}
                        onClose={this.getSensors}
                        input={<Input />}
                        renderValue={selected => (
                          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                            {this.state.selectedRooms.map(value => (
                              <Chip
                                size="small"
                                color={'primary'}
                                key={value}
                                label={value}
                                style={{ margin: '1px' }}
                              />
                            ))}
                          </div>
                        )}
                      >
                        {this.state.rooms.map(zone => (
                          <MenuItem key={zone} value={zone}>
                            <Checkbox
                              checked={
                                this.state.selectedRooms.indexOf(zone) > -1
                              }
                            />
                            <ListItemText primary={zone} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel>Sensors</InputLabel>
                      <Select
                        multiple
                        onChange={this.handleChangeSensors}
                        value={this.state.selectedSensors}
                        onClose={this.applyForSensors}
                        input={<Input />}
                        renderValue={selected => (
                          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                            {this.state.selectedSensors.map(value => (
                              <Chip
                                size="small"
                                color={'primary'}
                                key={value}
                                label={value}
                                style={{ margin: '1px' }}
                              />
                            ))}
                          </div>
                        )}
                      >
                        {this.state.sensors.map(sensor => (
                          <MenuItem key={sensor} value={sensor}>
                            <Checkbox
                              checked={
                                this.state.selectedSensors.indexOf(sensor) > -1
                              }
                            />
                            <ListItemText primary={sensor} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<FontAwesomeIcon icon={faCaretDown} />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography>Date Time Span</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <RadioGroup
                    aria-label="gender"
                    name="gender1"
                    onChange={this.handleChangeDateFilter}
                    value={this.state.selectedDateRange}
                  >
                    <FormControlLabel
                      value="realtime"
                      control={<Radio />}
                      label="Real Time"
                    />
                    <FormControlLabel
                      value="15mins"
                      control={<Radio />}
                      label="15 Mins"
                    />
                    <FormControlLabel
                      value="1hr"
                      control={<Radio />}
                      label="1 Hr"
                    />
                    <FormControlLabel
                      value="4hr"
                      control={<Radio />}
                      label="4 Hr"
                    />
                    <FormControlLabel
                      value="24hrs"
                      control={<Radio />}
                      label="24 Hrs"
                    />
                    <FormControlLabel
                      value="1week"
                      control={<Radio />}
                      label="1 Week"
                    />
                    <FormControlLabel
                      value="1month"
                      control={<Radio />}
                      label="1 Month"
                    />
                    <FormControlLabel
                      value={'custom'}
                      control={<Radio />}
                      label={'Custom'}
                    />
                    <div xs={12}>
                      {this.state.selectedDateRange == 'custom' ? (
                        <div style={{ width: '13vw' }}>
                          <br />
                          <TextField
                            fullWidth
                            id={'datetime-local'}
                            label={'Start Time'}
                            type="datetime-local"
                            name="startTime"
                            onChange={this.handleChangeDateFilter}
                            InputLabelProps={{
                              shrink: true
                            }}
                          />

                          {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <DatePicker value={this.selectedDate} onChange={this.handleChangeDateFilter} />
                              <TimePicker value={this.selectedDate} onChange={this.handleChangeDateFilter} />
                              <DateTimePicker value={this.selectedDate} onChange={this.handleChangeDateFilter} />
                            </MuiPickersUtilsProvider> */}

                          <br />
                          <TextField
                            fullWidth
                            id={'datetime-local'}
                            label={'End Time'}
                            type="datetime-local"
                            name="endTime"
                            onChange={this.handleChangeDateFilter}
                            InputLabelProps={{
                              shrink: true
                            }}
                          />
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </div>
                  </RadioGroup>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<FontAwesomeIcon icon={faCaretDown} />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                  disabled={true}
                >
                  <Typography>View Options</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <FormGroup row style={{ display: 'grid' }}>
                    <p
                      style={{
                        width: '-webkit-fill-available',
                        color: '#144358',
                        textAlign: 'left'
                      }}
                    >
                      <Typography fullWidth>Air Quality</Typography>
                    </p>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={this.handleViewOptionsChange}
                          name="airIndexQuality"
                        />
                      }
                      label="Air Index Quality"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="particleConcentration"
                          onChange={this.handleViewOptionsChange}
                        />
                      }
                      label="Particle Concentration"
                    />
                    <div style={{ marginLeft: '30px', display: 'grid' }}>
                      <FormControlLabel
                        control={<Checkbox name="1mcp" />}
                        label="PN0.3"
                      />
                      <FormControlLabel
                        control={<Checkbox name="2.5mcp" />}
                        label="PN0.5"
                      />
                      <FormControlLabel
                        control={<Checkbox name="3mcp" />}
                        label="PN1.0"
                      />
                      <FormControlLabel
                        control={<Checkbox name="5mcp" />}
                        label="PN2.5"
                      />
                      <FormControlLabel
                        control={<Checkbox name="10mcp" />}
                        label="PN5.0"
                      />

                      <FormControlLabel
                        control={<Checkbox name="1nmcp" />}
                        label="PN10"
                      />
                      <FormControlLabel
                        control={<Checkbox name="2.5nmcp" />}
                        label="PM1.0"
                      />
                      <FormControlLabel
                        control={<Checkbox name="3nmcp" />}
                        label="PM2.5"
                      />
                      <FormControlLabel
                        control={<Checkbox name="5nmcp" />}
                        label="PM10"
                      />
                    </div>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="temperature"
                          onChange={this.handleViewOptionsChange}
                        />
                      }
                      label="Temperature"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="humidity"
                          onChange={this.handleViewOptionsChange}
                        />
                      }
                      label="Relative Humidity"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="pressure"
                          onChange={this.handleViewOptionsChange}
                        />
                      }
                      label="Pressure"
                    />
                    <p
                      style={{
                        width: '-webkit-fill-available',
                        color: '#144358',
                        textAlign: 'left'
                      }}
                    >
                      <Typography fullWidth>Gas Levels</Typography>
                    </p>
                    <div style={{ display: 'grid' }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="CO2"
                            onChange={this.handleViewOptionsChange}
                          />
                        }
                        label="CO2"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="VCOS"
                            onChange={this.handleViewOptionsChange}
                          />
                        }
                        label="VOCs"
                      />
                    </div>
                  </FormGroup>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<FontAwesomeIcon icon={faCaretDown} />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                  disabled={true}
                >
                  <Typography>Advanced Filters</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>Coming Soon...</Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  disabled={true}
                  expandIcon={<FontAwesomeIcon icon={faCaretDown} />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography>Save Filter As</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>Coming Soon...</Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<FontAwesomeIcon icon={faCaretDown} />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                  disabled={true}
                >
                  <Typography title="Comming Soon...">Load Filter</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>Coming Soon...</Typography>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>

          <Grid item xl={10} lg={10} md={9} sm={12} xs={12}>
            {alertMesages.map(data => {
              return (
                <div>
                  <br />
                  <Alert variant="filled" severity={data.type}>
                    {data.msg}
                  </Alert>
                </div>
              );
            })}
            <div className="floorDivCss">
              <span className="dateCss">
                Live{' '}
                <span style={{ fontSize: '12px' }}>
                  Last refreshed{' '}
                  <Moment format="MM/DD/YYYY hh:mm:ss">
                    {this.state.lastRefreshed}
                  </Moment>
                </span>
              </span>

              <IconButton
                onClick={this.export}
                style={{ float: 'right' }}
                aria-label="delete"
                size="small"
              >
                <PictureAsPdfIcon />
              </IconButton>
              <IconButton
                title="CSV"
                onClick={this.exportToCSV}
                style={{ float: 'right' }}
                aria-label="csv"
                size="small"
              >
                <DescriptionIcon />
              </IconButton>
              <IconButton
                title="Export Raw Data"
                onClick={this.exportRawToCSV}
                style={{ float: 'right' }}
                aria-label="csv"
                size="small"
              >
                <FileCopyIcon />
              </IconButton>
            </div>

            {this.state.loading && <BarLoader color="#1D8BF1" height="2" />}
            <div id="dashboard-container">
              <Grid container spacing={1}>
                <Grid item xs={12} lg={3} xl={3} md={6} sm={6}>
                  <Card>
                    <CardContent className={'setFixheight'}>
                      <span className="titleCss1">
                        <Typography>Particle Concentration</Typography>
                      </span>
                      <br />
                      <Grid container spacing={1}>
                        <Grid item xs={6} className="text-center">
                          <div style={{ height: '51px' }}>
                            <img
                              src="/img/PN03.png"
                              alt="co2"
                              className="borderCircle"
                            />
                          </div>
                          <div className="_5MCPTextCss text-center">
                            {particleData.inside_3MCP}
                          </div>
                          <div className="_5MCPSmallTextCss text-center">
                            PN0.3 ({this.state.userSettings.pnType})
                          </div>
                        </Grid>
                        <Grid item xs={6} className="text-center">
                          <div style={{ height: '51px' }}>
                            <img
                              src="/img/PM25.png"
                              alt={'vocs'}
                              className={'borderCircle'}
                            />
                          </div>
                          <div className="_3MCPTextCss text-center">
                            {particleData.inside_2_5MCP}
                          </div>
                          <div className="_5MCPSmallTextCss text-center">
                            PM2.5 ({this.state.userSettings.pmType}){' '}
                          </div>
                        </Grid>
                      </Grid>
                    </CardContent>
                    <CardActions style={{ background: '#F7F9FB' }}>
                      <Grid container xs={12} spacing={1}>
                        <Grid item xs={2} className={'text-center'}>
                          <div
                            className="_5MCPOutsideTextCss"
                            style={{ fontSize: '14px', paddingTop: '9px' }}
                          >
                            Outdoor
                          </div>
                        </Grid>

                        <Grid item xs={5} className={'text-center'}>
                          <div
                            className="_5MCPTextCss"
                            style={{ paddingLeft: '8px' }}
                          >
                            {particleData.outside_3MCP}
                          </div>
                        </Grid>
                        <Grid item xs={4} className="text-center">
                          <div className="_3MCPTextCss">
                            {particleData.outside_5MCP}
                          </div>
                        </Grid>
                      </Grid>
                    </CardActions>
                  </Card>
                </Grid>
                <Grid item xs={12} lg={3} xl={3} md={6} sm={6}>
                  <Card>
                    <CardContent className={'setFixheight'}>
                      <span className="titleCss1">
                        <Typography noWrap> Temp | RH | Pressure</Typography>
                      </span>
                      <br />
                      <Grid container spacing={1}>
                        <Grid item xs={4} className="text-center">
                          <div style={{ height: '51px' }}>
                            <img src="/img/icon_temp.png" alt="Temperature" />
                          </div>
                          <div className="tempTextCss">{particleData.temp}</div>
                          <div className="tempSmallTextCss">
                            Temp(
                            {this.state.userSettings.temperature == '℃'
                              ? '°C'
                              : '°F'}
                            ){' '}
                          </div>
                        </Grid>
                        <Grid item xs={4} className="text-center">
                          <div style={{ height: '51px' }}>
                            <img src="/img/icon_rh.png" alt="RH" />
                          </div>
                          <div className="rhTextCss">
                            {particleData.humidity}
                          </div>
                          <div className="rhSmallTextCss">
                            RH({this.state.userSettings.relativeHumidity})
                          </div>
                        </Grid>
                        <Grid item xs={4} className="text-center">
                          <div style={{ height: '51px' }}>
                            <img src="/img/icon_pressure.png" alt="Pressure" />
                          </div>
                          <div className="pressureTextCss">
                            {particleData.pressure}
                          </div>
                          <div className="pressureSmallTextCss">
                            Pressure({this.state.userSettings.pressure})
                          </div>
                        </Grid>
                      </Grid>
                    </CardContent>
                    <CardActions style={{ background: '#F7F9FB' }}>
                      <Grid container spacing={1}>
                        <Grid item xs={4} className="text-center">
                          <span className="tempTextCss">
                            {particleData.out_temp}
                          </span>
                        </Grid>
                        <Grid item xs={4} className="text-center">
                          <span className="rhTextCss">
                            {particleData.out_humidity}
                          </span>
                        </Grid>
                        <Grid item xs={4} className="text-center">
                          <span className="pressureTextCss">
                            {particleData.out_pressure}
                          </span>
                        </Grid>
                      </Grid>
                    </CardActions>
                  </Card>
                </Grid>
                <Grid item xs={12} lg={3} xl={3} md={6} sm={6}>
                  <Card>
                    <CardContent className={'setFixheight'}>
                      <span className="titleCss1">
                        <Typography>Gases</Typography>
                      </span>
                      <br />
                      <Grid container spacing={1}>
                        <Grid item xs={6} className="text-center">
                          <img
                            src="/img/icon_co2.png"
                            alt="co2"
                            className="borderCircle"
                          />
                          <div className="co2TextCss">
                            {particleData.rawethanol}
                          </div>
                          <div className="co2SmallTextCss">
                            CO2({this.state.userSettings.co2})
                          </div>
                        </Grid>
                        <Grid item xs={6} className="text-center">
                          <img
                            src="/img/icon_vocs.png"
                            alt={'vocs'}
                            className="borderCircle"
                          />
                          <div className="vco2TextCss">{particleData.tvoc}</div>
                          <div className="vco2SmallTextCss">
                            VOCs({this.state.userSettings.tvoc})
                          </div>
                        </Grid>
                      </Grid>
                    </CardContent>
                    <CardActions style={{ background: '#F7F9FB' }}>
                      <Grid container xs={12}>
                        <Grid item xs={6} className="text-center">
                          <span className="co2TextCss">
                            {particleData.out_rawethanol}
                          </span>
                        </Grid>
                        <Grid item xs={6} className="text-center">
                          <span className="vco2TextCss">
                            {particleData.out_tvoc}
                          </span>
                        </Grid>
                      </Grid>
                    </CardActions>
                  </Card>
                </Grid>
                <Grid item xs={12} lg={3} xl={3} md={6} sm={6}>
                  <Card
                    style={{
                      background: 'linear-gradient(to right, #144358, #4E98B9)',
                      pointerEvents: 'None',
                      opacity: 0.5
                    }}
                  >
                    <CardContent>
                      <span className="alertTitle"> Alerts </span>
                      <span className="viewAllCss">View All + </span>
                      <div className="mt-16">
                        <span className="noSignalImgCss">
                          <img src="/img/icon_nosignal.png" alt="noSignal" />
                        </span>
                        <div className="displayCss">
                          <span className="wpr">
                            <span className="noSignalCss">No Signal</span>
                          </span>
                          <span className="wpr">
                            <span className="deviceTextCss"></span>
                          </span>
                        </div>
                        <div className="iconNextCss">
                          <Link to="/reports?reportType=UnHealthy_devices&status=UnHealthy">
                            <FontAwesomeIcon
                              icon={faCaretRight}
                              className="iconNextColorCss"
                            />
                          </Link>
                        </div>
                      </div>
                      <mat-divider className="lineCss"></mat-divider>
                      <div className="mt-9">
                        <span className="noSignalImgCss">
                          <img
                            src="/img/icon_alert_large.png"
                            alt="iconAlert"
                          />
                        </span>
                        <div className="displayCss">
                          <span className="wpr">
                            <span className="highParticleCss">
                              High Particle Concentration
                            </span>
                          </span>
                          <span className="wpr">
                            <span className="deviceTextCss">
                              Device #01985 Room# 305
                            </span>
                          </span>
                        </div>
                        <div className="iconNextCss">
                          <Link to="/reports?reportType=alerts">
                            <FontAwesomeIcon
                              icon={faCaretRight}
                              className="iconNextColorCss"
                            />
                          </Link>
                        </div>
                      </div>
                      <mat-divider className="lineCss"></mat-divider>
                      <div className="mt-9">
                        <span className="noSignalImgCss">
                          <img
                            src="/img/icon_particle.png"
                            alt="iconParticle"
                          />
                        </span>
                        <div className="displayCss">
                          <span className="wpr">
                            <span className="particlexyzCss">
                              Particle XYZ Found
                            </span>
                          </span>
                          <span className="wpr">
                            <span className="deviceTextCss">
                              Device #01985 Room# 305
                            </span>
                          </span>
                        </div>
                        <div className="iconNextCss">
                          <FontAwesomeIcon
                            icon={faCaretRight}
                            className="iconNextColorCss"
                          />
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
              <br />
              <Grid item xs={12} md={12} sm={12}>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={6} sm={6} xl={12}>
                    <Card>
                      <CardContent>
                        <Grid container spacing={1}>
                          <Grid item xs={6} md={6} sm={6} xl={6}>
                            <span className="titleCss1">
                              <Typography>Overview</Typography>
                            </span>
                          </Grid>
                          <Grid item xs={6} md={6} sm={6} xl={6}>
                            <FormControl style={{ float: 'right' }}>
                              <Select
                                name="overview"
                                value={this.state.selectedSerialNumber}
                                onChange={this.changeOverviewGraph}
                              >
                                {_.uniq(this.state.allSerialNumbers).map(
                                  sensor => (
                                    <MenuItem value={sensor}>{sensor}</MenuItem>
                                  )
                                )}
                              </Select>
                            </FormControl>
                          </Grid>
                        </Grid>

                        <div className="chartBoxCss1">
                          {this.state.timeChartOptions &&
                            this.state.timeChartOptions.length > 0 && (
                              <TimeSeriesChart
                                config={this.state.timeChartOptions}
                                userSettings={this.state.userSettings}
                              />
                            )}
                        </div>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={6} sm={6} xl={12}>
                    <Card>
                      <CardContent style={{ maxHeight: '335px' }}>
                        <Grid container spacing={1}>
                          <Grid item xs={6} md={6} sm={6} xl={6}>
                            <span className="titleCss1">
                              <Typography>Detailed</Typography>
                            </span>
                          </Grid>

                          <Grid item xs={6} md={6} sm={6} xl={6}>
                            <FormControl style={{ float: 'right' }}>
                              <Select
                                name="timeSeries"
                                onChange={this.onTimeChartEventHandler}
                                value={this.state.timeSeriesType}
                              >
                                {/* {
                                this.state.typeDetails.map((k1) => 
                                  (
                                    <MenuItem value={k1}>
                                      {k1}
                                    </MenuItem>
                                  )
                                )
                              } */}
                                <MenuItem value="pressure">Pressure</MenuItem>
                                <MenuItem value="pn03_m" selected>
                                  Particle Concentration
                                </MenuItem>
                                <MenuItem value="temp">Temperature</MenuItem>
                                <MenuItem value="hum">
                                  Relative Humidity
                                </MenuItem>
                                <MenuItem value="rawethanol">CO2</MenuItem>
                                <MenuItem value="tvoc">VOCs</MenuItem>
                                <MenuItem value="pn25_m">PM2.5</MenuItem>
                                <MenuItem value="pm10_m">PM10</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                        </Grid>
                        <div className="chartBoxCss1">
                          {this.state.showLoaderForDetailed ? (
                            <div
                              style={{
                                display: 'flex',
                                padding: '5% 45% 0% 45%'
                              }}
                            ></div>
                          ) : (
                            <DetaildSeriesChart
                              data={this.state.detailedChartOptions}
                              timeSeriesType={this.state.timeSeriesType}
                              userSettings={this.state.userSettings}
                              selectedDateRange={this.state.selectedDateRange}
                            />
                          )}
                        </div>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
              <br />
              <Grid item xs={12}>
                <Card>
                  <CardContent>
                    <Grid container spacing={1}>
                      <Grid item xs={10} md={10}>
                        <span className="titleCss1">
                          <Typography>Comparison</Typography>
                        </span>
                      </Grid>

                      <Grid item xs={2} md={2}>
                        <FormControl style={{ float: 'right' }}>
                          <Select
                            name="comparisionOption"
                            onChange={this.onRangeChangeEventHandler}
                            value={this.state.comparisionOption}
                          >
                            <MenuItem value="pressure">Pressure</MenuItem>
                            <MenuItem value="pn03_m" selected>
                              Particle Concentration
                            </MenuItem>
                            <MenuItem value="temp">Temperature</MenuItem>
                            <MenuItem value="hum">Relative Humidity</MenuItem>
                            <MenuItem value="rawethanol">CO2</MenuItem>
                            <MenuItem value="tvoc">VOCs</MenuItem>
                            <MenuItem value="pn25_m">PN2.5</MenuItem>

                            <MenuItem value="pm10_m">PM10</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                    <div className="chartBoxCss1">
                      <Grid container spacing={1}>
                        <Grid item xs={12} md={12}>
                          {this.state.rangeChartOptions &&
                            this.state.rangeChartOptions.data && (
                              <ComparisionChart
                                config={this.state.rangeChartOptions.data}
                                class="chartCss"
                                userSettings={this.state.userSettings}
                                comparisionOption={this.state.comparisionOption}
                              />
                            )}
                        </Grid>
                      </Grid>
                    </div>
                  </CardContent>
                </Card>
              </Grid>
              <br />

              <Grid item xs={12}>
                <Card style={{ height: '600px' }}>
                  <CardContent>
                    <Grid container spacing={1}>
                      <Grid item xs={2} md={2}>
                        <span className="titleCss1">
                          <Typography>Geo map</Typography>
                        </span>
                      </Grid>

                      <Grid item xs={2} md={2}>
                        <span className="titleCss1">
                          <FormControlLabel
                            control={
                              <Checkbox
                                value={this.state.isAutoScaleOn}
                                checked={this.state.isAutoScaleOn}
                                onChange={this.onGeoMapAutoScaleChange}
                              />
                            }
                            label="Autoscale"
                          />
                        </span>
                      </Grid>

                      <Grid item xs={2} md={2}>
                        <span className="titleCss1">
                          <RenderColorScale
                            parameter={this.state.floorRangeType}
                            changeMinMaxColorScale={this.changeMinMaxColorScale}
                            data={this.state.geoData}
                            isAutoScaleOn={this.state.isAutoScaleOn}
                            mapType={this.state.mapType}
                          />
                        </span>
                      </Grid>

                      <Grid item xs={2} md={2}>
                        <span className="titleCss1">
                          <FormControl style={{ float: 'right' }}>
                            <InputLabel htmlFor="age-native-simple">
                              Map Type
                            </InputLabel>
                            <Select
                              native
                              name="mapType"
                              onChange={this.onMapTypeChange}
                              value={this.state.mapType}
                            >
                              <option value="agg">Aggregate</option>
                              <option value="real_time">Real time</option>
                            </Select>
                          </FormControl>
                        </span>
                      </Grid>

                      <Grid item xs={2} md={2}>
                        <span className="titleCss1">
                          <RenderGeoMapSensors
                            onGeoMapSensorChange={this.onGeoMapSensorChange}
                            data={this.state.geoData}
                          />
                        </span>
                      </Grid>

                      <Grid item xs={2} md={2}>
                        <FormControl style={{ float: 'right' }}>
                          <InputLabel htmlFor="age-native-simple">
                            Type
                          </InputLabel>
                          <Select
                            native
                            name="rangetype"
                            onChange={this.onShowFloorType}
                            value={this.state.floorRangeType}
                          >
                            <option value="pressure">Pressure</option>
                            <option value="Particle Concentration">
                              Particle Concentration
                            </option>
                            <option value="Temperature">Temperature</option>
                            <option value="Relative Humidity">
                              Relative Humidity
                            </option>

                            <option value="rawethanol">CO2</option>
                            <option value="VOCs">VOCs</option>
                            <option value="pn25_m">PM2.5</option>
                            <option value="pm10_m">PM10</option>
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                    <div className="chartBoxCss1">
                      <Grid container spacing={1}>
                        <Grid item xs={12} md={12}>
                          <GeoMap
                            data={this.state.geoData}
                            parameter={this.state.floorRangeType}
                            minColorScale={this.state.colorScaleMinValue}
                            maxColorScale={this.state.colorScaleMaxValue}
                            geoSensorSelector={this.state.geoSensorSelector}
                            mapType={this.state.mapType}
                            timeRangeType={this.state.chageMarkerSize}
                          />
                        </Grid>
                      </Grid>
                    </div>
                  </CardContent>
                </Card>
              </Grid>
            </div>
          </Grid>
        </Grid>
        <br />
        <br />
      </div>
    );
  }
}
