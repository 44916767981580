import './analytics.css';

import * as moment from 'moment-timezone';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card,
  CardContent,
  Checkbox,
  Chip,
  FormControlLabel,
  ListItemText,
  MenuItem,
  Radio,
  RadioGroup,
  TextField
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import AnalyticsService from '../api/analyticsService';
import BarLoader from 'react-bar-loader';
import CalendarHeatMapDaily from './CalendarHeatMapDaily';
import CalendarHeatMapHourlyAntV from './CalendarHeatMapHourly';
import CalendarHeatMapHourlyWeekAntV from './CalendarHeatMapWeekly';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import SettingsService from '../../src/settings/SettingsService';
import { Typography } from '@material-ui/core';
import { areaChart } from './charts/area-chart';
import deviceService from '../api/deviceService';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons/faCaretDown';
import subDays from 'date-fns/subDays';
import { utcDateConversion } from '../timeUtil';
import axios from 'axios';
import { MonthlyPlot } from './components/MonthlyPlot';
import { WeeklyPlot } from './components/WeeklyBoxPlot';
import { HourlyPlot } from './components/HourlyPlot';

var _ = require('underscore');
const ReactHighcharts = require('react-highcharts');

const AntTimeTrends = () => {
  const [loading, setLoading] = useState(false);
  const [sensors, setSensors] = useState([]);
  const [filteredsensors, setFilteredsensors] = useState(
    JSON.parse(localStorage.getItem('timeTrendsFilters')).modifiedFlag === true
      ? JSON.parse(localStorage.getItem('timeTrendsFilters')).sensors
      : JSON.parse(localStorage.getItem('filters')).sensors
  );
  const [allclients, setAllclients] = useState([]);
  const [submittedData, setSubmittedData] = useState({});
  const [clients, setClients] = useState([]);
  const [uniqclients, setUniqclients] = useState([]);
  const [dailyHeatData, setDailyHeatData] = useState([]);
  const [hourlyHeatData, setHourlyHeatData] = useState([]);
  const [weekelyHeatData, setWeekelyHeatData] = useState([]);
  const [hourlyBoxData, setHourlyBoxData] = useState([]);
  const [weeklyBoxData, setWeeklyBoxData] = useState([]);
  const [monthlyBoxData, setMonthlyBoxData] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [mtimeZone, msetTimeZone] = useState(
    moment.tz(moment.tz.guess()).zoneAbbr()
  );
  const [selectedDateRange, setSelectedDateRange] = useState('1month');
  const [selectedClients, setSelectedClients] = useState(
    JSON.parse(localStorage.getItem('timeTrendsFilters')).modifiedFlag === true
      ? JSON.parse(localStorage.getItem('timeTrendsFilters'))
          .selectedClientsData
      : JSON.parse(localStorage.getItem('filters')).selectedClientsData
  );
  const [selectedSensors, setSelectedSensors] = useState([]);
  const [chartConfig, setChartConfig] = useState({
    daily: {},
    monthly: {},
    weekly: {},
    dailyBox: {},
    weeklyBox: {},
    monthlyBox: {}
  });
  const [timeSeriesType, setTimeSeriesType] = useState('pn03_m');
  const [partConSelect, setpartConSelect] = useState('pn03_m');
  const [userSettings, setUserSettings] = useState({});

  const onTimeChartEventHandler = event => {
    fetchAllTimeTrends(
      event.target.value,
      filteredsensors,
      selectedClients,
      startDate,
      endDate
    );
    setpartConSelect(event.target.value);
    setTimeSeriesType(event.target.value);
  };

  const getAllClients = () => {
    setLoading(true);
    deviceService.getAllDevices().then(response => {
      setAllclients(response.data);
      localStorage.setItem('masterClients', JSON.stringify(response.data));
      setUniqclients(_.uniq(_.pluck(response.data, 'client')));
      setTimeout(() => {
        let serialNumbers = [];
        if (selectedClients.length > 0) {
          for (let i = 0; i < response.data.length; i++) {
            if (selectedClients.includes(response.data[i].client)) {
              serialNumbers.push(response.data[i].serial_number);
            }
          }
          serialNumbers.sort();
          setSensors(serialNumbers);
        }
        fetchData();
      }, 500);
      setLoading(false);
    });
  };

  const onSensorChangeEvent = event => {
    setFilteredsensors(event.target.value);
    let data = JSON.parse(localStorage.getItem('timeTrendsFilters'));
    data.timeTrendSensor = event.target.value;
    data.sensors = event.target.value;
    let pushSensors = event.target.value;
    data.sensors = pushSensors;
    data.modifiedFlag = true;
    localStorage.setItem('timeTrendsFilters', JSON.stringify(data));

    setStartDate(subDays(new Date(), 30).getTime());
    setEndDate(new Date().getTime());
  };

  const fetchData = () => {
    setStartDate(subDays(new Date(), 30).getTime());
    setEndDate(new Date().getTime());
    fetchAllTimeTrends(
      timeSeriesType,
      filteredsensors,
      selectedClients,
      subDays(new Date(), 30).getTime(),
      new Date().getTime()
    );
  };

  const onClientChangeEvent = event => {
    setFilteredsensors([]);
    setSelectedClients(event.target.value);
    var getLocalStoreData = JSON.parse(
      localStorage.getItem('timeTrendsFilters')
    );
    let pushClinets = event.target.value;
    getLocalStoreData.clients = pushClinets;
    getLocalStoreData.selectedClientsData = pushClinets;
    getLocalStoreData.modifiedFlag = true;
    localStorage.setItem(
      'timeTrendsFilters',
      JSON.stringify(getLocalStoreData)
    );

    getLocalStoreData.selectedClientsData = event.target.value;
    let serialNumbers = [];
    if (setSelectedClients.length > 0) {
      for (let i = 0; i < allclients.length; i++) {
        if (event.target.value.includes(allclients[i].client)) {
          serialNumbers.push(allclients[i].serial_number);
        }
      }
      serialNumbers.sort();
      setSensors(serialNumbers);
    }
    // localStorage.setItem('timeTrendsFilters', JSON.stringify(data));
  };
  const handleChangeDateFilter = event => {
    var todayUtcDate = utcDateConversion(new Date());

    if (event.target.name === 'startTime' || event.target.name === 'endTime') {
      var utcMilllisecondsSinceEpoch = utcDateConversion(event.target.value);
      event.target.name === 'startTime'
        ? setStartDate(utcMilllisecondsSinceEpoch)
        : setEndDate(utcMilllisecondsSinceEpoch);
      setSelectedDateRange('custom');
      localStorage.setItem('selectedTimeRange', 'custom');
    } else {
      setSelectedDateRange(event.target.value);

      if (event.target.value === '1week') {
        var weekSubDays = utcDateConversion(subDays(new Date(), 7));
        setStartDate(weekSubDays);
        setEndDate(utcDateConversion(new Date()));
      }
      if (event.target.value === '1month') {
        var monthSubDays = utcDateConversion(subDays(new Date(), 30));
        setStartDate(monthSubDays);
        setEndDate(utcDateConversion(new Date()));
      }
      localStorage.setItem('selectedTimeRange', event.target.value);
    }
  };

  const applFilters = () => {
    fetchAllTimeTrends(
      timeSeriesType,
      filteredsensors,
      selectedClients,
      startDate,
      endDate
    );
  };

  const fetchAllTimeTrends = (
    parameter,
    sensorID,
    selectedClients,
    sDate,
    eDate
  ) => {
    const data = {
      parameter: parameter,
      sensors: sensorID.join(','),
      clients: selectedClients,
      startDate: sDate,
      endDate: eDate,
      timeZone: JSON.parse(localStorage.getItem('UserData')).timeZone
    };
    setSubmittedData(data);
  };

  useEffect(() => {
    //localStorage.setItem('UserData', JSON.stringify(this.state));
    SettingsService.getUserSettings().then(res => {
      if (res.status === 200) {
        localStorage.setItem('UserData', JSON.stringify(res.data));
      }
    });
    getAllClients();
  }, []);

  return (
    <div style={{ marginRight: '5px', flexGrow: 1, background: '#f5fafe' }}>
      <Grid container spacing={2}>
        <Grid
          item
          xs={2}
          lg={2}
          md={3}
          sm={12}
          className="text-center"
          id="dont-print"
        >
          <br /> <br />
          <Button variant="contained" onClick={applFilters} color="primary">
            Apply Filters
          </Button>
          <br />
          <br />
          <Button color="primary">Reset</Button>
          <FormControl fullWidth>
            <InputLabel>Clients</InputLabel>
            <Select
              multiple
              value={selectedClients}
              input={<Input />}
              onChange={onClientChangeEvent}
              renderValue={() => (
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  {selectedClients.map(value => (
                    <Chip
                      size="small"
                      color={'primary'}
                      key={value}
                      label={value}
                      style={{ margin: '1px' }}
                    />
                  ))}
                </div>
              )}
            >
              {uniqclients.map(client => (
                <MenuItem key={client} value={client} fullWidth>
                  <Checkbox checked={selectedClients.indexOf(client) > -1} />
                  <ListItemText primary={client} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel>Sensors</InputLabel>
            <Select
              multiple
              value={filteredsensors}
              input={<Input />}
              onChange={onSensorChangeEvent}
              renderValue={() => (
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  {filteredsensors.map(value => (
                    <Chip
                      size="small"
                      color={'primary'}
                      key={value}
                      label={value}
                      style={{ margin: '1px' }}
                    />
                  ))}
                </div>
              )}
            >
              {sensors.map(sensor => (
                <MenuItem key={sensor} value={sensor}>
                  <Checkbox checked={filteredsensors.indexOf(sensor) > -1} />
                  <ListItemText primary={sensor} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <Accordion>
              <AccordionSummary
                expandIcon={<FontAwesomeIcon icon={faCaretDown} />}
                aria-controls="panel2a-content"
                id="panel2a-header"
                style={{
                  backgroundColor: '#F5FAFE',
                  padding: '0px',
                  color: '#7073A1',
                  borderBottom: '1px solid'
                }}
              >
                <Typography>Date Time Span</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <RadioGroup
                  aria-label="timeselection"
                  name="data_time_span"
                  onChange={handleChangeDateFilter}
                  value={selectedDateRange}
                >
                  <FormControlLabel
                    value="1week"
                    control={<Radio />}
                    label="1 Week"
                  />
                  <FormControlLabel
                    value="1month"
                    control={<Radio />}
                    label="1 Month"
                  />
                  <FormControlLabel
                    value={'custom'}
                    control={<Radio />}
                    label={'Custom'}
                  />
                  <div xs={12}>
                    {selectedDateRange === 'custom' ? (
                      <div style={{ width: '13vw' }}>
                        <br />
                        <TextField
                          fullWidth
                          id={'datetime-local-start'}
                          label={'Start Time'}
                          type="datetime-local"
                          name="startTime"
                          onChange={handleChangeDateFilter}
                          InputLabelProps={{
                            shrink: true
                          }}
                        />
                        <br />
                        <TextField
                          fullWidth
                          id={'datetime-local-end'}
                          label={'End Time'}
                          type="datetime-local"
                          name="endTime"
                          onChange={handleChangeDateFilter}
                          InputLabelProps={{
                            shrink: true
                          }}
                        />
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </div>
                </RadioGroup>
              </AccordionDetails>
            </Accordion>
          </FormControl>
        </Grid>
        <Grid item xl={10} lg={10} md={9} sm={12} xs={12}>
          <div className="floorDivCss">
            <span className="dateCss">Temporal Trends</span>
            <FormControl style={{ float: 'right' }}>
              <Select
                name="timeSeries"
                onChange={onTimeChartEventHandler}
                value={partConSelect}
              >
                <MenuItem value="pressure">Pressure</MenuItem>
                {/* <MenuItem value="pn03_t">Particle Concentration</MenuItem> */}
                <MenuItem value="pn03_m">Particle Concentration</MenuItem>
                <MenuItem value="temp">Temperature</MenuItem>
                <MenuItem value="hum">Relative Humidity</MenuItem>
                <MenuItem value="rawethanol">CO2</MenuItem>
                <MenuItem value="tvoc">VOCs</MenuItem>
                <MenuItem value="pn25_m">PM2.5</MenuItem>
                <MenuItem value="pm10_m">PM10</MenuItem>
              </Select>
            </FormControl>
          </div>
          {loading && <BarLoader color="#1D8BF1" height="2" fullWidth />}
          <Grid container spacing={1}>
            <Grid item xs={12} lg={12} xl={12} md={12} sm={12}>
              <HourlyPlot data={submittedData} userSettings={userSettings} />
            </Grid>
            <Grid item xs={12} lg={6} xl={6} md={6} sm={6}>
              <WeeklyPlot data={submittedData} />
            </Grid>
            <Grid item xs={12} lg={6} xl={6} md={6} sm={6}>
              <MonthlyPlot data={submittedData} userSettings={userSettings} />
            </Grid>

            <Grid item xs={12} lg={12} xl={12} md={12} sm={12}>
              <div id="dashboard-container-5">
                <Card style={{ height: '950px' }}>
                  <CardContent className={'setFixheight'}>
                    <span className="titleCss1">
                      <Typography>Hourly</Typography>
                    </span>
                    <br />
                    <CalendarHeatMapHourlyAntV data={submittedData} />
                  </CardContent>
                </Card>
              </div>
            </Grid>

            <Grid item xs={12} lg={6} xl={6} md={6} sm={6}>
              <div id="dashboard-container-4">
                <Card style={{ height: '620px' }}>
                  <CardContent className={'setFixheight'}>
                    <span className="titleCss1">
                      <Typography>Daily</Typography>
                    </span>
                    <br />
                    <CalendarHeatMapDaily data={submittedData} />

                    <div id="container">
                      <div id="container1"></div>
                      <div id="container2"></div>
                    </div>
                  </CardContent>
                </Card>
              </div>
            </Grid>

            <Grid item xs={12} lg={6} xl={6} md={6} sm={6}>
              <div id="dashboard-container-6">
                <Card style={{ height: '620px' }}>
                  <CardContent className={'setFixheight'}>
                    <span className="titleCss1">
                      <Typography>Weekly</Typography>
                    </span>
                    <br />
                    <CalendarHeatMapHourlyWeekAntV data={submittedData} />
                  </CardContent>
                </Card>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
export default AntTimeTrends;
