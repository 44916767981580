import React from 'react';
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Button,
  Tabs,
  Tab,
  Avatar,
  Box,
  Menu,
  MenuItem
} from '@material-ui/core';
import PropTypes from 'prop-types';
import DashboardIcon from '@material-ui/icons/Dashboard';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers } from '@fortawesome/free-solid-svg-icons/faUsers';
import { faNetworkWired } from '@fortawesome/free-solid-svg-icons/faNetworkWired';
import { faTachometerAlt } from '@fortawesome/free-solid-svg-icons/faTachometerAlt';
import { faChartBar } from '@fortawesome/free-solid-svg-icons/faChartBar';
import { faClipboardCheck } from '@fortawesome/free-solid-svg-icons/faClipboardCheck';
import { faCog } from '@fortawesome/free-solid-svg-icons/faCog';
import { Link } from 'react-router-dom';
import { subHours } from 'date-fns';

function LinkTab(props) {
  return (
    <Tab
      component="a"
      onClick={event => {
        event.preventDefault();
      }}
      {...props}
    />
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      0.
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `nav-tab-${index}`,
    'aria-controls': `nav-tabpanel-${index}`
  };
}

const useStyles = makeStyles(theme => ({
  grow: {
    flexGrow: 1
  },
  gradientHeader: {
    backgroundColor: '#144358',
    backgroundImage: 'linear-gradient(to right, #144358, #4E98B9)'
  },
  iconLabelWrapper: {
    flexDiection: 'row'
  },
  labelContainer: {
    width: 'auto',
    padding: 0
  },
  toolBarColor: {
    backgroundColor: '#0a2936 !important'
  },
  tabCss: {
    minWidth: '16.6%',
    flexDirection: 'row',
    fontWeight: 600,
    textTransform: 'initial'
  }
}));
export default function HomePage() {
  let startDate = subHours(new Date(), 24).getTime();
  let endDate = new Date().getTime();

  if (localStorage.getItem('alertStartDate')) {
    startDate = localStorage.getItem('alertStartDate');
    endDate = localStorage.getItem('alertEndDate');
  }

  const classes = useStyles();
  const userData = JSON.parse(sessionStorage.getItem('userData'));
  const [value, setValue] = React.useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [DropdownSetup, setDropdownSetup] = React.useState(null);

  const changeTab = (event, newValue) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorEl(null);
    localStorage.clear();
    sessionStorage.clear();
    window.location.href = '/login';
  };

  const handleSetupMenuClick = event => {
    setDropdownSetup(event.currentTarget);
  };

  const handleUserClick = event => {
    setAnchorEl(event.currentTarget);
  };
  if (userData == null) {
    window.location.href = '/login';
  }

  return (
    <div>
      <AppBar position="static">
        <Toolbar className={classes.gradientHeader}>
          <a href="javascript:void('0')">
            <img
              src="/img/telosair_white.png"
              alt="TelosAir Logo"
              height="38"
              width="110"
            />
          </a>
          <div className={classes.grow} />
          <div>
            <Typography>
              <Button
                aria-controls="login-user-menu"
                aria-haspopup="true"
                onClick={handleUserClick}
                style={{ color: 'white' }}
              >
                {userData.idToken.payload.name}
              </Button>
              <Menu
                id="login-user-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleCloseUserMenu}
              >
                <MenuItem onClick={handleCloseUserMenu}>Logout</MenuItem>
              </Menu>
            </Typography>
          </div>
        </Toolbar>
        <Toolbar variant="dense" className={classes.toolBarColor}>
          <Tabs
            // onChange={changeTab}
            variant="fullWidth"
            variant="scrollable"
            scrollButtons="auto"
            style={{ width: '100%', maxHeight: '47px' }}
          >
            <Tab
              label="Dashboard"
              className={classes.tabCss}
              icon={<FontAwesomeIcon icon={faTachometerAlt} />}
              to="/dashboard"
              component={Link}
            />
            <Tab
              label="Analytics"
              className={classes.tabCss}
              icon={<FontAwesomeIcon icon={faChartBar} />}
              to="/analytics"
              component={Link}
            />
            <Tab
              label="Reports"
              className={classes.tabCss}
              icon={<FontAwesomeIcon icon={faClipboardCheck} />}
              to="/reports"
              component={Link}
            />
            <Tab
              label="Setup"
              className={classes.tabCss}
              icon={<FontAwesomeIcon icon={faNetworkWired} />}
              to="/setup"
              // onClick={() => handleSetupMenuClick()}
              component={Link}
            />

            <Tab
              label="Alerts"
              className={classes.tabCss}
              to={`/alerts`}
              component={Link}
            />
            <Tab
              label="Settings"
              href="/home/settings"
              icon={<DashboardIcon />}
              className={classes.tabCss}
              icon={<FontAwesomeIcon icon={faCog} />}
              to="/settings"
              component={Link}
            />
          </Tabs>
        </Toolbar>
      </AppBar>
    </div>
  );
}
