import { HighchartsReact } from 'highcharts-react-official';
import { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import axios from 'axios';
import moment from 'moment';
var { jStat } = require('jstat');
let discipline = [
  {
    name: '20',
    data: '20'
  }
];

const Scatterplot = ({
  chartData,
  startDate,
  endDate,
  parameter,
  refSensors
}) => {
  const data = [];

  const [chartConfig, setChartConfig] = useState([]);
  const [yAxisMax, setYAxisMax] = useState();
  const [yAxisMin, setYAxisMin] = useState();

  const [xAxisMax, setXAxisMax] = useState();
  const [xAxisMin, setXAxisMin] = useState();

  useEffect(() => {
    if (chartData && chartData.linearFitData) {
      var keys = Object.keys(chartData.linearFitData).filter(
        p => p.indexOf('linear_fit') != -1
      );
      const refSensorData = chartData.linearFitData.ref_sensor;
      const series = [];
      const xAxisData = [];
      const yAxisData = [];

      keys.forEach(key => {
        var data = chartData.linearFitData[key];
        var finalData = [];
        //sensor data
        data.forEach((da, index) => {
          finalData.push([refSensorData[index], da]);
          xAxisData.push(da);
          yAxisData.push(refSensorData[index]);
          //tempData.push(fiteredRefData[0].data[index][1]);
        });
        series.push({
          name: key.replace('linear_fit_', ''),
          type: 'line',
          data: finalData
        });
      });
      if (chartData.detailed) {
        chartData.detailed
          .filter(value => {
            return value.name.indexOf('sensor_to_cal') != -1;
          })
          .forEach(value => {
            var finalData = [];
            value.data.forEach((da, index) => {
              finalData.push([refSensorData[index], da[1]]);

              //tempData.push(fiteredRefData[0].data[index][1]);
            });
            series.push({
              name: value.name,
              data: finalData,
              type: 'scatter'
            });
          });
      }

      const maxData = Math.max(...yAxisData);
      const minData = Math.min(...yAxisData);
      setYAxisMin(minData);
      setYAxisMax(maxData);

      const maxData1 = Math.max(...xAxisData);
      const minData1 = Math.min(...xAxisData);
      setXAxisMax(maxData1);
      setXAxisMin(minData1);

      setChartConfig(series);
    }
  }, [chartData]);
  const finalData = {
    chart: {
      type: 'scatter',

      title: '',
      height: 300
    },
    title: {
      text: 'Linear fit'
    },
    subtitle: {
      text: ''
    },

    xAxis: {
      title: {
        text: 'Temp'
      },
      labels: {
        format: '{value}'
      }
      //min: xAxisMin,
      //max: xAxisMax
    },

    yAxis: {
      title: {
        text: `${parameter} (${refSensors})`
      },
      //min: yAxisMin,
      //max: yAxisMax,
      labels: {}
    },

    legend: {
      enabled: true
    },

    tooltip: {},

    series: chartConfig
  };

  return <HighchartsReact highcharts={Highcharts} options={finalData} />;
};

function calculateTimeRange(startDate, endDate) {
  if (startDate && endDate) {
    var a = moment(startDate);
    var b = moment(endDate);

    var diff = b.diff(a, 'days');
    if (diff <= 1) {
      return 'HH:mm';
    } else if (diff > 1 && diff <= 30) {
      return 'MM/DD HH:mm';
    } else if (diff >= 30 && diff <= 365) {
      return 'MM/DD';
    } else if (diff >= 365) {
      return 'yyyy/MM/DD';
    }
  }
}

export default Scatterplot;
