import { Chip, makeStyles } from '@material-ui/core';

import React, { useEffect, useState } from 'react';
import Moment from 'react-moment';
import { Link, useParams } from 'react-router-dom';
import SettingsService from '../../settings/SettingsService';
import PermPhoneMsgIcon from '@material-ui/icons/PermPhoneMsg';
import ReactHighcharts from 'react-highcharts';
import xrange from 'highcharts/modules/xrange';
import axiosInstance from '../../_helpers/interceptor';
import { DEV_DOMAIN_URL } from '../../environment';
import moment from 'moment';
import _ from 'lodash';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {
  Descriptions,
  Empty,
  DatePicker,
  Card,
  Spin,
  Space,
  Typography,
  Button,
  Col,
  Row,
  Badge,
  List
} from 'antd';
import { VolumeMuteOutlined, VolumeOffOutlined } from '@material-ui/icons';
import {
  DownCircleFilled,
  DownCircleOutlined,
  DownloadOutlined
} from '@ant-design/icons';
import { subHours } from 'date-fns';
import { stat } from 'fs';

const { RangePicker } = DatePicker;

xrange(ReactHighcharts.Highcharts);
const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary
  }
}));

const RuleView = () => {
  const params = useParams();
  const classes = useStyles();
  const [ruleData, setRuleData] = useState({});

  const [xRangeData, setXRangeData] = useState([]);
  const [sensorIds, setSensorIds] = useState([]);

  const [date, setDate] = useState([
    moment().tz('America/New_York').startOf('day'),
    moment().tz('America/New_York').valueOf()
  ]);
  const [loading, setLoading] = useState(true);
  const [alertStatus, setAlertStatus] = useState([]);

  const changeMuteStatus = () => {
    axiosInstance
      .post(`${DEV_DOMAIN_URL}/alerts/${params.id}/snooze`)
      .then(responseData => {
        if (responseData.status === 200) {
          fetchData();
        }
      });
  };

  const fetchData = () => {
    SettingsService.getSingleRule(params.id).then(responseData => {
      setRuleData(responseData.data);
    });
  };

  const getDashboardData = (start, end, alertDetails) => {
    axiosInstance
      .get(`${DEV_DOMAIN_URL}/alerts/chart/${params.id}/${start}/${end}`)
      .then(res => {
        const data = res.data;
        const result = [];
        const sensors = [];
        const sensorsGroupBy = _.groupBy(data, 'serialNumber');

        Object.keys(sensorsGroupBy).forEach((key, index) => {
          const alertData = sensorsGroupBy[key];
          sensors.push(parseInt(key));
          for (let i = 0; i < alertData.length; i++) {
            let avg = alertData[i].maxValue;
            if (alertData[i].status === 'In Threshold') {
              avg = alertData[i].minValue;
            }
            const temp = {
              x: alertData[i].timestamp,
              x2: alertData[i].endDate,
              y: index,
              value: avg,
              color:
                avg > Number(alertDetails?.toValue)
                  ? '#F25022'
                  : avg < Number(alertDetails?.fromValue)
                  ? '#00A4EF'
                  : '#7FBA00'
            };
            result.push(temp);
          }
        });

        setSensorIds(_.uniq(sensors));

        setXRangeData(result);
      });
  };

  const onChangeDate = temp => {
    setLoading(true);
    setDate(temp);
    getDashboardData(
      moment(temp[0]).tz('America/New_York').startOf('day').valueOf(),
      moment(temp[1]).tz('America/New_York').endOf('day').valueOf(),
      ruleData
    );
  };

  useEffect(() => {
    SettingsService.getSingleRule(params.id).then(responseData => {
      setRuleData(responseData.data);
      getDashboardData(
        moment().tz('America/New_York').startOf('day').valueOf(),
        moment().tz('America/New_York').valueOf(),
        responseData.data
      );
    });
  }, [params.id]);

  const download = () => {
    const startDate = moment(date[0]).startOf('day').valueOf();
    const endDate = moment(date[1]).endOf('day').valueOf();
    const url = `${DEV_DOMAIN_URL}/alerts/download/${params.id}/${startDate}/${endDate}`;

    axiosInstance.get(url).then(responseData => {
      if (responseData.status === 200) {
        const element = document.createElement('a');
        const file = new Blob([responseData.data], { type: 'text/plain' });
        element.href = URL.createObjectURL(file);
        element.download = 'alerts_' + new Date().getTime() + '.csv';
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
      }
    });
  };

  return (
    <div style={{ margin: '1%' }}>
      <>
        <Card>
          <Descriptions
            column={2}
            title={
              <Space align="baseline">
                <Typography.Text type="Secondary">Properties</Typography.Text>
              </Space>
            }
            extra={
              <React.Fragment>
                <Link to="/alerts">
                  <Button type="text" icon={<ArrowBackIcon />}>
                    Back
                  </Button>
                </Link>
                <Button
                  type="text"
                  onClick={download}
                  icon={<DownloadOutlined />}
                >
                  Download
                </Button>
                {ruleData.status && ruleData.status.toLowerCase() === 'active' && (
                  <Button
                    onClick={changeMuteStatus}
                    type="text"
                    icon={<VolumeMuteOutlined />}
                  >
                    Mute
                  </Button>
                )}
                {ruleData.status &&
                  ruleData.status.toLowerCase() === 'inactive' && (
                    <Button
                      onClick={changeMuteStatus}
                      type="text"
                      icon={<VolumeOffOutlined />}
                    >
                      UnMute
                    </Button>
                  )}
              </React.Fragment>
            }
          >
            {/* <div>Alert for {ruleData.parameterName},  client {ruleData.clientName}</div></Space> } size='default'> */}
            <Descriptions.Item label="Created at">
              <Moment format="MMM Do YYYY h:mm a">
                {ruleData.createdDate}
              </Moment>
              &nbsp; by {ruleData.createdBy}
            </Descriptions.Item>
            <Descriptions.Item label="Query">{`${ruleData.aggType}(${ruleData.parameterName})  between ${ruleData.fromValue} and ${ruleData.toValue} within ${ruleData.timeValue} ${ruleData.timeUnit}`}</Descriptions.Item>
            <Descriptions.Item label="Priority">
              {ruleData.priority}
            </Descriptions.Item>
            <Descriptions.Item label="Mobile">
              <PermPhoneMsgIcon fontSize="small" /> {ruleData.mobileNumber}
            </Descriptions.Item>
            <Descriptions.Item label="Email">
              {!ruleData.notification ? 'N/A' : ruleData.notification}
            </Descriptions.Item>
          </Descriptions>
        </Card>
        <br />
        {xRangeData && xRangeData.length === 0 && (
          <Empty
            image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
            imageStyle={{
              height: 60
            }}
            description={<span>No Data Found</span>}
          ></Empty>
        )}
        {xRangeData && xRangeData.length > 0 && (
          <Card
            title={`Alert Details`}
            size="small"
            extra={
              <RangePicker
                onChange={onChangeDate}
                placement="bottomLeft"
                defaultValue={[
                  moment().tz('America/New_York').startOf('day'),
                  moment().tz('America/New_York')
                ]}
              />
            }
          >
            <Row>
              <Col span={20}>
                <RenderXRange chartData={xRangeData} sensors={sensorIds} />
              </Col>
              <Col span={4}>
                <Row
                  justify="space-around"
                  align="middle"
                  style={{ marginTop: '25%', marginLeft: '20%' }}
                >
                  <Col span={24}>
                    <Space>
                      <div
                        style={{
                          height: '15px',
                          width: '15px',
                          borderRadius: '50%',
                          backgroundColor: '#F25022'
                        }}
                      ></div>
                      <Button type="text">Above Threshold</Button>
                    </Space>
                  </Col>
                  <Col span={24}>
                    <Space>
                      <div
                        style={{
                          height: '15px',
                          width: '15px',
                          borderRadius: '50%',
                          backgroundColor: '#00A4EF'
                        }}
                      ></div>
                      <Button type="text">Below Threshold</Button>
                    </Space>
                  </Col>
                  <Col span={24}>
                    <Space>
                      <div
                        style={{
                          height: '15px',
                          width: '15px',
                          borderRadius: '50%',
                          backgroundColor: '#7FBA00'
                        }}
                      ></div>
                      <Button type="text">In Threshold</Button>
                    </Space>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card>
        )}
      </>
    </div>
  );
};

const config = {
  title: '',
  chart: {
    type: 'xrange'
  },
  xAxis: {
    labels: {
      formatter: function () {
        return moment
          .utc(this.value)
          .tz('America/New_York')
          .format('YYYY/MM/DD hh:mm A');
      }
    }
  },

  yAxis: {
    title: {
      text: ''
    },

    reversed: true
  },
  plotOptions: {
    series: {
      connectNulls: true
    }
  },
  series: []
};

const RenderXRange = ({ chartData, sensors }) => {
  const tempData = _.cloneDeep(config);
  tempData['yAxis']['categories'] = sensors;
  tempData['tooltip'] = {
    formatter: function () {
      return `<b>${sensors[this.y]} </b> - <b>${
        this.point.color == 'green' ? 'Active' : 'Alert'
      } - Value: ${this.point.value}</b>
            <br\>
            ${moment
              .utc(this.point.x)
              .tz('America/New_York')
              .format('YYYY/MM/DD hh:mm A')} - ${moment
        .utc(this.point.x2)
        .tz('America/New_York')
        .format('YYYY/MM/DD hh:mm A')}
            <br/>`;
    }
  };
  tempData['series'] = [
    {
      name: 'Sensors',
      data: chartData
    }
  ];

  console.log(tempData);

  return <ReactHighcharts config={tempData} />;
};

export default RuleView;
