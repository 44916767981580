import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import CreateIcon from "@material-ui/icons/Create";
import { DataGrid } from "@material-ui/data-grid";
import DeleteIcon from "@material-ui/icons/Delete";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import ErrorIcon from "@material-ui/icons/Error";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import MaterialTable from "material-table";
import { NavLink } from "react-router-dom";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import { forwardRef, useEffect, useState } from "react";
import deviceService from "../../../api/deviceService";
import { ViewColumn } from "@material-ui/icons";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  Input,
  InputLabel,
  makeStyles,
} from "@material-ui/core";

import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { toast } from "react-toastify";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => (
    <DeleteOutline color={"error"} {...props} ref={ref} />
  )),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  CreateIcon: forwardRef((props, ref) => <CreateIcon {...props} ref={ref} />),
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 440,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const ViewClients = (props) => {
  const [data, setData] = useState([]);
  const [showModel, setShowModel] = useState(false);
  const [editData, setEditData] = useState({});
  const [deleteId, setDeleteId] = useState(0);

  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleClose = () => {
    setShowModel(!showModel);
  };
  const handleCloseConfirmation = () => {
    setShowConfirmation(!showConfirmation);
  };

  const refresh = () => {
    deviceService.getClients().then((responseData) => {
      setData(responseData.data);
    });
  };

  useEffect(() => {
    refresh();
  }, []);

  const deleteClient = (data) => {
    setShowConfirmation(!showConfirmation);
    setDeleteId(data);
  };

  const editClient = (data) => {
    setEditData(data);
    setShowModel(!showModel);
  };

  return (
    <div className="equal-height-container">
      <Grid container spacing={1}>
        <Grid container item xs={12}>
          <div style={{ width: "100%" }}>
            <MaterialTable
              icons={tableIcons}
              title="Clients"
              columns={[
                { title: "Client Name", field: "clientName" },
                {
                  title: "Gateway",
                  field: "gatewaySerialNumber",
                },
                {
                  title: "Contact",
                  field: "ownerEmail",
                },
                {
                  title: "Address",
                  field: "address",
                },

                {
                  title: "City",
                  field: "city",
                },
                { title: "State", field: "state" },
                { title: "Country", field: "country" },
              ]}
              data={data}
              actions={[
                {
                  icon: "delete",
                  tooltip: "Delete Device",
                  iconProps: { style: { fontSize: "14px", color: "red" } },
                  onClick: (event, rowData) => deleteClient(rowData.id),
                },
                {
                  icon: "edit",
                  tooltip: "Edit Device",
                  iconProps: { style: { fontSize: "14px" } },
                  onClick: (event, rowData) => editClient(rowData),
                },
                {
                  icon: "add",
                  tooltip: "Create Client",
                  isFreeAction: true,
                  onClick: (event) => handleClose(),
                },
              ]}
              options={{
                actionsColumnIndex: -1,
              }}
            />
          </div>
        </Grid>
      </Grid>
      <RenderModal
        refresh={refresh}
        data={editData}
        open={showModel}
        handleClose={handleClose}
      />
      <RenderConfirmation
        refresh={refresh}
        data={deleteId}
        open={showConfirmation}
        handleClose={handleCloseConfirmation}
      />
    </div>
  );
};

const RenderConfirmation = (props) => {
  const classes = useStyles();
  const closeModel = () => {
    props.refresh();
    props.handleClose();
  };

  const deleteClient = () => {
    console.log(props.data);
    deviceService
      .deleteClient(props.data)
      .then((responseData) => {
        if (responseData.status === 200) {
          toast.success("succesfully deleted the client");
        }
      })
      .catch((e) => {
        toast.error("uanable to delete the client");
      });
  };

  return (
    <Dialog
      open={props.open}
      onClose={closeModel}
      aria-labelledby="form-dialog-title"
      maxWidth="md"
      fullWidth={true}
    >
      <DialogTitle id="form-dialog-title">Delete Client </DialogTitle>
      <DialogContent dividers>
        Deleting the client will delete releated sesnsors as well. can't undo
        the deletion. Click OK to continue.
      </DialogContent>
      <DialogActions>
        <Button type="button" onClick={deleteClient} color="primary">
          OK
        </Button>
        <Button onClick={closeModel} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const RenderModal = (props) => {
  const classes = useStyles();
  const closeModel = () => {
    props.refresh();
    props.handleClose();
  };
  const [formData, setFormData] = useState({
    id: 0,
    clientName: "",
    address: "",
    zipCode: "",
    state: "",
    city: "",
    country: "",
    gatewaySerialNumber: "",
  });
  useEffect(() => {
    setFormData(props.data);
  }, [props.data]);
  const onFormChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const submitForm = () => {
    deviceService
      .saveClient(formData)
      .then((responseData) => {
        if (responseData.status === 200) {
          toast.success("Client creation was succesful");
        }
        if (responseData.status === 500) {
          toast.success("Not able to create client. Client may exists");
        }
      })
      .catch((error) => {
        toast.error("Not able to create client. Client may exists");
      });
  };

  return (
    <Dialog
      open={props.open}
      onClose={closeModel}
      aria-labelledby="form-dialog-title"
      maxWidth="md"
      fullWidth={true}
    >
      <DialogTitle id="form-dialog-title">Create new client</DialogTitle>
      <ValidatorForm onSubmit={submitForm} className={classes.form}>
        <DialogContent dividers>
          <FormControl style={{ width: "80%", marginBottom: "20px" }}>
            <TextValidator
              label="Client name"
              fullWidth
              onChange={onFormChange}
              name="clientName"
              value={formData.clientName}
              aria-describedby="my-helper-text"
              validators={["required"]}
              errorMessages={["this field is required"]}
            />
          </FormControl>
          <FormControl style={{ width: "80%", marginBottom: "20px" }}>
            <TextValidator
              label="Gateway Number"
              fullWidth
              onChange={onFormChange}
              name="gatewaySerialNumber"
              value={formData.gatewaySerialNumber}
              aria-describedby="my-helper-text"
              validators={["required"]}
              errorMessages={["this field is required"]}
            />
          </FormControl>
          <FormControl style={{ width: "80%", marginBottom: "20px" }}>
            <TextValidator
              label="Contact"
              fullWidth
              onChange={onFormChange}
              name="ownerEmail"
              value={formData.ownerEmail}
              aria-describedby="my-helper-text"
              validators={["required", "isEmail"]}
              errorMessages={["this field is required", "email is not valid"]}
            />
          </FormControl>

          <FormControl style={{ width: "80%", marginBottom: "20px" }}>
            <TextValidator
              label="Address"
              fullWidth
              onChange={onFormChange}
              name="address"
              value={formData.address}
              aria-describedby="my-helper-text"
            />
          </FormControl>
          <FormControl style={{ width: "80%", marginBottom: "20px" }}>
            <TextValidator
              label="State"
              fullWidth
              onChange={onFormChange}
              name="state"
              value={formData.state}
              aria-describedby="my-helper-text"
            />
          </FormControl>
          <FormControl style={{ width: "80%", marginBottom: "20px" }}>
            <TextValidator
              label="City"
              fullWidth
              onChange={onFormChange}
              name="city"
              value={formData.city}
              aria-describedby="my-helper-text"
            />
          </FormControl>
          <FormControl style={{ width: "80%", marginBottom: "20px" }}>
            <TextValidator
              label="Zipcode"
              fullWidth
              onChange={onFormChange}
              name="zipCode"
              value={formData.zipCode}
              aria-describedby="my-helper-text"
            />
          </FormControl>
          <FormControl style={{ width: "80%", marginBottom: "20px" }}>
            <TextValidator
              label="Country"
              fullWidth
              onChange={onFormChange}
              name="country"
              value={formData.country}
              aria-describedby="my-helper-text"
            />
          </FormControl>
        </DialogContent>

        <DialogActions>
          <Button type="submit" color="primary">
            Submit
          </Button>
          <Button onClick={closeModel} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </ValidatorForm>
    </Dialog>
  );
};

export default ViewClients;
