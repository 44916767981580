// @ts-nocheck

import { G2, Heatmap } from '@ant-design/charts';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import ColorScale from './ColorScale';
import _ from 'lodash';
import { sort } from '../utils/util';
import analyticsService from '../api/analyticsService';
import BarLoader from 'react-bar-loader';
const DemoHeatmapHours = props => {
  //const [data, setData] = useState([]);
  const [trendsData, setTrendsData] = useState({});
  const [loading, setLoading] = useState(false);
  const { data } = props;
  const [filterData, setFilterData] = useState([]);
  const onInputChange = (min, max) => {
    const tempData = _.filter(trendsData, function (o) {
      if (!o[data.parameter]) {
        return true;
      }
      return o[data.parameter] >= min && o[data.parameter] <= max;
    });
    setFilterData(tempData);
  };

  useEffect(() => {
    var res = [];
    const finalData = data;
    finalData['type'] = 'hourlyHeat';
    setLoading(true);
    analyticsService.getTrends(finalData).then(responseData => {
      var sortresH = sort(responseData.data.heatMapResponse);
      setLoading(false);
      const newObj = Object.entries(sortresH).reduce((newObj, [key, val]) => {
        if (Object.keys(newObj).length != 0) {
          const lastDate = Object.keys(newObj).length - 1;
          var missingMonthsNo =
            (Date.parse(key.split(' ')[0]) -
              Date.parse(Object.keys(newObj)[lastDate].split(' ')[0])) /
            (1000 * 3600 * 24);

          if (missingMonthsNo > 1) {
            for (var i = 1; i < missingMonthsNo; i++) {
              const oldDate = new Date(
                Object.keys(newObj)[lastDate].split(' ')[0]
              );
              const newDate = oldDate + 1;
              const formattedDate = moment(oldDate)
                .add(1, 'd')
                .format('MM.DD.yyyy');
              for (var k = 0; k <= 23; k++) {
                newObj[formattedDate + ' ' + k] = 0;
              }
            }
          } else {
            newObj[key] = val;
          }
        } else {
          newObj[key] = val;
        }

        return newObj;
      }, {});

      console.log(newObj);

      for (const [key, value] of Object.entries(newObj)) {
        var rObj = {};
        //   finalDateObj.setDate(dateObj.getDate() +parseInt(`${key}`));
        var extractDateHr = key.split(' ');
        var eDate = extractDateHr[0];
        var eHr = extractDateHr[1];

        // eslint-disable-next-line no-useless-concat

        rObj['date'] =
          ('0' + (new Date(eDate).getMonth() + 1)).slice(-2) +
          '/' +
          ('0' + new Date(eDate).getDate()).slice(-2);
        if (value !== 0) {
          rObj[data.parameter] = parseInt(value);
          rObj['hours'] = parseInt(eHr);
        }

        res.push(rObj);
      }
      setTrendsData(res);
      setFilterData(res);
    });
  }, [data]);
  var colors = ['#060CB1', '#1792FF', '#5BFF99', '#FBF115', '#B61511'];
  var config = {
    data: filterData,
    height: 800,
    autoFit: false,
    xField: 'date',
    yField: 'hours',

    colorField: data.parameter,
    reflect: 'y',
    color: colors,

    shape: 'boundary-polygon',

    meta: {
      sale: {
        min: 1
      },
      min: 1,
      week: { type: 'cat' },
      commits: { sync: true },
      date: {
        type: 'cat'
      },
      formatter: function (value) {
        console.log('value>>' + value);
      },

      hours: {
        type: 'cat',
        values: [
          '0',
          '1',
          '2',
          '3',
          '4',
          '5',
          '6',
          '7',
          '8',
          '9',
          '10',
          '11',
          '12',
          '13',
          '14',
          '15',
          '16',
          '17',
          '18',
          '19',
          '20',
          '21',
          '22',
          '23'
        ]
      }
    },
    yAxis: { grid: null },
    tooltip: {
      title: 'date',
      fields: [data.parameter],
      showMarkers: false
    },
    interactions: [{ type: 'element-active' }],
    xAxis: {
      position: 'top',
      tickLine: null,
      line: null,

      label: {
        offset: 12,
        style: {
          fontSize: 12,
          fill: '#666',
          textBaseline: 'top'
        }
      }
    }
  };

  return (
    <>
      {loading && <BarLoader color="#1D8BF1" height="2" fullWidth />}
      <ColorScale
        data={trendsData}
        filterData={filterData}
        parameter={data.parameter}
        onInputChange={onInputChange}
      />
      <Heatmap {...config} />
    </>
  );
};

export default DemoHeatmapHours;
