import {
  Button,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField
} from '@material-ui/core';
import React, { forwardRef, useEffect, useState } from 'react';
import {
  SelectValidator,
  TextValidator,
  ValidatorForm
} from 'react-material-ui-form-validator';
import { ToastContainer, toast } from 'react-toastify';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { styled } from '@mui/material/styles';
import { DataGrid } from '@material-ui/data-grid';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/Edit';
import FormControl from '@material-ui/core/FormControl';
import GetAppIcon from '@material-ui/icons/GetApp';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import ReferencedScalingFactor from './ReferencedScalingFactor';
import Select from '@material-ui/core/Select';
import SettingsService from '../SettingsService';
import _ from 'lodash';
import axios from 'axios';
import ReferenceChart from './ReferenceChart';
import Scatterplot from './Scatterplot';
import CorrectedValues from './CorrectedValues';
import ReferenceSensorForm from './SaveReferenceSensorModel';
import { CloudUploadOutlined } from '@material-ui/icons';
import MaterialTable from 'material-table';
import moment from 'moment';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1
});

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary
  }
}));

const ScalingFactor = () => {
  const classes = useStyles();
  const downloadFile = () => {
    window.open('/api/v1/sensor/scaling/download');
  };
  return (
    <div className={classes.root}>
      <RenderAlerts />
    </div>
  );
};

const RenderAlerts = props => {
  const classes = useStyles();
  const theme = useTheme();
  const [rows, setRows] = useState([]);
  const [selectedSensors, setSelectedSensors] = React.useState([]);

  const [formData, setFormData] = useState({
    fromSensors: [],
    toSensors: [],
    parameter: '',
    startDate: '',
    endDate: ''
  });
  const [chartData, setChartData] = useState({});
  const [finalRows, setFinalRows] = useState([]);
  const [sensors, setSensors] = useState([]);
  const [userSettings, setUserSettings] = useState({});

  const showErrorMessage = message => {
    toast.error(message, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
    });
  };

  const deleteRow = id => {
    SettingsService.deleteScalingFactor(id).then(responseData => {
      if (responseData.status === 200) {
        toast.success('Deleted Successfully', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
        fetechAllScalingFactors();
      }
    });
  };
  const [showRefFactors, setShowRefFactors] = useState(false);

  const toggle = () => {
    setShowRefFactors(!showRefFactors);
  };

  const fetechAllScalingFactors = () => {
    SettingsService.getClients().then(responseData => {
      const tempDevices = _.uniqBy(responseData.data, 'serial_number');
      setSelectedSensors(tempDevices);
    });
  };

  useEffect(() => {
    fetechAllScalingFactors();
    fetchUserSettings();
  }, [showRefFactors]);

  const fetchUserSettings = () => {
    SettingsService.getUserSettings().then(
      res => {
        if (Object.values(res.data).length > 0) {
          setUserSettings({
            pnType: res.data.pnType,
            pmType: res.data.pmType,
            pressure: res.data.pressure,
            relativeHumidity: res.data.relativeHumidity,
            temperature: res.data.temperature,
            tvoc: res.data.tvoc,
            co2: res.data.co2,
            refresh: res.data.liveRefreshTime,
            timeZone: res.data.timeZone
          });
        }
      },
      err => {}
    );
  };

  const handleChange = event => {
    const tempFormData = _.cloneDeep(formData);
    tempFormData[event.target.name] = event.target.value;
    setFormData(tempFormData);
  };

  const download = () => {
    window.open('/api/v1/sensor/scaling/download');
    // window.open("http://localhost:8080/api/v1/sensor/scaling/download");
  };

  const [showModel, setShowModel] = useState(false);

  const [files, setFiles] = React.useState([]);
  const handleFileChange = e => {
    if (e.target.files) {
      uploadFile(e.target.files[0]);
      setFiles(e.target.files);
    }
  };

  const uploadFile = file => {
    const userName = sessionStorage.getItem('user_name');
    const formData = new FormData();
    formData.append('file', file);

    const options = {
      headers: { user_name: userName }
    };
    axios
      .post(
        '/backend/api/v1/sensor/scaling//calibration/uploadAndGetSensors',
        formData,
        options
      )
      .then(responseData => {
        if (responseData.status === 200) {
          toast.success('Succesfully uploaded the file');
          const tempData = responseData.data.map(value => {
            return { serial_number: value };
          });

          setSelectedSensors(tempData);
          //refresh();
        }
      })
      .catch(function (error) {
        toast.error('Unable to upload the document');
      });
  };

  const handleClose = () => {
    setShowModel(!showModel);
  };
  const doCalc = () => {
    if (files.length == 0) {
      if (formData.fromSensors.length == 0) {
        toast.error('Please Select Reference Sensor');
        return false;
      }
      if (formData.toSensors.length == 0) {
        toast.error('Please Select Sensors to calibration');
        return false;
      }
      if (!formData.parameter) {
        toast.error('Please Select Parameter');
        return false;
      }
      if (!formData.startDate) {
        toast.error('Please Select start date');
        return false;
      }
      if (!formData.endDate) {
        toast.error('Please Select end date');
        return false;
      }
      const tempData = {
        fromSensors: formData.fromSensors.join(','),
        toSensor: formData.toSensors.join(','),
        parameter: formData.parameter,
        startDate: formData.startDate,
        endDate: formData.endDate
      };
      axios
        .post('/backend/api/v1/sensor/scaling/get-calculations', tempData)
        .then(responseData => {
          setChartData(responseData.data);
        })
        .catch(error => {
          toast.error(
            'Not able to calibrate for selected sensors, Please select appropriate parameters '
          );
        });
    } else {
      if (formData.fromSensors.length == 0) {
        toast.error('Please Select Reference Sensor');
        return false;
      }
      if (formData.toSensors.length == 0) {
        toast.error('Please Select Sensors to calibration');
        return false;
      }
      const options = {
        headers: { user_name: 'userName' }
      };
      const tempFormData = new FormData();
      tempFormData.append('file', files[0]);
      tempFormData.append('fromSensors', formData.fromSensors.join(','));
      tempFormData.append('toSensor', formData.toSensors.join(','));
      axios
        .post(
          '/backend/api/v1/sensor/scaling//calibration/upload',
          tempFormData,
          options
        )
        .then(responseData => {
          if (responseData.status === 200) {
            toast.success('Succesfully uploaded the file');
          }
        })
        .catch(function (error) {
          toast.error('Unable to upload the document');
        });
    }
  };

  const clear = () => {
    setFiles([]);
    selectedSensors([]);
    fetechAllScalingFactors();
  };

  return (
    <React.Fragment>
      {!showRefFactors && (
        <>
          <Grid container spacing={3}>
            <React.Fragment>
              <Grid item xs={2}>
                <FormControl
                  style={{ width: '100%' }}
                  className={classes.formControl}
                >
                  <InputLabel id="demo-mutiple-name-label">
                    Reference Sensor
                  </InputLabel>
                  <Select
                    labelId="demo-mutiple-name-label"
                    id="demo-mutiple-name"
                    multiple
                    name="fromSensors"
                    value={formData.fromSensors}
                    input={<Input />}
                    MenuProps={MenuProps}
                    onChange={handleChange}
                  >
                    {selectedSensors &&
                      selectedSensors.map(value => {
                        return (
                          <MenuItem value={value.serial_number.toString()}>
                            {value.serial_number.toString()}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={2}>
                <FormControl
                  style={{ width: '100%' }}
                  className={classes.formControl}
                >
                  <InputLabel id="demo-mutiple-name-label">
                    Sensors to calibration
                  </InputLabel>
                  <Select
                    labelId="demo-mutiple-name-label"
                    id="demo-mutiple-name"
                    multiple
                    name="toSensors"
                    value={formData.toSensors}
                    input={<Input />}
                    MenuProps={MenuProps}
                    onChange={handleChange}
                  >
                    {selectedSensors &&
                      selectedSensors.map(value => {
                        return (
                          <MenuItem value={value.serial_number.toString()}>
                            {value.serial_number.toString()}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={2}>
                <FormControl
                  style={{ width: '100%' }}
                  className={classes.formControl}
                >
                  <InputLabel id="demo-mutiple-name-label">
                    Parameters
                  </InputLabel>
                  <Select
                    labelId="demo-mutiple-name-label"
                    id="demo-mutiple-name"
                    name="parameter"
                    value={formData.parameter}
                    input={<Input />}
                    MenuProps={MenuProps}
                    onChange={handleChange}
                  >
                    <MenuItem value="pressure">Pressure</MenuItem>
                    <MenuItem value="Particle Concentration">
                      Particle Concentration
                    </MenuItem>
                    <MenuItem value="temp">Temperature</MenuItem>
                    <MenuItem value="hum">Relative Humidity</MenuItem>

                    <MenuItem value="co2">CO2</MenuItem>
                    <MenuItem value="tvoc">VOCs</MenuItem>
                    <MenuItem value="pm25_m">PM2.5</MenuItem>
                    <MenuItem value="pm10_m">PM10</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={2}>
                <FormControl
                  className={classes.formControl}
                  style={{ width: '100%' }}
                >
                  <TextField
                    fullWidth
                    id={'datetime-local'}
                    label={'Start Time'}
                    type="datetime-local"
                    name="startDate"
                    onChange={handleChange}
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={2}>
                <TextField
                  fullWidth
                  id={'datetime-local'}
                  label={'End Time'}
                  type="datetime-local"
                  name="endDate"
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <Button
                  onClick={doCalc}
                  variant="contained"
                  color="primary"
                  style={{ marginTop: '12px' }}
                >
                  Calculate
                </Button>

                <Button
                  variant="contained"
                  color="primary"
                  style={{ marginTop: '12px', marginLeft: '12px' }}
                  onClick={handleClose}
                >
                  Save
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ marginTop: '12px', marginLeft: '12px' }}
                  onClick={toggle}
                >
                  Existing Calibrations
                </Button>
                <Button component="label" startIcon={<CloudUploadOutlined />}>
                  Upload file
                  <VisuallyHiddenInput
                    onChange={handleFileChange}
                    type="file"
                  />
                </Button>

                <Button
                  variant="contained"
                  color="primary"
                  style={{ marginTop: '12px', marginLeft: '12px' }}
                  onClick={clear}
                >
                  Clear
                </Button>
              </Grid>
            </React.Fragment>

            {showRefFactors && (
              <Button
                variant="contained"
                color="primary"
                onClick={toggle}
                style={{ marginTop: '12px', float: 'right' }}
              >
                Back
              </Button>
            )}
          </Grid>

          <div style={{ height: 700, width: '100%', marginTop: '40px' }}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                {chartData && chartData.detailed && (
                  <ReferenceChart
                    data={chartData}
                    timeSeriesType={formData.parameter}
                    userSettings={userSettings}
                    startDate={formData.startDate}
                    endDate={formData.endDate}
                    selectedDateRange={'custom'}
                  />
                )}
              </Grid>
              <Grid item xs={6}>
                {chartData && chartData.detailed && (
                  <CorrectedValues
                    data={chartData}
                    timeSeriesType={formData.parameter}
                    userSettings={userSettings}
                    startDate={formData.startDate}
                    endDate={formData.endDate}
                    selectedDateRange={'custom'}
                  />
                )}
              </Grid>
              <Grid item xs={6}>
                {chartData && chartData.detailed && (
                  <Scatterplot
                    chartData={chartData}
                    startDate={formData.startDate}
                    endDate={formData.endDate}
                    parameter={formData.parameter}
                    refSensors={formData.fromSensors.join(',')}
                  />
                )}
              </Grid>

              <Grid item xs={6}>
                {chartData && chartData.detailed && (
                  <RenderSlopeAndInterceptTable data={chartData} />
                )}
              </Grid>
            </Grid>
          </div>
          <ReferenceSensorForm
            calibartionFormData={formData}
            data={chartData}
            open={showModel}
            handleClose={handleClose}
          />
        </>
      )}
      {showRefFactors && <RenderTable toggle={toggle} />}
    </React.Fragment>
  );
};

const RenderTable = ({ toggle }) => {
  const [data, setData] = useState();

  axios
    .get('/backend/api/v1/sensor/scaling/get-existing-calibrations')
    .then(responseData => {
      if (responseData.status === 200) {
        setData(responseData.data);
      }
    });

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <Button
            variant="contained"
            color="primary"
            style={{ marginTop: '12px', marginLeft: '12px', float: 'right' }}
            onClick={toggle}
          >
            Back
          </Button>
        </Grid>
        <Grid item xs={12} md={12}>
          <MaterialTable
            title="Scaling Factors"
            columns={[
              { title: 'Ref Sensor', field: 'deviceId' },
              {
                title: 'Sacling date',
                field: 'scalingDate',
                render: rowData => (
                  <>
                    {rowData.scalingDate &&
                      moment(rowData.scalingDate).format('MM/DD/YYYY h:mm a')}
                  </>
                )
              },

              {
                title: 'Scaling Start Date',
                field: 'scalingStartDate',
                render: rowData => (
                  <>
                    {rowData.scalingStartDate &&
                      moment(rowData.scalingStartDate).format(
                        'MM/DD/YYYY h:mm a'
                      )}
                  </>
                )
              },
              {
                title: 'Scaling End Date',
                field: 'scalingEndDate',
                render: rowData => (
                  <>
                    {rowData.scalingEndDate &&
                      moment(rowData.scalingEndDate).format(
                        'MM/DD/YYYY h:mm a'
                      )}
                  </>
                )
              },
              { title: 'Parameter', field: 'parameter' },
              {
                title: 'Scaling Date Range',
                field: 'scalingDateRange',
                render: rowData => (
                  <>
                    {`
                ${moment(rowData.scalingDateRange.split('to')[0].trim()).format(
                  'MM/DD/YYYY h:mm a'
                )} to ${moment(
                      rowData.scalingDateRange.split('to')[1].trim()
                    ).format('MM/DD/YYYY h:mm a')}`}
                  </>
                )
              },
              {
                title: 'Slope',
                field: 'slope',
                render: rowData => <>{rowData.slope.toFixed(2)}</>
              },
              {
                title: 'Intercept',
                field: 'intercept',
                render: rowData => <>{rowData.intercept.toFixed(2)}</>
              },
              { title: 'Created User', field: 'createdUser' },
              { title: 'Update User', field: 'updateUser' }
            ]}
            data={data}
            options={{
              actionsColumnIndex: -1
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};

const RenderSlopeAndInterceptTable = ({ data }) => {
  const finalData = data.linearFitData.slopeAndIntercept;

  console.log(finalData);

  return (
    <TableContainer style={{ maxHeight: 300 }} component={Paper}>
      <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Device</TableCell>
            <TableCell align="right">Slope</TableCell>
            <TableCell align="right">Intercept</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {finalData.map((value, index) => {
            return (
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                key={index}
              >
                <TableCell component="th" scope="row">
                  {value.device}
                </TableCell>
                <TableCell align="right">{value.slope.toFixed(2)}</TableCell>
                <TableCell align="right">
                  {value.intercept.toFixed(2)}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ScalingFactor;
