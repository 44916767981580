

import React, { useEffect, useState } from 'react';
import SettingsService from "../SettingsService"; 
import Input from '@material-ui/core/Input';
import { ValidatorForm, TextValidator,SelectValidator } from 'react-material-ui-form-validator';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { Button, InputLabel, MenuItem, TextField } from '@material-ui/core';
import _ from 'lodash'
import { ToastContainer, toast } from "react-toastify";
import { DataGrid } from '@material-ui/data-grid';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/Edit';
import ListItemText from '@material-ui/core/ListItemText';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
  }));
  

const ReferencedScalingFactor =()=>{

    const [displayCreated,setDisplayCreated] = useState(false);
    const [formData,setFormData] = useState({});
    const toggle = ()=>{
        setDisplayCreated(!displayCreated);
    }
    const showEdit = (params)=>{
        //console.log(params.row);
        setDisplayCreated(!displayCreated);
        setFormData(params.row)
    }
    return <React.Fragment>
        <ToastContainer/>
        <RenderAlertForm editData = {formData}   toggle = {toggle}/>
       
    </React.Fragment> 
}

const RenderAlertsData = ()=>{
   const [rows,setRows] = useState([]);

   

}

const RenderAlertForm = (props)=>{
    const classes = useStyles();

    const [formData,setFormData] = useState(
        {id:0,fromSerialNumber:[],parameterName:'',startDate:'',endDate:'',toSerialNumber:[]}
        );

    const [devices,setDevices] = useState([]);

    useEffect(() => {
        fetchDevices() 
        fetchRefScallings();
        if(props.editData.alertName){
            setFormData(props.editData)
        }
    }, [props.editData])

    const  fetchRefScallings = ()=>{
        SettingsService.getExistingRefSaclingFactors().then((responseData)=>{
            if(responseData.data.fromSensors){
                //2021-06-23T11:19
                const data = {
                    id : responseData.data.id,
                    fromSerialNumber : responseData.data.fromSensors.split(","),
                    parameterName: responseData.data.parameter,
                    startDate: responseData.data.startDate.replace(":00.000+00:00",""),
                    endDate: responseData.data.endDate.replace(":00.000+00:00",""),
                    toSerialNumber:responseData.data.toSensor.split(","),
                }
                setFormData(data);
            }
           
        })
    }

    const fetchDevices =()=>{
        SettingsService.getAllDevices().then((responseData)=>{
            const temp = [];
            for(var i=0;i<responseData.data.length;i++){
                temp.push(responseData.data[i].serial_number.toString())
            }
            setDevices(temp);
        })
    }

    const handleSubmit = ()=>{

        if(formData.fromSerialNumber.length==0){
            showErrorMessage("Please Select Reference Sensors");
            return false;
        }
        if(formData.parameterName===''){
            showErrorMessage("Please Select Parameter");
            return false;
        }
        if(formData.startDate==''){
            showErrorMessage("Please Enter Start Date");
            return false;
        }
        if(formData.endDate==''){
            showErrorMessage("Please Enter End Date");
            return false;
        }

        var startDate = new Date(formData.startDate);
        var endDate = new Date(formData.endDate);    

        if (startDate > endDate){
            showErrorMessage("End Date Shouldn't  be greater than Start Date");
            return false;
        }

        if(formData.toSerialNumber.length===0){
            showErrorMessage("Please Select Sensors to be scaled");
            return false;
        }

            const finalData = {
                id:formData.id,
                fromSensors:formData.fromSerialNumber.join(','),
                parameter:formData.parameterName,
                startDate:formData.startDate,
                endDate:formData.endDate,
                toSensor:formData.toSerialNumber.join(',')
            };
            //{id:0,fromSerialNumber:[],parameterName:'',startDate:'',endDate:'',toSerialNumber:[]}
            SettingsService.createOrUpdateRefSensors(finalData).then((responseData)=>{
                if(responseData.status === 200){
                    toast.success("Saved Successfully", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                      });
                }else{
                    showErrorMessage('uable to save data')
                }
               
            }).catch(error => {
                showErrorMessage('uable to save data reason - Data may not found for that time selection')
            })
       
       
    }
    const showErrorMessage  = (message)=>{
        toast.error(message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
    }

    const handleChange = (event)=>{
        const tempFormData = _.cloneDeep(formData);
        console.log(event.target.value)
        if( Array.isArray(event.target.value)){
            tempFormData[event.target.name] = event.target.value;
            setFormData(tempFormData)
        }else{
            tempFormData[event.target.name] = event.target.value.toString();
            setFormData(tempFormData)
        }
        
    }

    return  (
    <div className={classes.root}>
         
        <ValidatorForm onSubmit={handleSubmit}>
        <Grid container spacing={3}>
        <Grid item xs={6}>

        <InputLabel htmlFor="age-native-simple">Reference Sensors</InputLabel>
        <Select
          labelId="demo-mutiple-name-label"
          id="demo-mutiple-name"
          multiple
          name="fromSerialNumber"
          value={formData.fromSerialNumber}
          onChange={handleChange}
          input={<Input />}
          MenuProps={MenuProps}
          renderValue={(selected) => selected.join(', ')}
        >
         {devices.map((value)=>{
                    return  <MenuItem key={value} value={value}>
                             <Checkbox checked={formData.fromSerialNumber.indexOf(value) > -1} />
                              <ListItemText primary={value} />
                    </MenuItem> 
                })}
        </Select>
                 
        </Grid>
        <Grid item xs={6}>
            <InputLabel htmlFor="age-native-simple">Parameter Name</InputLabel>
            <SelectValidator style = {{width:'50%'}}
                    
                    onChange={(event)=>handleChange(event)}
                    name="parameterName"
                    value={formData.parameterName}
                    validators={['required']}
                    errorMessages={['this field is required']}>
                    <MenuItem value="All">All</MenuItem> 
                   <MenuItem value="temp">temp</MenuItem> 
                   <MenuItem value="hum">hum</MenuItem>
                   <MenuItem value="pressure">pressure</MenuItem>
                   <MenuItem value="CO2">CO2</MenuItem>
                   <MenuItem value="eco2">eco2</MenuItem>
                   <MenuItem value="tvoc">tvoc</MenuItem>
                   <MenuItem value="rawh2">rawh2</MenuItem>
                   <MenuItem value="rawethanol">rawethanol</MenuItem>
                   <MenuItem value="pn03_m">pn03_m</MenuItem>
                   <MenuItem value="pm2.5_m">pm2.5_m</MenuItem>
                   <MenuItem value="pm10_m">pm10_m</MenuItem>

               </SelectValidator>        
        </Grid>
        <Grid item xs={6}>
        <InputLabel htmlFor="age-native-simple">Start Date</InputLabel>    
        <TextField style = {{width:'50%'}}
                
                    onChange={(event)=>handleChange(event)}
                    name="startDate"
                    
                    
                    value={formData.startDate}
                    type="datetime-local"
                   />
                
        </Grid>  
        <Grid item xs={6}>
        <InputLabel htmlFor="age-native-simple">End Date</InputLabel>    
        <TextValidator style = {{width:'50%'}}
                
                    onChange={(event)=>handleChange(event)}
                    name="endDate"
                    value={formData.endDate}
                    validators={['required']}
                    type="datetime-local"
                    errorMessages={['this field is required']}>
               </TextValidator>        
        </Grid>     

            <Grid item xs={6}>
            <InputLabel htmlFor="age-native-simple">Sensors to be scaled</InputLabel>    
            <Select
          labelId="demo-mutiple-name-label"
          id="demo-mutiple-name"
          multiple
          name="toSerialNumber"
          value={formData.toSerialNumber}
          onChange={handleChange}
          input={<Input />}
          MenuProps={MenuProps}
          renderValue={(selected) => selected.join(', ')}
        >
         {devices.map((value)=>{
                    return  <MenuItem key={value} value={value}>
                             <Checkbox checked={formData.toSerialNumber.indexOf(value) > -1} />
                              <ListItemText primary={value} />
                    </MenuItem> 
                })}
        </Select>
            </Grid> 
            
             
            
        
        
        </Grid>
        <br/>
        <Button variant="contained" color="primary" type = "submit">Save </Button>
        </ValidatorForm>
       
    </div>
        
    )

}

const RenderAlerts = (props)=>{
    const [rows,setRows] = useState([]);

    const deleteRow = (id)=>{
        SettingsService.deleteAlerts(id).then((responseData)=>{
            if(responseData.status === 200){
                toast.success("Deleted Successfully", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                  fetchAllAlerts()
            }
        })
    }   

    const columns = [
        { field: 'id', headerName: 'ID', width: 90 },
        { field: 'alertName', headerName: 'Name', width: 250 },
        { field: 'alertDescription', headerName: 'Description', width: 300 },
        { field: 'priority', headerName: 'Priority', width: 100 },
        { field: 'createdBy', headerName: 'createdBy', width: 250 },
        { field: 'Action', headerName: 'Action',flex: 0.3,renderCell: (params) =>(
            <React.Fragment>
                <EditIcon onClick = {()=>props.showEdit(params)} style = {{cursor :'pointer'}} fontSize = "small"/> {" "}
                <DeleteOutlineIcon onClick = {()=>deleteRow(params.row.id)} style = {{cursor :'pointer'}} fontSize = "small"/> 
                
            </React.Fragment>
           
            )},
        
      ];

    const fetchAllAlerts = ()=>{
        SettingsService.getAllAlerts().then((responseData)=>{
            setRows(responseData.data);
        })
    }

    useEffect(() => {
        fetchAllAlerts()
    }, [])

    return <React.Fragment>
            <div style = {{width:'100%'}}>
                  <Button style = {{float:'right'}} variant="contained" color="primary" onClick = {props.toggle}>Create </Button>
            </div>
           
            <div style={{ height: 400, width: '100%',marginTop:'12px' }}>
                 <DataGrid rows={rows} columns={columns} pageSize={5}  />
            </div>
    </React.Fragment> 

}
export default  ReferencedScalingFactor;