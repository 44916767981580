import React from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import _ from 'lodash';
import moment from 'moment';
import { columnRange } from './column-range';

const ComparisionChart = React.memo(
  ({ config, userSettings, comparisionOption }) => {
    const configData = _.cloneDeep(
      getChartConfig(config, userSettings, comparisionOption)
    );
    return <HighchartsReact highcharts={Highcharts} options={configData} />;
  },
  (prevProps, nextProps) => {
    if (_.isEqual(prevProps.config, nextProps.config)) {
      return true; // props are equal
    }
    return false; // props are not equal -> update the component
  }
);

const getChartConfig = (chartData, userSettings, comparisionOption) => {
  //const xAxis = chartData.data.timeChart.xAxis;
  //let columnRange = rangeChartOptions;
  if (comparisionOption === 'Particle Concentration') {
    columnRange.yAxis.title.text = ' ' + userSettings.pnType;

    columnRange.tooltip.valueSuffix = ' ' + userSettings.pnType;
  }
  if (comparisionOption === 'Temperature') {
    columnRange.yAxis.title.text = ' ' + ' ' + userSettings.temperature;
    columnRange.tooltip.valueSuffix = ' ' + userSettings.temperature;
  }
  if (comparisionOption === 'Relative Humidity') {
    columnRange.yAxis.title.text = ' ' + userSettings.relativeHumidity;
    columnRange.tooltip.valueSuffix = ' ' + userSettings.relativeHumidity;
  }
  if (comparisionOption === 'CO2') {
    columnRange.yAxis.title.text = ' ' + userSettings.co2;
    columnRange.tooltip.valueSuffix = ' ' + userSettings.co2;
  }
  if (comparisionOption === 'VCOS') {
    columnRange.yAxis.title.text = ' ' + userSettings.tvoc;
    columnRange.tooltip.valueSuffix = ' ' + userSettings.tvoc;
  }
  if (comparisionOption === 'pressure') {
    columnRange.yAxis.title.text = '"" of ' + userSettings.pressure;
    columnRange.tooltip.valueSuffix = ' "" of ' + userSettings.pressure;
  }
  if (comparisionOption === 'pn25_m') {
    columnRange.yAxis.title.text = ' ' + userSettings.pmType;

    columnRange.tooltip.valueSuffix = ' ' + userSettings.pmType;
  }
  if (comparisionOption === 'pm10_m') {
    columnRange.yAxis.title.text = ' ' + userSettings.pmType;

    columnRange.tooltip.valueSuffix = ' ' + userSettings.pmType;
  }
  columnRange.series = [{ name: 'Sensors', data: chartData.rangeChart.data }];
  columnRange.xAxis.categories = chartData.rangeChart.xAxis;

  return columnRange;
};

function calculateTimeRange(startDate, endDate) {
  var a = moment(startDate);
  var b = moment(endDate);

  var diff = b.diff(a, 'days');
  if (diff <= 1) {
    return 'HH:mm';
  } else if (diff > 1 && diff <= 30) {
    return 'MM/DD HH:mm';
  } else if (diff >= 30 && diff <= 365) {
    return 'MM/DD';
  } else if (diff >= 365) {
    return 'yyyy/MM/DD';
  }
}

export default ComparisionChart;
