import DevicesView from "./Network/Network";
import { DeviceForm } from "./Network/DeviceForm";
import DeviceHeader from "./Network/DeviceHeader";
import React from "react";
import "./Network/setup.css";
class Device extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showView: true,
            sensorNumber: 0,
        };
        this.openCreateOrEditDeviceForm = this.openCreateOrEditDeviceForm.bind(this);
        this.setSensorNumber = this.setSensorNumber.bind(this);
    }

    openCreateOrEditDeviceForm = () => {
        this.setState({
            showView: !this.state.showView,
        });
    };
    componentDidMount() {}
    showEditPage() {
        this.setState({
            showView: false,
        });
    }
    setSensorNumber(number) {
        this.setState({ sensorNumber: number });
        this.setState({ showView: false });
    }

    render() {
        return (
            <React.Fragment>
                {this.state.showView ? (
                    <React.Fragment>
                        <DeviceHeader openCreateOrEditDeviceForm={this.openCreateOrEditDeviceForm} />
                        <DevicesView
                            setSensorNumber={this.setSensorNumber}
                            showEditPage={this.showEditPage}
                            openCreateOrEditDeviceForm={this.openCreateOrEditDeviceForm}
                        />
                    </React.Fragment>
                ) : (
                    <DeviceForm sensorNumber={this.state.sensorNumber} openCreateOrEditDeviceForm={this.openCreateOrEditDeviceForm} />
                )}
            </React.Fragment>
        );
    }
}

export default Device;
