import * as React from 'react';
import chroma from 'chroma-js';
import './index.css';
import { set } from 'date-fns';
import _ from 'lodash';
import { type } from 'os';
const RenderColorScale = props => {
  const { data, changeMinMaxColorScale, isAutoScaleOn } = props;

  const [colors, setColors] = React.useState([]);
  const [minValue, setMinValue] = React.useState(0);
  const [maxValue, setMaxValue] = React.useState(0);

  const onInputChange = (event, type) => {
    if (type === 'min' && event.target.value != '') {
      changeMinMaxColorScale(parseInt(event.target.value), maxValue);
      setMinValue(parseInt(event.target.value));
    } else if (event.target.value != '') {
      changeMinMaxColorScale(minValue, parseInt(event.target.value));
      setMaxValue(parseInt(event.target.value));
    }
  };

  React.useEffect(() => {
    if (data.geoMap && data.geoMap.length > 0) {
      const tempData = data.geoMap.map(value => {
        return value.value | 0;
      });

      const tolerance = 0.005;
      const groupsByDistance = getGroupsByDistance(data.geoMap, tolerance);

      const geoData = groupsByDistance.map(geo => {
        const values = geo.properties.map(pr => {
          return pr.value;
        });
        const sum = values.reduce((a, b) => a + b, 0);
        const avg = sum / values.length || 0;
        return { lat: geo.lat, long: geo.long, value: avg | 0 };
      });

      const tempDataValues = geoData.map(value => {
        return value.value;
      });
      tempDataValues.sort(function (a, b) {
        return a - b;
      });

      let minValue = Math.min(...tempData);
      let maxValue = Math.max(...tempData);

      const particale_color_scale = chroma
        .scale(['#343d99', '#f9f1b9', '#aa0a27'])
        .domain([minValue, maxValue], 1, 'log');

      const tempColors = particale_color_scale.colors(50);
      console.log(tempColors);

      const colors = tempDataValues.map(value => {
        return particale_color_scale(value).toString();
      });
      if (isAutoScaleOn) {
        setMinValue(minValue);
        setMaxValue(maxValue);
      }
      setColors(tempColors);
    }
  }, [data, isAutoScaleOn]);

  return (
    <div className="gradient">
      {colors.map(value => {
        return (
          <span
            className="grad-step"
            style={{ background: `${value}`, width: getWidth(colors.length) }}
          ></span>
        );
      })}
      <input
        onChange={event => onInputChange(event, 'min')}
        type="number"
        name="colorMin"
        value={minValue}
        style={{ width: '50px' }}
        disabled={isAutoScaleOn}
        className="domain-min"
      />
      <input
        onChange={event => onInputChange(event, 'max')}
        name="colorMax"
        type="number"
        value={maxValue}
        disabled={isAutoScaleOn}
        style={{ width: '52px' }}
        className="domain-max"
      />
    </div>
  );
};

const getWidth = numberOfPoints => {
  if (numberOfPoints < 10) {
    return 13;
  }
  if (numberOfPoints < 10 && numberOfPoints >= 20) {
    return 10;
  }
  if (numberOfPoints > 1) {
    return 5;
  }
};

const getDistance = (lat1, lon1, lat2, lon2) => {
  const radlat1 = (Math.PI * lat1) / 180;
  const radlat2 = (Math.PI * lat2) / 180;

  const theta = lon1 - lon2;
  const radtheta = (Math.PI * theta) / 180;

  let dist =
    Math.sin(radlat1) * Math.sin(radlat2) +
    Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
  dist = Math.acos(dist);
  dist = (dist * 180) / Math.PI;
  dist = dist * 60 * 1.1515;
  dist = dist * 1.609344;

  return dist;
};

const getGroupsByDistance = (coordinates, tolerance) => {
  const coordinatesToleranceMap = Array(coordinates.length).fill(1); // 1 = true

  return coordinates.reduce((groups, coordinate, index) => {
    // The tolerance map contains true/false (0/1) values, ignore anything with 0
    if (coordinatesToleranceMap[index] === 0) return groups;

    const { lat, long } = coordinate;

    // This will return the current listing because it's the same lat/lng. We don't need to check it's length because it always has one
    const coordinatesWithinTolerance = coordinates.filter(
      (coordinate, index) => {
        // We're not interested in any listing that have been filtered out already
        if (coordinatesToleranceMap[index] === 0) {
          return false;
        }

        const isSameLatLng = lat === coordinate.lat && long === coordinate.long;

        // Measure distance using Haversine formula
        const isWithinTolerance =
          isSameLatLng ||
          getDistance(lat, long, coordinate.lat, coordinate.long) <= tolerance;

        // Ignore the current listing on the next filter
        if (isWithinTolerance) coordinatesToleranceMap[index] = 0;

        return isWithinTolerance;
      }
    );

    groups.push({
      lat,
      long,
      properties: coordinatesWithinTolerance
    });

    return groups;
  }, []);
};

export default RenderColorScale;
