import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import AnalyticsService from '../api/analyticsService';
import { styled } from '@mui/material/styles';
import { addHours, subHours } from 'date-fns';
import { TagsInput } from 'react-tag-input-component';
import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
  GridRowEditStopReasons,
  GridToolbar,
  GridToolbarExport,
  GridSaveAltIcon
} from '@mui/x-data-grid';
import _, { update } from 'lodash';

import { randomId } from '@mui/x-data-grid-generator';

import SettingsService from '../settings/SettingsService';
import { Chip, Snackbar } from '@material-ui/core';
import { Alert } from '@mui/material';
import {
  AddCircleOutline,
  AddCircleOutlined,
  CancelOutlined,
  CloudUpload,
  CloudUploadOutlined,
  DeleteOutline,
  EditOutlined,
  RestoreOutlined,
  SaveAlt,
  SaveOutlined
} from '@material-ui/icons';
import axios from 'axios';
import { toast } from 'react-toastify';

import LogEntryAnalysis from './LogEntryAnalysis';
import { Link } from 'react-router-dom';
import moment from 'moment';
import './index.css';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1
});

const formatDate = dateStr => {
  var dateObj = moment(dateStr).format('h:mm a MMM D YYYY');
  return dateObj;
};

const pad2 = n => {
  return (n < 10 ? '0' : '') + n;
};

function EditToolbar(props) {
  const {
    setRows,
    setRowModesModel,
    refresh,
    doAnayaslis,
    rows,
    showArchviedLogActivity,
    showArchived
  } = props;
  const id = randomId();
  const [files, setFiles] = React.useState();
  const handleFileChange = e => {
    if (e.target.files) {
      uploadFile(e.target.files[0]);
    }
  };

  const uploadFile = file => {
    const userName = sessionStorage.getItem('user_name');
    const formData = new FormData();
    formData.append('file', file);
    const options = {
      headers: { user_name: userName }
    };
    axios
      .post('/api/v1/analytics/upload-log-entries', formData, options)
      .then(responseData => {
        if (responseData.status === 200) {
          toast.success('Succesfully uploaded the file');
          refresh();
        }
      })
      .catch(function (error) {
        toast.error('Unable to upload the document');
      });
  };

  const handleClick = () => {
    const temprows = _.cloneDeep(rows);
    temprows.unshift({
      id: id,
      serialNumber: '',
      dateTime: new Date(),
      entryLog: '',
      startDate: new Date(),
      endDate: new Date(),
      isNew: true
    });

    setRows(temprows);
    setRowModesModel(oldModel => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: 'name' }
    }));
  };

  return (
    <GridToolbarContainer>
      <Button
        color="primary"
        startIcon={<AddCircleOutlined />}
        onClick={handleClick}
      >
        Add record
      </Button>

      <GridToolbarExport />
      <Button component="label" startIcon={<CloudUploadOutlined />}>
        Upload file
        <VisuallyHiddenInput onChange={handleFileChange} type="file" />
      </Button>

      <Button color="primary" onClick={doAnayaslis}>
        Analyze
      </Button>

      <Button color="primary" onClick={showArchviedLogActivity}>
        {showArchived ? 'Show Archived data' : 'Show Unarchived data'}
      </Button>
    </GridToolbarContainer>
  );
}

export default function FullFeaturedCrudGrid() {
  const [rows, setRows] = React.useState([]);
  const [showArchived, setShowArchived] = React.useState(true);
  const [rowModesModel, setRowModesModel] = React.useState({});
  const [sensors, setSensors] = React.useState([]);
  const [showLogEntryAnalysis, setShowLogEntryAnalysis] = React.useState(false);
  const [snackbar, setSnackbar] = React.useState(null);
  const [analysisData, setAnalysisData] = React.useState([]);
  const [selectedRows, setSelectedRows] = React.useState(null);
  const [rowSelectionModel, setRowSelectionModel] = React.useState([]);
  const fetchEventLogData = () => {
    AnalyticsService.getAllLogEntries().then(responseData => {
      setRows(responseData.data);
      setShowArchived(true);
      //setSensors(tempSensors);
    });
  };

  const fetchAllClients = () => {
    SettingsService.getClients().then(responseData => {
      const allClientData = responseData.data;

      const tempDevices = _.uniqBy(allClientData, 'serial_number').map(
        val => val.serial_number
      );
      setSensors(tempDevices);

      //setAllClients(responseData.data);
    });
  };

  React.useEffect(() => {
    fetchEventLogData();
    fetchAllClients();
  }, []);

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = id => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = id => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = id => () => {
    const data = {
      ids: id
    };
    AnalyticsService.deleteLogEntry(data).then(responseData => {
      if (responseData.status === 204) {
        setSnackbar({
          children: 'Log Entry deleted succesfully',
          severity: 'success'
        });
        fetchEventLogData();
      }
    });
  };

  const handleCancelClick = id => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true }
    });

    const editedRow = rows.find(row => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter(row => row.id !== id));
    }
  };

  const processRowUpdate = newRow => {
    const updatedRow = { ...newRow, isNew: false };
    const isValidRow = validateRow(newRow);
    if (!isValidRow) {
      throw new Error('Log entry , Start date and end date are mandotry!');
    }
    setRows(rows.map(row => (row.id === newRow.id ? updatedRow : row)));
    saveData(newRow);
    return updatedRow;
  };

  const saveData = row => {
    row['startDate'] = new Date(row.startDate).getTime();
    row['endDate'] = new Date(row.endDate).getTime();
    if (row.isNew) {
      row['id'] = 0;
    }
    if (row.id && row.ids && row.ids.length === 0) {
      row['ids'] = [row.id];
    }

    if (row.tags) {
      row['tags'] = row.tags.join(',');
    }

    AnalyticsService.logEvent(row).then(responseData => {
      if (responseData.status === 200) {
        //alert("Saved");

        setSnackbar({
          children: 'Log Entry saved succesfully',
          severity: 'success'
        });
        fetchEventLogData();
        // window.location.reload();
        //props.fetchEventLogData();
      }
    });
  };

  const validateRow = updateRow => {
    if (updateRow.entryLog.trim() === '') {
      return false;
    }
    if (!updateRow.startDate) {
      return false;
    }
    if (!updateRow.alertId && !updateRow.endDate) {
      return false;
    }
    return true;
  };

  const selectRows = newRows => {
    let startDateArray = [];
    let endDateArray = [];
    if (newRows.length > 0) {
      const tempData = [];
      for (var i = 0; i < rows.length; i++) {
        for (var j = 0; j < newRows.length; j++) {
          if (newRows[j] === rows[i].id) {
            let rowStartDate = 0;
            let rowEndDate = 0;
            if (rows[i].startDate) {
              rowStartDate = new Date(rows[i].startDate).getTime();
            }
            if (rows[i].endDate) {
              rowEndDate = new Date(rows[i].endDate).getTime();
            }
            tempData.push({
              sensor: rows[i].serialNumber,
              startDate: rowStartDate,
              endDate: rowEndDate,
              entryLog: rows[i].entryLog
            });
            if (rows[i].startDate) {
              startDateArray.push(new Date(rows[i].startDate).getTime());
            }
            if (rows[i].endDate) {
              endDateArray.push(new Date(rows[i].endDate).getTime());
            }
          }
        }
      }
      const maxStartDate = _.min(startDateArray);
      const minEndDate = _.max(endDateArray);
      let finalStartDate = subHours(new Date(), 24).getTime();
      let finalEndData = new Date().getTime();
      if (maxStartDate) {
        finalStartDate = subHours(maxStartDate, 3).getTime();
      }
      if (minEndDate) {
        finalEndData = addHours(minEndDate, 3).getTime();
      }
      const finalData = {
        sensors: tempData,
        startDate: finalStartDate,
        endDate: finalEndData
      };
      setSelectedRows(finalData);
      //props.enableAnalyzeButton(finalData, true);
    } else {
      //props.enableAnalyzeButton({ sensors: [] }, false);
    }

    setRowSelectionModel(newRows);
  };
  const handleProcessRowUpdateError = React.useCallback(error => {
    setSnackbar({ children: error.message, severity: 'error' });
  }, []);

  const handleRowModesModelChange = newRowModesModel => {
    setRowModesModel(newRowModesModel);
  };
  const handleCloseSnackbar = () => setSnackbar(null);

  const showArchviedLogActivity = () => {
    if (showArchived) {
      AnalyticsService.getArchivedLogActivity().then(responseData => {
        if (responseData.status === 200 && responseData.data.length > 0) {
          setRows(responseData.data);
          setShowArchived(false);
        } else {
          setSnackbar({ children: 'Not data found', severity: 'error' });
        }
      });
    } else {
      fetchEventLogData();
    }
  };

  const restoreLogActivity = id => {
    const data = {
      ids: id
    };
    AnalyticsService.restoreLogActivity(data).then(responseData => {
      if (responseData.status === 200) {
        setSnackbar({
          children: 'Successfully archived data',
          severity: 'success'
        });
        showArchviedLogActivity();
      }
    });
  };

  const doAnayaslis = () => {
    if (
      selectedRows &&
      selectedRows.sensors &&
      selectedRows.sensors.length > 0
    ) {
      setShowLogEntryAnalysis(true);

      setAnalysisData(selectedRows);
    } else {
      setSnackbar({
        children: 'Select atleast one sensor',
        severity: 'error'
      });
    }
  };

  function renderAutoCompleteCell(params) {
    return <RenderTags {...params} />;
  }

  const columns = [
    { field: 'id', headerName: 'Log Id' },
    {
      field: 'dateTime',
      headerName: 'Log Time',
      type: 'date',
      width: 200,
      renderCell: ({ value }) => <span>{formatDate(value)}</span>,
      valueFormatter: params => {
        return moment(params.value).format('MM/DD/YYYY h:mm a');
      }
    },
    {
      field: 'startDate',
      headerName: 'Start Time',
      type: 'dateTime',
      width: 180,
      renderCell: ({ value }) => <span>{formatDate(value)}</span>,
      valueFormatter: params => {
        return moment(params.value).format('MM/DD/YYYY h:mm a');
      },
      editable: showArchived
    },
    {
      field: 'endDate',
      headerName: 'End Time',
      type: 'dateTime',
      width: 180,

      renderCell: ({ value }) => <span>{formatDate(value)}</span>,
      valueFormatter: params => {
        return params.value
          ? moment(params.value).format('MM/DD/YYYY h:mm')
          : '';
      },
      editable: showArchived
    },
    {
      field: 'alertId',
      headerName: 'Alert',

      width: 100,
      renderCell: params => {
        return (
          <>
            {params &&
              params.row &&
              params.row.alerts &&
              params.row.alerts.map((value, index) => {
                return (
                  <Link
                    style={{ marginRight: '12px' }}
                    key={index}
                    to={`/rules/${value}`}
                  >
                    {value}
                  </Link>
                );
              })}
            {params &&
            params.row &&
            params.row.alerts &&
            params.row.alerts.length == 0 &&
            params.value &&
            params.value != '0' ? (
              <Link to={`/rules/${params.value}`}>{params.value}</Link>
            ) : (
              ''
            )}
          </>
        );
      },
      editable: false
    },

    {
      field: 'serialNumber',
      headerName: 'Device ID(s)',
      width: 180,
      editable: showArchived,

      type: 'singleSelect',
      renderCell: params => {
        return (
          <>
            {params.row.sensors &&
              params.row.sensors.map((value, index) => {
                return (
                  <span style={{ marginRight: '12px' }} key={index}>
                    {value}
                  </span>
                );
              })}
            {params.row.sensors &&
            params.row.sensors.length == 0 &&
            params.value ? (
              <span>{params.value}</span>
            ) : (
              ''
            )}
          </>
        );
      },
      valueOptions: sensors
    },

    {
      field: 'parameter',
      headerName: 'Parameter',
      width: 180
    },

    {
      field: 'avgValue',
      headerName: 'Average value',
      width: 150
    },

    {
      field: 'tags',
      headerName: 'Tags',
      width: 300,
      editable: showArchived,
      renderCell: params => {
        let rows = [];
        if (params.row.tags && Array.isArray(params.row.tags)) {
          rows = params.row.tags;
        } else if (params.row.tags) {
          rows = params.row.tags.split(',');
        }
        return (
          <>
            {rows.map((value, index) => {
              return <Chip size="small" label={value} key={index} />;
            })}
          </>
        );
      },
      renderEditCell: renderAutoCompleteCell
    },
    {
      field: 'entryLog',
      headerName: 'Notes',
      width: 400,
      editable: showArchived
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id, row }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        if (row.id && row.ids && row.ids.length === 0) {
          row['ids'] = [row.id];
        }
        if (isInEditMode && showArchived) {
          return [
            <GridActionsCellItem
              icon={<SaveOutlined />}
              label="Save"
              sx={{
                color: 'primary.main'
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelOutlined />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />
          ];
        }
        if (showArchived) {
          return [
            <GridActionsCellItem
              icon={<EditOutlined />}
              label="Edit"
              className="textPrimary"
              onClick={handleEditClick(id)}
              color="inherit"
            />,
            <GridActionsCellItem
              icon={<DeleteOutline />}
              label="Delete"
              onClick={handleDeleteClick(row.ids)}
              color="inherit"
            />
          ];
        } else {
          return [
            <GridActionsCellItem
              icon={<RestoreOutlined />}
              label="Restore"
              className="textPrimary"
              onClick={() => restoreLogActivity(row.ids)}
              color="inherit"
            />
          ];
        }
      }
    }
  ];

  return (
    <Box
      sx={{
        height: 500,
        width: '100%',
        '& .actions': {
          color: 'text.secondary'
        },
        '& .textPrimary': {
          color: 'text.primary'
        }
      }}
    >
      <DataGrid
        initialState={{
          sorting: {
            sortModel: [{ field: 'dateTime', sort: 'desc' }]
          }
        }}
        rows={rows}
        columns={columns}
        editMode={!showArchived ? 'row' : 'none'}
        disableColumnSelector
        disableDensitySelector
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={handleProcessRowUpdateError}
        checkboxSelection
        onSelectionModelChange={rowSelectionModel}
        onRowSelectionModelChange={newRowSelectionModel => {
          selectRows(newRowSelectionModel);
        }}
        columnVisibilityModel={{
          id: true
        }}
        slots={{
          toolbar: props => {
            return (
              <EditToolbar
                {...props}
                refresh={fetchEventLogData}
                doAnayaslis={doAnayaslis}
                showArchviedLogActivity={showArchviedLogActivity}
                showArchived={showArchived}
                rows={rows}
              />
            );
          },
          showQuickFilter: true
        }}
        slotProps={{
          toolbar: { setRows, setRowModesModel, GridToolbar }
        }}
      />
      {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          onClose={handleCloseSnackbar}
          autoHideDuration={6000}
        >
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}
      {showLogEntryAnalysis && <LogEntryAnalysis selectedRows={analysisData} />}
    </Box>
  );
}

const RenderTags = props => {
  const { id, value, api, field } = props;
  const [selected, setSelected] = React.useState([]);
  React.useEffect(() => {
    if (value && !Array.isArray(value)) {
      setSelected(value.split(','));
    }
  }, []);
  const handleChange = event => {
    api.setEditCellValue({ id, field, value: event }, event);
    setSelected(event);
  };

  return (
    <TagsInput
      style={{ width: '100%' }}
      value={selected}
      onChange={handleChange}
      name="tags"
      placeHolder="Enter tags"
    />
  );
};
