import { Box, Tab, Tabs, Toolbar, Typography } from '@material-ui/core';
import React, { useState } from 'react';

import TimeTrends from './AntTimeTrends';
import makeStyles from '@material-ui/core/styles/makeStyles';
import LogActivity from './LogActivity';

const useStyles = makeStyles(theme => ({
  grow: {
    flexGrow: 1
  },
  gradientHeader: {
    backgroundColor: '#144358',
    backgroundImage: 'linear-gradient(to right, #144358, #4E98B9)'
  },
  iconLabelWrapper: {
    flexDiection: 'row'
  },
  labelContainer: {
    width: 'auto',
    padding: 0
  },
  toolBarColor: {
    backgroundColor: '#bbd7e2 !important'
  },
  tabCss: {
    minWidth: '16.6%',
    flexDirection: 'row',
    fontWeight: 600,
    textTransform: 'initial',
    color: '#0b2836'
  }
}));

const Analytics = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const classes = useStyles();

  return (
    <div>
      {/*<HomePage />*/}

      <Toolbar className={classes.toolBarColor}>
        <Tabs
          // onChange={changeTab}
          variant="fullWidth"
          scrollButtons="auto"
          style={{ width: '100%', maxHeight: '47px' }}
          value={value}
          onChange={handleChange}
        >
          <Tab
            label="Time Trends"
            className={classes.tabCss}
            {...a11yProps(2)}
          />
          <Tab
            label="Activity Log"
            className={classes.tabCss}
            {...a11yProps(1)}
          />
        </Tabs>
      </Toolbar>

      <TabPanel value={value} index={0}>
        <TimeTrends />
      </TabPanel>

      <TabPanel value={value} index={1}>
        <LogActivity />
      </TabPanel>
    </div>
  );
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default Analytics;
