import { DEV_DOMAIN_URL } from '../environment';
import axiosInstance from '../_helpers/interceptor';

class DeviceService {
  saveOrUpdateData(data) {
    return axiosInstance.post(DEV_DOMAIN_URL + '/device/add-device', data);
  }
  getAllDevices() {
    return axiosInstance.get(DEV_DOMAIN_URL + '/device/get-clients');
  }
  getDistinctDevices() {
    return axiosInstance.get(DEV_DOMAIN_URL + '/device/get-distinct-devices');
  }
  getFaclities(data) {
    return axiosInstance.post(
      DEV_DOMAIN_URL + '/device/get-all-facility',
      data
    );
  }
  getZones(data) {
    return axiosInstance.post(DEV_DOMAIN_URL + '/device/get-all-zones', data);
  }
  getRooms(data) {
    return axiosInstance.post(DEV_DOMAIN_URL + '/device/get-all-rooms', data);
  }
  getClientData(name) {
    return axiosInstance.get(DEV_DOMAIN_URL + '/device/getDevice/' + name);
  }
  getFilteredClientData(data) {
    return axiosInstance.post(DEV_DOMAIN_URL + '/device/getFilteredData', data);
  }
  deleteDevice(id) {
    return axiosInstance.delete(
      DEV_DOMAIN_URL + '/device/deleteDeviceById/' + id
    );
  }
  getDetailedView(id, data) {
    return axiosInstance.post(
      DEV_DOMAIN_URL + '/device/getDetailedView/' + id,
      data
    );
  }
  getClients() {
    return axiosInstance.get(DEV_DOMAIN_URL + '/device/clients/');
  }
  saveClient(data) {
    return axiosInstance.post(DEV_DOMAIN_URL + '/device/clients/save', data);
  }
  deleteClient(id) {
    return axiosInstance.delete(DEV_DOMAIN_URL + '/device/clients/' + id);
  }
  validateUser(data) {
    return axiosInstance.post(
      DEV_DOMAIN_URL + '/device/client/validateRegistration',
      data
    );
  }
  registerUser(data) {
    return axiosInstance.post(
      DEV_DOMAIN_URL + '/device/client/register-user',
      data
    );
  }

  scaleSensor(data) {
    return axiosInstance.post(DEV_DOMAIN_URL + '/sensor/scaling/save', data);
  }
}

export default new DeviceService();
