import axiosInstance from '../_helpers/interceptor';
import { DEV_DOMAIN_URL } from '../environment';

class SettingsService {
  getUserSettings() {
    return axiosInstance.get(DEV_DOMAIN_URL + '/user/');
  }
  saveUserSettings(data) {
    return axiosInstance.post(DEV_DOMAIN_URL + '/user/save', data);
  }
  getAllAlerts() {
    return axiosInstance.get(DEV_DOMAIN_URL + '/alerts/');
  }
  getAllDevices() {
    return axiosInstance.get(DEV_DOMAIN_URL + '/device/get-distinct-devices');
  }
  saveAlerts(data) {
    if (data.id && data.id != 0) {
      return axiosInstance.put(`${DEV_DOMAIN_URL}/alerts/${data.id}`, data);
    } else {
      return axiosInstance.post(`${DEV_DOMAIN_URL}/alerts`, data);
    }
  }
  deleteAlerts(id) {
    return axiosInstance.delete(DEV_DOMAIN_URL + '/alerts/delete/' + id);
  }
  getScalingFactors() {
    return axiosInstance.get(DEV_DOMAIN_URL + '/sensor/scaling/get');
  }
  deleteScalingFactor(id) {
    return axiosInstance.delete(
      DEV_DOMAIN_URL + '/sensor/scaling/delete/' + id
    );
  }
  createOrUpdateRefSensors(data) {
    return axiosInstance.post(
      DEV_DOMAIN_URL + '/sensor/scaling/ref-sensor-scaling',
      data
    );
  }
  getExistingRefSaclingFactors() {
    return axiosInstance.get(
      DEV_DOMAIN_URL + '/sensor/scaling/ref-sensor-scalings'
    );
  }
  getAllClients() {
    return axiosInstance.get(DEV_DOMAIN_URL + '/device/get-all-clients');
  }

  getClients() {
    return axiosInstance.get(DEV_DOMAIN_URL + '/device/get-clients');
  }

  getAletHistory(data) {
    return axiosInstance.post(DEV_DOMAIN_URL + '/alerts/history', data);
  }
  getSingleAlert(data) {
    return axiosInstance.get(
      DEV_DOMAIN_URL +
        '/alerts/history/' +
        data.alertId +
        '/?time=' +
        data.time +
        '&alertName=' +
        data.name
    );
  }
  getSingleRule(id) {
    return axiosInstance.get(`${DEV_DOMAIN_URL}/alerts/${id}`);
  }
}
export default new SettingsService();
