import React from "react";
import { Button, Grid, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";

const DeviceHeader = (props) => {
    return (
        <div>
            <br />
            <Grid container spacing={3} style={{ padding: "0px 10px 0px 10px" }}>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                            <Typography ariant="h6" gutterBottom style={{ color: "#4E98B9", fontSize: "20px" }}>
                                Network Status
                            </Typography>
                        </Grid>
                        <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                            <Button color="primary" onClick={props.openCreateOrEditDeviceForm} variant="contained" style={{ float: "right" }}>
                                Add New
                            </Button>
                            {' '}
                            <Link to="/clients">
                             <Button color="primary"  variant="contained" style={{ float: "right",marginRight:'12px' }}>
                                Clients
                            </Button>
                            </Link>
                            
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

export default DeviceHeader;
