/* eslint-disable no-extend-native */
// @ts-nocheck

// import { G2, Heatmap } from "@antv/g2plot";

import { G2, Heatmap } from '@ant-design/charts';
import React, { useEffect, useState } from 'react';

import moment from 'moment';
import ColorScale from './ColorScale';
import _ from 'lodash';
import { sort } from '../utils/util';
import analyticsService from '../api/analyticsService';

G2.registerShape('polygon', 'boundary-polygon', {
  draw: function draw(cfg, container) {
    var group = container.addGroup();
    var attrs = {
      stroke: '#fff',
      lineWidth: 1,
      fill: cfg.color,
      paht: []
    };
    var points = cfg.points;
    var path = [
      ['M', points[0].x, points[0].y],
      ['L', points[1].x, points[1].y],
      ['L', points[2].x, points[2].y],
      ['L', points[3].x, points[3].y],
      ['Z']
    ];
    attrs.path = this.parsePath(path);
    group.addShape('path', { attrs: attrs });
    if (cfg.data.lastWeek) {
      var linePath = [
        ['M', points[2].x, points[2].y],
        ['L', points[3].x, points[3].y]
      ];
      group.addShape('path', {
        attrs: {
          path: this.parsePath(linePath),
          lineWidth: 4,
          stroke: '#fff'
        }
      });
      if (cfg.data.lastDay) {
        group.addShape('path', {
          attrs: {
            path: this.parsePath([
              ['M', points[1].x, points[1].y],
              ['L', points[2].x, points[2].y]
            ]),
            lineWidth: 4,
            stroke: '#fff'
          }
        });
      }
    }
    return group;
  }
});

Date.prototype.getWeek = function () {
  var date = new Date(this.getTime());
  date.setHours(0, 0, 0, 0);
  // Thursday in current week decides the year.
  date.setDate(date.getDate() + 3 - ((date.getDay() + 6) % 7));
  // January 4 is always in week 1.
  var week1 = new Date(date.getFullYear(), 0, 4);
  // Adjust to Thursday in week 1 and count number of weeks from date to week1.
  return (
    1 +
    Math.round(
      ((date.getTime() - week1.getTime()) / 86400000 -
        3 +
        ((week1.getDay() + 6) % 7)) /
        7
    )
  );
};

Date.prototype.getWeekOfMonth = function () {
  var firstWeekday = new Date(this.getFullYear(), this.getMonth(), 1).getDay();
  var offsetDate = this.getDate() + firstWeekday - 1;
  return Math.floor(offsetDate / 7);
};

function findlastweek(month, year, dd) {
  let lastDay = new Date(year, month, 0).getDate();
  var lastDaysCount = [];
  let counterStart = lastDay - 6;
  for (let m = counterStart; m < lastDay; m++) {
    lastDaysCount.push(m);
  }
  return lastDaysCount.includes(dd);
}

function findlastday(month, year, dd) {
  //debugger;
  let lastDay = new Date(year, month, 0).getDate();
  return lastDay === dd;
}

// const DemoHeatmap = () => {
const DemoHeatmap = props => {
  const [trendsData, setTrendsData] = useState({});
  const [loading, setLoading] = useState(false);
  const { data } = props;
  const [filterData, setFilterData] = useState([]);
  const onInputChange = (min, max) => {
    const tempData = _.filter(trendsData, function (o) {
      return o[data.parameter] >= min && o[data.parameter] <= max;
    });
    setFilterData(tempData);
  };
  useEffect(() => {
    // eslint-disable-next-line no-extend-native
    const finalData = data;
    finalData['type'] = 'dailyHeat';
    var res = [];
    var weekCounter = 0;
    var weekIncrementer = 0;

    analyticsService.getTrends(finalData).then(responseData => {
      var sortedDate = responseData.data.heatMapResponse;

      var sortres = sort(sortedDate);
      for (const [key, value] of Object.entries(sortres)) {
        var rObj = {};
        var getWeekNumber = new Date(key).getWeekOfMonth();

        rObj['date'] =
          ('0' + (new Date(key).getMonth() + 1)).slice(-2) +
          '/' +
          ('0' + new Date(key).getDate()).slice(-2);
        rObj[data.parameter] = parseInt(`${value}`);
        rObj['week'] = getWeekNumber + '.' + parseInt(new Date(key).getMonth());
        rObj['month'] = parseInt(new Date(key).getMonth());
        rObj['lastWeek'] = findlastweek(
          ('0' + (new Date(key).getMonth() + 1)).slice(-2),
          new Date(key).getFullYear(),
          new Date(key).getDate()
        );
        rObj['lastDay'] = findlastday(
          ('0' + (new Date(key).getMonth() + 1)).slice(-2),
          new Date(key).getFullYear(),
          new Date(key).getDate()
        );
        rObj['day'] = new Date(key).getDay();
        res.push(rObj);
        if (new Date(key).getDay() == 6) {
          weekIncrementer = weekIncrementer + 1;
          weekCounter = 0;
        } else {
          weekCounter = weekCounter + 1;
        }
      }
      setTrendsData(res);
      setFilterData(res);
    });
  }, [data]);

  var config = {
    data: filterData,
    height: 500,
    autoFit: false,
    xField: 'week',
    yField: 'day',

    colorField: data.parameter,
    reflect: 'y',
    shape: 'boundary-polygon',
    color: ['#060CB1', '#1792FF', '#5BFF99', '#FBF115', '#B61511'],
    meta: {
      day: {
        type: 'cat',
        values: [
          'Sunday',
          'Monday',
          'Tuesday',
          'Wednesday',
          'Thursday',
          'Friday',
          'Saturday'
        ]
      },
      commits: { sync: true },
      week: { type: 'cat' },
      value: { sync: true },
      date: { type: 'cat' }
    },
    yAxis: { grid: null },
    tooltip: {
      title: 'date',
      fields: [data.parameter],
      showMarkers: false
    },
    interactions: [{ type: 'element-active' }],
    xAxis: {
      position: 'top',
      tickLine: null,
      line: null,
      label: {
        offset: 12,
        style: {
          fontSize: 12,
          fill: '#666',
          textBaseline: 'top'
        },

        formatter: function formatter(getMonth) {
          if (getMonth.split('.')[1] == 11) {
            return 'DEC';
          } else if (getMonth.split('.')[1] == 10) {
            return 'NOV';
          } else if (getMonth.split('.')[1] == 9) {
            return 'OCT';
          } else if (getMonth.split('.')[1] == 8) {
            return 'SEP';
          } else if (getMonth.split('.')[1] == 7) {
            return 'AUG';
          } else if (getMonth.split('.')[1] == 6) {
            return 'JULY';
          } else if (getMonth.split('.')[1] == 5) {
            return 'JUNE';
          } else if (getMonth.split('.')[1] == 4) {
            return 'MAY';
          } else if (getMonth.split('.')[1] == 3) {
            return 'APR';
          } else if (getMonth.split('.')[1] == 2) {
            return 'MAR';
          } else if (getMonth.split('.')[1] == 1) {
            return 'FEB';
          } else if (getMonth.split('.')[1] == 0) {
            return 'JAN';
          }
        }
      }
    }
  };
  return (
    <>
      <ColorScale
        data={trendsData}
        filterData={filterData}
        parameter={data.parameter}
        onInputChange={onInputChange}
      />
      <Heatmap {...config} />
    </>
  );
};

export default DemoHeatmap;
