import React, {
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
  memo
} from 'react';
import _ from 'lodash';
import moment from 'moment';
import { areaChart } from './area-chart';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import { set } from 'date-fns';

function moviePropsAreEqual(prevMovie, nextMovie) {
  return prevMovie.selectedDateRange === nextMovie.selectedDateRange;
}

const DetaildSeriesChart = React.memo(
  ({ timeSeriesType, data, userSettings, selectedDateRange }) => {
    const chartRef = useRef(null);
    const [options, setOptions] = useState({});
    console.log('profile rendered' + chartRef);

    useEffect(() => {
      if (selectedDateRange !== 'realtime') {
        setOptions(
          getChartConfig(data, timeSeriesType, userSettings, selectedDateRange)
        );
      } else {
        const perviousOptions = _.cloneDeep(options);
        const tempData = data.detailed;
        if (perviousOptions.series) {
          for (var j = 0; j < tempData.length; j++) {
            for (var k = 0; k < perviousOptions.series.length; k++) {
              if (tempData[j].name === perviousOptions.series[k].name) {
                // highCharts.add
                perviousOptions.series[k].data.push(tempData[j].data[0]);
              }
            }
          }
          setOptions(tempData);
        } else {
          setOptions(
            getChartConfig(
              data,
              timeSeriesType,
              userSettings,
              selectedDateRange
            )
          );
        }
      }
    }, [data]);

    useEffect(() => {
      setOptions(
        getChartConfig(data, timeSeriesType, userSettings, selectedDateRange)
      );
    }, [timeSeriesType]);

    return <HighchartsReact highcharts={Highcharts} options={options} />;
  },
  (prevProps, nextProps) => {
    if (_.isEqual(prevProps.data.detailed, nextProps.data.detailed)) {
      return true; // props are equal
    }
    return false; // props are not equal -> update the component
  }
);

//const DetaildSeriesChart = React.memo(DetailedChart, moviePropsAreEqual);

function getChartConfig(
  res,
  timeSeriesType,
  userSettings,

  selectedDateRange
) {
  var temp = JSON.parse(localStorage.getItem('filters'));
  const startDate = temp.startDate;
  const endDate = temp.startDate;
  if (res.detailed && res.detailed.length > 0) {
    if (selectedDateRange === 'realtime') {
      let tempData = _.cloneDeep(areaChart);

      areaChart.series = _.cloneDeep(res.detailed);
    } else {
      areaChart.series = res.detailed;
    }

    if (timeSeriesType === 'pn03_m') {
      areaChart.yAxis.title.text = ' ' + userSettings.pnType;
      areaChart.tooltip.valueSuffix = ' ' + userSettings.pnType;
    }
    if (timeSeriesType === 'temp') {
      areaChart.yAxis.title.text = ' ' + ' ' + userSettings.temperature;
      areaChart.tooltip.valueSuffix = ' ' + userSettings.temperature;
    }
    if (timeSeriesType === 'hum') {
      areaChart.yAxis.title.text = ' ' + userSettings.relativeHumidity;
      areaChart.tooltip.valueSuffix = ' ' + userSettings.relativeHumidity;
    }
    if (timeSeriesType === 'rawethanol') {
      areaChart.yAxis.title.text = ' ' + userSettings.co2;
      areaChart.tooltip.valueSuffix = ' ' + userSettings.co2;
    }
    if (timeSeriesType === 'tvoc') {
      areaChart.yAxis.title.text = ' ' + userSettings.tvoc;
      areaChart.tooltip.valueSuffix = ' ' + userSettings.tvoc;
    }
    if (timeSeriesType === 'pressure') {
      areaChart.yAxis.title.text = '"" of ' + userSettings.pressure;
      areaChart.tooltip.valueSuffix = ' "" of ' + userSettings.pressure;
    }

    if (timeSeriesType === 'pn25_m') {
      areaChart.yAxis.title.text = ' ' + userSettings.pnType;
      areaChart.tooltip.valueSuffix = ' ' + userSettings.pnType;
    }
    if (timeSeriesType === 'pm10_m') {
      areaChart.yAxis.title.text = ' ' + userSettings.pnType;
      areaChart.tooltip.valueSuffix = ' ' + userSettings.pnType;
    }

    const dateFormt = calculateTimeRange(startDate, endDate);
    const timeZone = userSettings.timeZone;

    //areaChart.plotOptions.series.connectNulls = interval;

    areaChart.xAxis.labels = {
      formatter: function () {
        var monthStr = moment(this.value).tz(timeZone).format(dateFormt);
        return monthStr;
      }
    };

    areaChart.tooltip = {
      formatter: function () {
        return this.points.reduce(function (s, point) {
          return s + '<br/>' + point.series.name + ': ' + point.y.toFixed(2);
        }, '<b>' + moment(this.x).tz(timeZone).format(dateFormt) + '</b>');
      },
      shared: true
    };

    const savedTimeSeriesFilters = JSON.parse(localStorage.getItem('filters'))
      .detailed;
    for (let i = 0; i < areaChart.series.length; i++) {
      if (
        savedTimeSeriesFilters != undefined &&
        !savedTimeSeriesFilters.includes(areaChart.series[i].name)
      ) {
        areaChart.series[i]['visible'] = true;
      } else {
        areaChart.series[i]['visible'] = false;
      }
      areaChart.series[i]['allowDecimals'] = false;
    }
  } else {
    areaChart.series = [];
  }
  return _.cloneDeep(areaChart);
}

function calculateTimeRange(startDate, endDate) {
  var a = moment(startDate);
  var b = moment(endDate);

  var diff = b.diff(a, 'days');
  if (diff <= 1) {
    return 'HH:mm';
  } else if (diff > 1 && diff <= 30) {
    return 'MM/DD HH:mm';
  } else if (diff >= 30 && diff <= 365) {
    return 'MM/DD';
  } else if (diff >= 365) {
    return 'yyyy/MM/DD';
  }
}

export default DetaildSeriesChart;
