import {
  Button,
  makeStyles,
  Paper,
  TextField,
  Typography
} from '@material-ui/core';
import React, { useState } from 'react';
import LoginFormData from './loginFormData';
import { authenticationService } from './service/authenticatioin-service';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  backImg: {
    backgroundImage: "url('./img/dark_background.jpg')"
  }
}));

export default function Login() {
  const customClasses = useStyles();

  const loginData = { userName: '', password: '' };

  const formLoginData = new LoginFormData(loginData);

  const [formData, setFormData] = useState(formLoginData);

  const loginUser = data => {
    data.preventDefault();
    authenticationService.login(formData.userName, formData.password);
  };

  const handleFormChange = event => {
    const newFormData = Object.assign({}, formData);
    newFormData[event.target.name] = event.target.value;
    setFormData(newFormData);
  };

  return (
    <div id="login" className={customClasses.backImg}>
      <div style={{ paddingRight: '5%', marginBottom: '3%' }}>
        <div className="absolutePositionCss">
          <span>
            <img src="./img/telosair_white.png" alt="Telosair" />
          </span>
        </div>
        <div className="mt-mr">
          <span className="deeperCss">Deeper Insights |</span>
          <span className="healthierCss"> Healthier Air</span>
        </div>
      </div>
      <Paper elevation={3} class="cardHeight">
        <form onSubmit={loginUser} autoComplete="off">
          <Typography class="welcomeCss">
            <b>Welcome Back! </b>
          </Typography>
          <div className="userNameCss">
            <TextField
              variant="standard"
              label="Email"
              type="email"
              placeholder="Email"
              fullWidth
              required
              name="userName"
              onChange={handleFormChange}
            />
          </div>
          <div className="userNameCss">
            <TextField
              variant="standard"
              label="Password"
              type="password"
              fullWidth
              required
              name="password"
              onChange={handleFormChange}
            />
          </div>
          <br />
          <div className="userNameCss">
            <Button type="submit" variant="contained" color="primary" fullWidth>
              SIGN IN
            </Button>
          </div>
        </form>
        <p className="hereCss">
          By clicking here, you agree to our
          <a href="" className="customerCss">
            Customer Agreement
          </a>
        </p>
        <div>
          <Link to="/forgot-password">
            <a style={{ color: '#1c1c1c', fontWeight: 400 }}>
              Forgot Your Password ?
            </a>
          </Link>
          <Link to="/register">
            <a
              style={{ float: 'right', color: '#1c1c1c', fontWeight: 400 }}
              href="register"
            >
              Register
            </a>
          </Link>
          <br />
          <br />
        </div>
      </Paper>
    </div>
  );
}
