import { Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import dashboardService from "../../dashboard/dashboard-service";
import _ from "lodash";
const ReactHighcharts = require("react-highcharts");
require("highcharts-more")(ReactHighcharts.Highcharts);

let options = {
  chart: {
    zoomType: "xy",
  },
  title: {
    text: "DND Chart",
  },
  xAxis: {
    categories: [],
  },
  yAxis: {
    type: "logarithmic",
    minorTickInterval: 0.1,
  },
  series: [
    { name: "Real time", type: "column", data: [] },
    { name: "1 min interval", type: "spline", data: [] },
  ],
};
const RealTimeDND = () => {
  const [data, setData] = useState(options);
  const [listening, setListening] = useState(false);
  let eventSource = undefined;

  useEffect(() => {
    if (!listening) {
      eventSource = new EventSource(
        "/api/v1/dnd/real-time-data?userName=" +
          sessionStorage.getItem("user_name")
      );

      eventSource.onopen = (event) => {
        console.log("connection opened");
      };

      eventSource.onmessage = (event) => {
        console.log("result", event.data);
        const d1 = JSON.parse(event.data);
        setData((data) => {
          const tempData = _.cloneDeep(data);
          tempData.series[0].data = d1.data;
          tempData.xAxis.categories = d1.categories;
          return tempData;
        });
        //setData((data) => ([...data.series[0].data] = d1.data));
        //setData((data) => ([...data.xAxis.categories] = d1.categories));
      };

      eventSource.onerror = (event) => {
        console.log(event.target.readyState);
        if (event.target.readyState === EventSource.CLOSED) {
          console.log("eventsource closed (" + event.target.readyState + ")");
        }
        eventSource.close();
      };
      load1IntervalData(data);
      setListening(true);
    }
    const interval = setInterval(
      function (data) {
        const tempData = data;
        load1IntervalData(tempData);
      },
      60000,
      data
    );

    return () => {
      eventSource.close();
      console.log("eventsource closed");
      clearInterval(interval);
    };
  }, []);

  const load1IntervalData = (tempData) => {
    dashboardService.getOneMinInterval().then((responseData) => {
      if (responseData.status === 200) {
        const tempValue = _.cloneDeep(tempData);
        const data = responseData.data;
        //tempValue.xAxis.categories = data.categories;

        tempValue.series[1].data = data.data;
        options = tempValue;
        setData(tempValue);
      }
    });
  };

  console.log(JSON.stringify(data));

  return (
    <React.Fragment>
      <ReactHighcharts config={data} class="chartCss"></ReactHighcharts>
    </React.Fragment>
  );
};
export default RealTimeDND;
