import { Button, Chip, Container } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import SettingsService from '../../settings/SettingsService';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/Edit';
import { Grid } from '@mui/material';
import { subHours } from 'date-fns';
import { Link } from 'react-router-dom';
import { Typography } from 'antd';

const RenderAlerts = props => {
  let startDate = subHours(new Date(), 24).getTime();
  let endDate = new Date().getTime();

  if (localStorage.getItem('alertStartDate')) {
    startDate = localStorage.getItem('alertStartDate');
    endDate = localStorage.getItem('alertEndDate');
  }

  const [rows, setRows] = useState([]);

  const deleteRow = id => {
    SettingsService.deleteAlerts(id).then(responseData => {
      if (responseData.status === 200) {
        toast.success('Deleted Successfully', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
        fetchAllAlerts();
      }
    });
  };

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      width: 100,
      renderCell: param => {
        return <Link to={`/rules/${param.id}`}>{param.id}</Link>;
      }
    },
    {
      field: 'state',
      headerName: 'Status',
      width: 120,
      renderCell: param => {
        if (param.row.alertState === 'alert') {
          return (
            <Chip
              label="Alert"
              style={{ backgroundColor: 'rgb(211, 47, 47)', color: '#fff' }}
            />
          );
        } else {
          return (
            <Chip
              label="OK"
              style={{ backgroundColor: '#2e7d32', color: '#fff' }}
            />
          );
        }
      }
    },
    { field: 'clientName', headerName: 'Client', width: 250 },
    { field: 'deviceId', headerName: 'Sensors', width: 250 },
    { field: 'parameterName', headerName: 'Parameter', width: 250 },
    {
      field: 'priority',
      headerName: 'Priority',
      width: 150,

      renderCell: param => {
        if (param.row.priority === 'High') {
          return (
            <Chip
              variant="outlined"
              label={param.row.priority}
              style={{ color: 'orange' }}
            />
          );
        } else if (param.row.priority === 'Critical') {
          return (
            <Chip
              variant="outlined"
              label={param.row.priority}
              style={{ color: 'red' }}
            />
          );
        } else if (param.row.priority === 'Medium') {
          return (
            <Chip
              variant="outlined"
              label={param.row.priority}
              style={{ color: 'yellow' }}
            />
          );
        } else if (param.row.priority === 'Low') {
          return (
            <Chip
              variant="outlined"
              label={param.row.priority}
              style={{ color: 'blue' }}
            />
          );
        }
      }
    },

    { field: 'createdBy', headerName: 'Created By', width: 250 },
    {
      field: 'Action',
      headerName: 'Action',
      flex: 0.3,
      renderCell: params => (
        <React.Fragment>
          <EditIcon
            onClick={() => props.showEdit(params)}
            style={{ cursor: 'pointer' }}
            fontSize="small"
          />
          {'   '}
          <DeleteOutlineIcon
            onClick={() => deleteRow(params.row.id)}
            style={{ cursor: 'pointer', marginLeft: '12px' }}
            fontSize="small"
          />
        </React.Fragment>
      )
    }
  ];

  const fetchAllAlerts = () => {
    SettingsService.getAllAlerts().then(responseData => {
      setRows(responseData.data);
    });
  };

  useEffect(() => {
    fetchAllAlerts();
  }, []);

  return (
    <React.Fragment>
      <Grid container spacing={2} alignItems={'baseline'}>
        <Grid item xs={8}>
          <Typography.Title level={3} style={{ paddingLeft: '2.5%' }}>
            Alerts
          </Typography.Title>
        </Grid>
        <Grid item xs={4}>
          <Button
            style={{ float: 'right', marginTop: '12px', marginRight: '26px' }}
            variant="contained"
            color="primary"
            onClick={props.toggle}
          >
            Create ALert
          </Button>
        </Grid>
      </Grid>
      <Container
        maxWidth="xl"
        style={{ height: 600, width: '100%', marginTop: '12px' }}
      >
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={5}
          disableColumnFilter={false}
        />
      </Container>
    </React.Fragment>
  );
};

export default RenderAlerts;
