import * as React from 'react';

import Map, { Marker, Popup } from 'react-map-gl';

import 'mapbox-gl/dist/mapbox-gl.css';
import chroma from 'chroma-js';
import Pin from './pin';
import _ from 'lodash';

const MAPBOX_TOKEN =
  'pk.eyJ1IjoicHZpc2h3YTMiLCJhIjoiY2pqN2o3ZmhuMjA3djNxbGtpeGlzeG0wMCJ9.VJAx4hLRQcRYHlkrmmBIXQ';

const geojson = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      geometry: { type: 'Point', coordinates: [-122.4, 37.8] }
    }
  ]
};

const GeoMap = props => {
  const {
    data,
    parameter,
    minColorScale,
    maxColorScale,
    geoSensorSelector,
    mapType,
    timeRangeType
  } = props;

  const [mapData, setMapData] = React.useState([]);
  const [showPopup, setShowPopup] = React.useState(false);
  const [popInfo, setPopInfo] = React.useState({});
  //const [colorScale, setColorScale] = React.useState([]);
  var particale_color_scale;
  let tempData = [];
  if (mapData.length > 0) {
    tempData = mapData.map(value => {
      return value.value;
    });

    let minValue = Math.min(...tempData);
    let maxValue = Math.max(...tempData);
    if (minColorScale !== 0) {
      minValue = minColorScale;
    }
    if (maxColorScale !== 0) {
      maxValue = maxColorScale;
    }
    particale_color_scale = chroma
      .scale(['#343d99', '#f9f1b9', '#aa0a27'])
      .domain([minValue, maxValue], 7, 'log');
  }
  React.useEffect(() => {
    if (data.geoMap) {
      const tolerance = 10;
      if (mapType === 'real_time') {
        if (geoSensorSelector && geoSensorSelector.length > 0) {
          const tempData = data.geoMap.filter(value =>
            geoSensorSelector.includes(value.serial_number.toString())
          );
          const finalData = tempData;
          if (timeRangeType) {
            const existingData = mapData;

            for (var i = 0; i < existingData.length; i++) {
              for (var j = 0; j < finalData.length; j++) {
                if (
                  existingData[i].lat === finalData[j].lat &&
                  existingData[i].long === finalData[j].long &&
                  existingData[i].serialNumber === finalData[j].serialNumber &&
                  existingData[i].value === finalData[j].value
                ) {
                  finalData[j]['realTime'] = true;
                } else {
                  finalData[j]['realTime'] = false;
                }
              }
            }
          }

          setMapData(finalData);
        } else {
          const finalData = data.geoMap;
          if (timeRangeType) {
            const existingData = mapData;
            const finalData = data.geoMap;
            for (var i = 0; i < existingData.length; i++) {
              for (var j = 0; j < finalData.length; j++) {
                if (
                  existingData[i].lat === finalData[j].lat &&
                  existingData[i].long === finalData[j].long &&
                  existingData[i].serialNumber === finalData[j].serialNumber &&
                  existingData[i].value === finalData[j].value
                ) {
                  finalData[j]['realTime'] = true;
                } else {
                  finalData[j]['realTime'] = false;
                }
              }
            }
          }
          setMapData(finalData);
        }
      } else {
        var grouped = _.mapValues(
          _.groupBy(data.geoMap, 'serial_number'),
          clist => clist.map(car => _.omit(car, 'serial_number'))
        );
        if (geoSensorSelector && geoSensorSelector.length > 0) {
          const finalData = {};
          geoSensorSelector.forEach(value => {
            if (grouped[value]) {
              const tempData = grouped[value];

              finalData[value] = tempData;
            }
          });

          grouped = finalData;
        }

        console.log(grouped);
        const finalData = [];
        Object.keys(grouped).forEach(serialNumber => {
          const sensorData = grouped[serialNumber];

          const values = sensorData.map(pr => {
            return pr.value;
          });

          const latValues = sensorData.map(pr => {
            return pr.lat;
          });

          const longValues = sensorData.map(pr => {
            return pr.long;
          });

          const latSum = latValues.reduce((a, b) => a + b, 0);
          const latAvg = latSum / latValues.length || 0;

          const longSum = longValues.reduce((a, b) => a + b, 0);
          const longAum = longSum / longValues.length || 0;

          const sum = values.reduce((a, b) => a + b, 0);
          const avg = sum / values.length || 0;

          finalData.push({
            lat: latAvg,
            long: longAum,
            value: avg | 0,
            serial_number: serialNumber
          });
        });
        if (timeRangeType) {
          const existingData = mapData;

          for (var i = 0; i < existingData.length; i++) {
            for (var j = 0; j < finalData.length; j++) {
              if (
                existingData[i].lat === finalData[j].lat &&
                existingData[i].long === finalData[j].long &&
                existingData[i].serialNumber === finalData[j].serialNumber &&
                existingData[i].value === finalData[j].value
              ) {
                finalData[j]['realTime'] = true;
              } else {
                finalData[j]['realTime'] = false;
              }
            }
          }
        }

        console.log(finalData);
        setMapData(finalData);
      }

      // setColorScale(particale_color_scale);
    }
  }, [data, geoSensorSelector, mapType]);

  const showInfo = data => {
    setShowPopup(true);
    setPopInfo({
      longitude: data.long,
      latitude: data.lat,
      serial_number: data.serial_number,
      value: data.value
    });
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  return (
    <>
      {mapData.length > 0 && (
        <Map
          style={{ height: 600 }}
          mapStyle="https://basemaps.cartocdn.com/gl/positron-gl-style/style.json"
          mapboxAccessToken={MAPBOX_TOKEN}
          initialViewState={{
            longitude: mapData[0].long,
            latitude: mapData[0].lat,
            zoom: 17
          }}
        >
          {mapData.map((value, index) => {
            return (
              <Marker
                onClick={() => showInfo(value)}
                id={index}
                longitude={value.long}
                latitude={value.lat}
              >
                <Pin
                  colorScale={particale_color_scale}
                  parameter={parameter}
                  parameterValue={value.value}
                  timeRangeType={value.realTime}
                />
              </Marker>
            );
          })}
          {showPopup && popInfo.longitude && (
            <Popup
              longitude={popInfo.longitude}
              latitude={popInfo.latitude}
              anchor="bottom"
              onClose={closePopup}
              offsetTop={-30}
              closeButton={true}
              closeOnClick={false}
              style={{ width: '200px' }}
              //onClose={() => setShowPopup(false)}
            >
              <span>
                <b>Sensor : </b> {popInfo.serial_number}
              </span>
              <br />
              <span>
                <b>{parameter} : </b> {popInfo.value.toFixed(2)}
              </span>
            </Popup>
          )}
        </Map>
      )}
    </>
  );
};

const getDistance = (lat1, lon1, lat2, lon2) => {
  const radlat1 = (Math.PI * lat1) / 180;
  const radlat2 = (Math.PI * lat2) / 180;

  const theta = lon1 - lon2;
  const radtheta = (Math.PI * theta) / 180;

  let dist =
    Math.sin(radlat1) * Math.sin(radlat2) +
    Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
  dist = Math.acos(dist);
  dist = (dist * 180) / Math.PI;
  dist = dist * 60 * 1.1515;
  dist = dist * 1.609344;

  return dist;
};

const getGroupsByDistance = (coordinates, tolerance) => {
  const coordinatesToleranceMap = Array(coordinates.length).fill(1); // 1 = true

  return coordinates.reduce((groups, coordinate, index) => {
    // The tolerance map contains true/false (0/1) values, ignore anything with 0
    if (coordinatesToleranceMap[index] === 0) return groups;

    const { lat, long, serial_number } = coordinate;

    // This will return the current listing because it's the same lat/lng. We don't need to check it's length because it always has one
    const coordinatesWithinTolerance = coordinates.filter(
      (coordinate, index) => {
        // We're not interested in any listing that have been filtered out already
        if (coordinatesToleranceMap[index] === 0) {
          return false;
        }

        const isSameLatLng = lat === coordinate.lat && long === coordinate.long;

        const isSameSensor = serial_number === coordinate.serial_number;

        // Measure distance using Haversine formula
        const isWithinTolerance =
          isSameLatLng ||
          getDistance(lat, long, coordinate.lat, coordinate.long) <= tolerance;

        // Ignore the current listing on the next filter
        if (isWithinTolerance) coordinatesToleranceMap[index] = 0;

        return isWithinTolerance;
      }
    );

    groups.push({
      lat,
      long,
      serial_number,
      properties: coordinatesWithinTolerance
    });

    return groups;
  }, []);
};

export const clusterLayer = {
  id: 'point',
  type: 'circle',
  paint: {
    'circle-radius': 10,
    'circle-color': '#007cbf'
  }
};

export default GeoMap;
