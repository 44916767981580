import { Card, CardContent, Typography } from '@material-ui/core';
import { useEffect, useState } from 'react';
import ReactHighcharts from 'react-highcharts';
import AnalyticsService from '../../api/analyticsService';
import {
  getBoxPlotChartConfig,
  numberToText,
  textToNumber,
  weeklyMapObj
} from '../../utils/util';
import BarLoader from 'react-bar-loader';

export const WeeklyPlot = props => {
  const { data } = props;
  const [trendsData, setTrendsData] = useState({});
  const userSettings1 = JSON.parse(localStorage.getItem('UserData'));
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (data.sensors) {
      const finalData = data;
      finalData['type'] = 'weeklyBox';
      setLoading(true);
      AnalyticsService.getTrends(finalData).then(responseData => {
        const chartConfig = getBoxPlotChartConfig(
          data.parameter,
          userSettings1
        );
        const weekDayAry = responseData.data.xaxis;
        const weekDayBoxData = responseData.data.data;

        var result = {};
        weekDayAry.forEach((key, i) => (result[key] = weekDayBoxData[i]));

        let final = Object.entries(result).reduce((op, [key, value]) => {
          let newKey = textToNumber[key];
          op[newKey || key] = value;
          return op;
        }, {});

        const length = Object.keys(final).length;
        const finalResult = Array.from({ length }, (_, i) => ({
          key: length - i,
          [length - i]: final[length - i]
        }));
        finalResult.sort((a, b) => b.key - a.key);
        finalResult.sort((a, b) => a.key - b.key);

        let final2 = Object.entries(finalResult).reduce((op, [key, value]) => {
          console.log(value.key, key);
          let newKey2 = numberToText[value.key];
          if (value[value.key]) {
            op[newKey2] = value[value.key];
          } else {
            op[newKey2] = [];
          }

          return op;
        }, {});
        var resultKeysAry = Object.keys(result);
        resultKeysAry.map(i => weeklyMapObj[i]);
        chartConfig.series = [
          { showInLegend: false, data: responseData.data.data }
        ];

        //weekDayAry.forEach((key, i) => (result[key] = weekDayBoxData[i]));
        chartConfig.xAxis.categories = Object.keys(final2);
        chartConfig.series = [
          { showInLegend: false, data: responseData.data.data }
        ];
        chartConfig.chart = { type: 'boxplot', zoomType: 'xy' };
        setTrendsData(chartConfig);
        setLoading(false);
      });
    }
  }, [data]);

  return (
    <div id="dashboard-container-200">
      {loading && <BarLoader color="#1D8BF1" height="2" fullWidth />}
      <Card style={{ height: '500px' }}>
        <CardContent className={'setFixheight'}>
          <span className="titleCss1">
            <Typography>Weekday Box trends</Typography>
          </span>
          <br />
          <ReactHighcharts
            config={trendsData}
            allowChartUpdate={true}
            className="chartCss"
          ></ReactHighcharts>
        </CardContent>
      </Card>
    </div>
  );
};
