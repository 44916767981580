import React from 'react';
import RenderAlertForm from './components/Rules';
import RenderAlerts from './components/ManageRules';

export default class Rules extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      canView: true,
      formData: {}
    };
    this.showOrHideALerts = this.showOrHideALerts.bind(this);
    this.showEdit = this.showEdit.bind(this);
  }

  componentDidMount() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const action = urlParams.get('action');
    if (action && action === 'create') {
      this.setState({ canView: false, formData: {} });
    }
  }

  showOrHideALerts() {
    const searchParams = new URLSearchParams(this.props.location.search);

    this.props.history.replace({
      pathname: '/rules'
      //search: searchParams.toString()
    });
    this.setState({ canView: !this.state.canView, formData: {} });
  }
  showEdit(data) {
    this.setState({ canView: !this.state.canView, formData: data.row });
  }
  render() {
    return (
      <>
        {this.state.canView && (
          <RenderAlerts
            showEdit={this.showEdit}
            toggle={this.showOrHideALerts}
          />
        )}
        {!this.state.canView && (
          <RenderAlertForm
            toggle={this.showOrHideALerts}
            editData={this.state.formData}
          />
        )}
      </>
    );
  }
}
