import { DEV_DOMAIN_URL } from "../environment";
import axiosInstance from "../_helpers/interceptor";

class ReportService {
    getAll() {
        return axiosInstance.get(DEV_DOMAIN_URL + "/reports/get-all");
    }
    
    deleteFile(id){
        return axiosInstance.delete(DEV_DOMAIN_URL + "/reports/"+id+"/delete");
    }

    checkForSystemAdmin(){
        return axiosInstance.get(DEV_DOMAIN_URL + "/reports/check-for-system-admin");
    }
    getAllClients(){
        ///get-all-clients
        return axiosInstance.get(DEV_DOMAIN_URL + "/reports/get-all-clients");
    }
    

}

export default new ReportService();
