import axios from 'axios';

const axiosInstance = axios.create({});

axiosInstance.interceptors.request.use(headerConfig => {
  const token1 = localStorage.getItem('auth_token');
  const userName = sessionStorage.getItem('user_name');
  if (token1) {
    headerConfig.headers.Authorization = `Bearer ${token1}`;
  }
  headerConfig.headers['user_name'] = userName;
  return headerConfig;
});

axiosInstance.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem('currentUser');
      //window.location.reload();
    } else if (error.response && error.response.status === 403) {
      localStorage.removeItem('auth_token');
      window.location.href = '/login';
    } else if (error.response == undefined) {
      localStorage.removeItem('currentUser');
      //window.location.reload();
    } else {
      return Promise.reject(error);
    }
  }
);
export default axiosInstance;
