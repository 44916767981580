const ReactHighcharts = require("react-highcharts");
export const areaChart = {
  chart: {
    height: 300,
    zoomType: "xy",
  },
  title: {
    text: "",
  },
  subtitle: {
    text: "",
  },
  xAxis: {
    crosshair: true,
    // tickmarkPlacement: "on",
    type: "datetime",
    labels: {
      formatter: function () {
        var monthStr = ReactHighcharts.Highcharts.dateFormat(
          "%e. %b %H:%M",
          this.value
        );
        return monthStr;
      },
    },
  },
  yAxis: {
    floor: 0,
    allowDecimals: false,
    title: {
      enabled: false,
      text: "",
    },
    labels: {
      format: "{value} ",
      style: {
        color: ReactHighcharts.Highcharts.getOptions().colors[0],
      },
    },
  },

  tooltip: {
    valueDecimals: 0,
  },
  plotOptions: {
    series: {
      marker: {
        enabled: false,
        symbol: "circle",
      },
      connectNulls: false,
      events: {
        legendItemClick: function () {
          var temp = JSON.parse(localStorage.getItem("filters"));
          var temp1 = [];
          temp1 = temp.detailed == undefined ? [] : temp.detailed;
          if (this.visible == false) {
            temp1.splice(temp1.indexOf(this.name), 1);
          } else {
            temp1.push(this.name);
          }
          temp.detailed = temp1;
          localStorage.setItem("filters", JSON.stringify(temp));
          localStorage.setItem("timeTrendsFilters", JSON.stringify(temp));
        },
      },
    },
  },
  series: [],
};
