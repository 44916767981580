export const timeZones = [
    {"type":"Pacific/Niue","name": "(GMT-11:00) Niue"},
    {"type":"Pacific/Pago_Pago","name": "(GMT-11:00) Pago Pago"},
    {"type":"Pacific/Honolulu","name": "(GMT-10:00) Hawaii Time"},
    {"type":"Pacific/Rarotonga","name": "(GMT-10:00) Rarotonga"},
    {"type":"Pacific/Tahiti","name": "(GMT-10:00) Tahiti"},
    {"type":"Pacific/Marquesas","name": "(GMT-09:30) Marquesas"},
    {"type":"America/Anchorage","name": "(GMT-09:00) Alaska Time"},
    {"type":"Pacific/Gambier","name": "(GMT-09:00) Gambier"},
    {"type":"America/Los_Angeles","name": "(GMT-08:00) Pacific Time"},
    {"type":"America/Tijuana","name": "(GMT-08:00) Pacific Time - Tijuana"},
    {"type":"America/Vancouver","name": "(GMT-08:00) Pacific Time - Vancouver"},
    {"type":"America/Whitehorse","name": "(GMT-08:00) Pacific Time - Whitehorse"},
    {"type":"Pacific/Pitcairn","name": "(GMT-08:00) Pitcairn"},
    {"type":"America/Dawson_Creek","name": "(GMT-07:00) Mountain Time - Dawson Creek"},
    {"type":"America/Denver","name": "(GMT-07:00) Mountain Time"},
    {"type":"America/Edmonton","name": "(GMT-07:00) Mountain Time - Edmonton"},
    {"type":"America/Hermosillo","name": "(GMT-07:00) Mountain Time - Hermosillo"},
    {"type":"America/Mazatlan","name": "(GMT-07:00) Mountain Time - Chihuahua, Mazatlan"},
    {"type":"America/Phoenix","name": "(GMT-07:00) Mountain Time - Arizona"},
    {"type":"America/Yellowknife","name": "(GMT-07:00) Mountain Time - Yellowknife"},
    {"type":"America/Belize","name": "(GMT-06:00) Belize"},
    {"type":"America/Chicago","name": "(GMT-06:00) Central Time"},
    {"type":"America/Costa_Rica","name": "(GMT-06:00) Costa Rica"},
    {"type":"America/El_Salvador","name": "(GMT-06:00) El Salvador"},
    {"type":"America/Guatemala","name": "(GMT-06:00) Guatemala"},
    {"type":"America/Managua","name": "(GMT-06:00) Managua"},
    {"type":"America/Mexico_City","name": "(GMT-06:00) Central Time - Mexico City"},
    {"type":"America/Regina","name": "(GMT-06:00) Central Time - Regina"},
    {"type":"America/Tegucigalpa","name": "(GMT-06:00) Central Time - Tegucigalpa"},
    {"type":"America/Winnipeg","name": "(GMT-06:00) Central Time - Winnipeg"},
    {"type":"Pacific/Galapagos","name": "(GMT-06:00) Galapagos"},
    {"type":"America/Bogota","name": "(GMT-05:00) Bogota"},
    {"type":"America/Cancun","name": "(GMT-05:00) America Cancun"},
    {"type":"America/Cayman","name": "(GMT-05:00) Cayman"},
    {"type":"America/Guayaquil","name": "(GMT-05:00) Guayaquil"},
    {"type":"America/Havana","name": "(GMT-05:00) Havana"},
    {"type":"America/Iqaluit","name": "(GMT-05:00) Eastern Time - Iqaluit"},
    {"type":"America/Jamaica","name": "(GMT-05:00) Jamaica"},
    {"type":"America/Lima","name": "(GMT-05:00) Lima"},
    {"type":"America/Nassau","name": "(GMT-05:00) Nassau"},
    {"type":"America/New_York","name": "(GMT-05:00) Eastern Time"},
    {"type":"America/Panama","name": "(GMT-05:00) Panama"},
    {"type":"America/Port-au-Prince", "name": "(GMT-05:00) Port-au-Prince"},
    {"type":"America/Rio_Branco","name": "(GMT-05:00) Rio Branco"},
    {"type":"America/Toronto","name": "(GMT-05:00) Eastern Time - Toronto"},
    {"type":"Pacific/Easter","name": "(GMT-05:00) Easter Island"},
    {"type":"America/Caracas","name": "(GMT-04:30) Caracas"},
    {"type":"America/Asuncion","name": "(GMT-03:00) Asuncion"},
    {"type":"America/Barbados","name": "(GMT-04:00) Barbados"},
    {"type":"America/Boa_Vista","name": "(GMT-04:00) Boa Vista"},
    {"type":"America/Campo_Grande","name": "(GMT-03:00) Campo Grande"},
    {"type":"America/Cuiaba","name": "(GMT-03:00) Cuiaba"},
    {"type":"America/Curacao","name": "(GMT-04:00) Curacao"},
    {"type":"America/Grand_Turk","name": "(GMT-04:00) Grand Turk"},
    {"type":"America/Guyana","name": "(GMT-04:00) Guyana"},
    {"type":"America/Halifax","name": "(GMT-04:00) Atlantic Time - Halifax"},
    {"type":"America/La_Paz","name": "(GMT-04:00) La Paz"},
    {"type":"America/Manaus","name": "(GMT-04:00) Manaus"},
    {"type":"America/Martinique","name": "(GMT-04:00) Martinique"},
    {"type":"America/Port_of_Spain","name": "(GMT-04:00) Port of Spain"},
    {"type":"America/Porto_Velho","name": "(GMT-04:00) Porto Velho"},
    {"type":"America/Puerto_Rico","name": "(GMT-04:00) Puerto Rico"},
    {"type":"America/Santo_Domingo","name": "(GMT-04:00) Santo Domingo"},
    {"type":"America/Thule","name": "(GMT-04:00) Thule"},
    {"type":"Atlantic/Bermuda","name": "(GMT-04:00) Bermuda"},
    {"type":"America/St_Johns","name": "(GMT-03:30) Newfoundland Time - St. Johns"},
    {"type":"America/Araguaina","name": "(GMT-03:00) Araguaina"},
    {"type":"America/Argentina/Buenos_Aire","name":"(GMT-03:00) Buenos Aires"},
    {"type":"America/Bahia","name": "(GMT-03:00) Salvador"},
    {"type":"America/Belem","name": "(GMT-03:00) Belem"},
    {"type":"America/Cayenne","name": "(GMT-03:00) Cayenne"},
    {"type":"America/Fortaleza","name": "(GMT-03:00) Fortaleza"},
    {"type":"America/Godthab","name": "(GMT-03:00) Godthab"},
    {"type":"America/Maceio","name": "(GMT-03:00) Maceio"},
    {"type":"America/Miquelon","name": "(GMT-03:00) Miquelon"},
    {"type":"America/Montevideo","name": "(GMT-03:00) Montevideo"},
    {"type":"America/Paramaribo","name": "(GMT-03:00) Paramaribo"},
    {"type":"America/Recife","name": "(GMT-03:00) Recife"},
    {"type":"America/Santiago","name": "(GMT-03:00) Santiago"},
    {"type":"America/Sao_Paulo","name": "(GMT-02:00) Sao Paulo"},
    {"type":"Antarctica/Palmer","name": "(GMT-03:00) Palmer"},
    {"type":"Antarctica/Rothera","name": "(GMT-03:00) Rothera"},
    {"type":"Atlantic/Stanley","name": "(GMT-03:00) Stanley"},
    {"type":"America/Noronha","name": "(GMT-02:00) Noronha"},
    {"type":"Atlantic/South_Georgia","name": "(GMT-02:00) South Georgia"},
    {"type":"America/Scoresbysund","name": "(GMT-01:00) Scoresbysund"},
    {"type":"Atlantic/Azores","name": "(GMT-01:00) Azores"},
    {"type":"Atlantic/Cape_Verde","name": "(GMT-01:00) Cape Verde"},
    {"type":"Africa/Abidjan","name": "(GMT+00:00) Abidjan"},
    {"type":"Africa/Accra","name": "(GMT+00:00) Accra"},
    {"type":"Africa/Bissau","name": "(GMT+00:00) Bissau"},
    {"type":"Africa/Casablanca","name": "(GMT+00:00) Casablanca"},
    {"type":"Africa/El_Aaiun","name": "(GMT+00:00) El Aaiun"},
    {"type":"Africa/Monrovia","name": "(GMT+00:00) Monrovia"},
    {"type":"America/Danmarkshavn","name": "(GMT+00:00) Danmarkshavn"},
    {"type":"Atlantic/Canary","name": "(GMT+00:00) Canary Islands"},
    {"type":"Atlantic/Faroe","name": "(GMT+00:00) Faeroe"},
    {"type":"Atlantic/Reykjavik","name": "(GMT+00:00) Reykjavik"},
    {"type":"Etc/GMT","name": "(GMT+00:00) GMT (no daylight saving)"},
    {"type":"Europe/Dublin","name": "(GMT+00:00) Dublin"},
    {"type":"Europe/Lisbon","name": "(GMT+00:00) Lisbon"},
    {"type":"Europe/London","name": "(GMT+00:00) London"},
    {"type":"Africa/Algiers","name": "(GMT+01:00) Algiers"},
    {"type":"Africa/Ceuta","name": "(GMT+01:00) Ceuta"},
    {"type":"Africa/Lagos","name": "(GMT+01:00) Lagos"},
    {"type":"Africa/Ndjamena","name": "(GMT+01:00) Ndjamena"},
    {"type":"Africa/Tunis","name": "(GMT+01:00) Tunis"},
    {"type":"Africa/Windhoek","name": "(GMT+02:00) Windhoek"},
    {"type":"Europe/Amsterdam","name": "(GMT+01:00) Amsterdam"},
    {"type":"Europe/Andorra","name": "(GMT+01:00) Andorra"},
    {"type":"Europe/Belgrade","name": "(GMT+01:00) Central European Time - Belgrade"},
    {"type":"Europe/Berlin","name": "(GMT+01:00) Berlin"},
    {"type":"Europe/Brussels","name": "(GMT+01:00) Brussels"},
    {"type":"Europe/Budapest","name": "(GMT+01:00) Budapest"},
    {"type":"Europe/Copenhagen","name": "(GMT+01:00) Copenhagen"},
    {"type":"Europe/Gibraltar","name": "(GMT+01:00) Gibraltar"},
    {"type":"Europe/Luxembourg","name": "(GMT+01:00) Luxembourg"},
    {"type":"Europe/Madrid","name": "(GMT+01:00) Madrid"},
    {"type":"Europe/Malta","name": "(GMT+01:00) Malta"},
    {"type":"Europe/Monaco","name": "(GMT+01:00) Monaco"},
    {"type":"Europe/Oslo","name": "(GMT+01:00) Oslo"},
    {"type":"Europe/Paris","name": "(GMT+01:00) Paris"},
    {"type":"Europe/Prague","name": "(GMT+01:00) Central European Time - Prague"},
    {"type":"Europe/Rome","name": "(GMT+01:00) Rome"},
    {"type":"Europe/Stockholm","name": "(GMT+01:00) Stockholm"},
    {"type":"Europe/Tirane","name": "(GMT+01:00) Tirane"},
    {"type":"Europe/Vienna","name": "(GMT+01:00) Vienna"},
    {"type":"Europe/Warsaw","name": "(GMT+01:00) Warsaw"},
    {"type":"Europe/Zurich","name": "(GMT+01:00) Zurich"},
    {"type":"Africa/Cairo","name": "(GMT+02:00) Cairo"},
    {"type":"Africa/Johannesburg","name": "(GMT+02:00) Johannesburg"},
    {"type":"Africa/Maputo","name": "(GMT+02:00) Maputo"},
    {"type":"Africa/Tripoli","name": "(GMT+02:00) Tripoli"},
    {"type":"Asia/Amman","name": "(GMT+02:00) Amman"},
    {"type":"Asia/Beirut","name": "(GMT+02:00) Beirut"},
    {"type":"Asia/Damascus","name": "(GMT+02:00) Damascus"},
    {"type":"Asia/Gaza","name": "(GMT+02:00) Gaza"},
    {"type":"Asia/Jerusalem","name": "(GMT+02:00) Jerusalem"},
    {"type":"Asia/Nicosia","name": "(GMT+02:00) Nicosia"},
    {"type":"Europe/Athens","name": "(GMT+02:00) Athens"},
    {"type":"Europe/Bucharest","name": "(GMT+02:00) Bucharest"},
    {"type":"Europe/Chisinau","name": "(GMT+02:00) Chisinau"},
    {"type":"Europe/Helsinki","name": "(GMT+02:00) Helsinki"},
    {"type":"Europe/Istanbul","name": "(GMT+02:00) Istanbul"},
    {"type":"Europe/Kaliningrad","name": "(GMT+02:00) Moscow-01 - Kaliningrad"},
    {"type":"Europe/Kiev","name": "(GMT+02:00) Kiev"},
    {"type":"Europe/Riga","name": "(GMT+02:00) Riga"},
    {"type":"Europe/Sofia","name": "(GMT+02:00) Sofia"},
    {"type":"Europe/Tallinn","name": "(GMT+02:00) Tallinn"},
    {"type":"Europe/Vilnius","name": "(GMT+02:00) Vilnius"},
    {"type":"Africa/Khartoum","name": "(GMT+03:00) Khartoum"},
    {"type":"Africa/Nairobi","name": "(GMT+03:00) Nairobi"},
    {"type":"Antarctica/Syowa","name": "(GMT+03:00) Syowa"},
    {"type":"Asia/Baghdad","name": "(GMT+03:00) Baghdad"},
    {"type":"Asia/Qatar","name": "(GMT+03:00) Qatar"},
    {"type":"Asia/Riyadh","name": "(GMT+03:00) Riyadh"},
    {"type":"Europe/Minsk","name": "(GMT+03:00) Minsk"},
    {"type":"Europe/Moscow","name": "(GMT+03:00) Moscow+00 - Moscow"},
    {"type":"Asia/Tehran","name": "(GMT+03:30) Tehran"},
    {"type":"Asia/Baku","name": "(GMT+04:00) Baku"},
    {"type":"Asia/Dubai","name": "(GMT+04:00) Dubai"},
    {"type":"Asia/Tbilisi","name": "(GMT+04:00) Tbilisi"},
    {"type":"Asia/Yerevan","name": "(GMT+04:00) Yerevan"},
    {"type":"Europe/Samara","name": "(GMT+04:00) Moscow+01 - Samara"},
    {"type":"Indian/Mahe","name": "(GMT+04:00) Mahe"},
    {"type":"Indian/Mauritius","name": "(GMT+04:00) Mauritius"},
    {"type":"Indian/Reunion","name": "(GMT+04:00) Reunion"},
    {"type":"Asia/Kabul","name": "(GMT+04:30) Kabul"},
    {"type":"Antarctica/Mawson","name": "(GMT+05:00) Mawson"},
    {"type":"Asia/Aqtau","name": "(GMT+05:00) Aqtau"},
    {"type":"Asia/Aqtobe","name": "(GMT+05:00) Aqtobe"},
    {"type":"Asia/Ashgabat","name": "(GMT+05:00) Ashgabat"},
    {"type":"Asia/Dushanbe","name": "(GMT+05:00) Dushanbe"},
    {"type":"Asia/Karachi","name": "(GMT+05:00) Karachi"},
    {"type":"Asia/Tashkent","name": "(GMT+05:00) Tashkent"},
    {"type":"Asia/Yekaterinburg","name": "(GMT+05:00) Moscow+02 - Yekaterinburg"},
    {"type":"Indian/Kerguelen","name": "(GMT+05:00) Kerguelen"},
    {"type":"Indian/Maldives","name": "(GMT+05:00) Maldives"},
    {"type":"Asia/Calcutta","name": "(GMT+05:30) India Standard Time"},
    {"type":"Asia/Colombo","name": "(GMT+05:30) Colombo"},
    {"type":"Asia/Katmandu","name": "(GMT+05:45) Katmandu"},
    {"type":"Antarctica/Vostok","name": "(GMT+06:00) Vostok"},
    {"type":"Asia/Almaty","name": "(GMT+06:00) Almaty"},
    {"type":"Asia/Bishkek","name": "(GMT+06:00) Bishkek"},
    {"type":"Asia/Dhaka","name": "(GMT+06:00) Dhaka"},
    {"type":"Asia/Omsk","name": "(GMT+06:00) Moscow+03 - Omsk, Novosibirsk"},
    {"type":"Asia/Thimphu","name": "(GMT+06:00) Thimphu"},
    {"type":"Indian/Chagos","name": "(GMT+06:00) Chagos"},
    {"type":"Asia/Rangoon","name": "(GMT+06:30) Rangoon"},
    {"type":"Indian/Cocos","name": "(GMT+06:30) Cocos"},
    {"type":"Antarctica/Davis","name": "(GMT+07:00) Davis"},
    {"type":"Asia/Bangkok","name": "(GMT+07:00) Bangkok"},
    {"type":"Asia/Hovd","name": "(GMT+07:00) Hovd"},
    {"type":"Asia/Jakarta","name": "(GMT+07:00) Jakarta"},
    {"type":"Asia/Krasnoyarsk","name": "(GMT+07:00) Moscow+04 - Krasnoyarsk"},
    {"type":"Asia/Saigon","name": "(GMT+07:00) Hanoi"},
    {"type":"Asia/Ho_Chi_Minh","name": "(GMT+07:00) Ho Chi Minh"},
    {"type":"Indian/Christmas","name": "(GMT+07:00) Christmas"},
    {"type":"Antarctica/Casey","name": "(GMT+08:00) Casey"},
    {"type":"Asia/Brunei","name": "(GMT+08:00) Brunei"},
    {"type":"Asia/Choibalsan","name": "(GMT+08:00) Choibalsan"},
    {"type":"Asia/Hong_Kong","name": "(GMT+08:00) Hong Kong"},
    {"type":"Asia/Irkutsk","name": "(GMT+08:00) Moscow+05 - Irkutsk"},
    {"type":"Asia/Kuala_Lumpur","name": "(GMT+08:00) Kuala Lumpur"},
    {"type":"Asia/Macau","name": "(GMT+08:00) Macau"},
    {"type":"Asia/Makassar","name": "(GMT+08:00) Makassar"},
    {"type":"Asia/Manila","name": "(GMT+08:00) Manila"},
    {"type":"Asia/Shanghai","name": "(GMT+08:00) China Time - Beijing"},
    {"type":"Asia/Singapore","name": "(GMT+08:00) Singapore"},
    {"type":"Asia/Taipei","name": "(GMT+08:00) Taipei"},
    {"type":"Asia/Ulaanbaatar","name": "(GMT+08:00) Ulaanbaatar"},
    {"type":"Australia/Perth","name": "(GMT+08:00) Western Time - Perth"},
    {"type":"Asia/Pyongyang","name": "(GMT+08:30) Pyongyang"},
    {"type":"Asia/Dili","name": "(GMT+09:00) Dili"},
    {"type":"Asia/Jayapura","name": "(GMT+09:00) Jayapura"},
    {"type":"Asia/Seoul","name": "(GMT+09:00) Seoul"},
    {"type":"Asia/Tokyo","name": "(GMT+09:00) Tokyo"},
    {"type":"Asia/Yakutsk","name": "(GMT+09:00) Moscow+06 - Yakutsk"},
    {"type":"Pacific/Palau","name": "(GMT+09:00) Palau"},
    {"type":"Australia/Adelaide","name": "(GMT+10:30) Central Time - Adelaide"},
    {"type":"Australia/Darwin","name": "(GMT+09:30) Central Time - Darwin"},
    {"type":"Antarctica/DumontDUrville","name": "(GMT+10:00) Dumont D'Urville"},
    {"type":"Asia/Magadan","name": "(GMT+10:00) Moscow+07 - Magadan"},
    {"type":"Asia/Vladivostok","name": "(GMT+10:00) Moscow+07 - Yuzhno-Sakhalinsk"},
    {"type":"Australia/Brisbane","name": "(GMT+10:00) Eastern Time - Brisbane"},
    {"type":"Australia/Hobart","name": "(GMT+11:00) Eastern Time - Hobart"},
    {"type":"Australia/Sydney","name": "(GMT+11:00) Eastern Time - Melbourne, Sydney"},
    {"type":"Pacific/Chuuk","name": "(GMT+10:00) Truk"},
    {"type":"Pacific/Guam","name": "(GMT+10:00) Guam"},
    {"type":"Pacific/Port_Moresby","name": "(GMT+10:00) Port Moresby"},
    {"type":"Pacific/Efate","name": "(GMT+11:00) Efate"},
    {"type":"Pacific/Guadalcanal","name": "(GMT+11:00) Guadalcanal"},
    {"type":"Pacific/Kosrae","name": "(GMT+11:00) Kosrae"},
    {"type":"Pacific/Norfolk","name": "(GMT+11:00) Norfolk"},
    {"type":"Pacific/Noumea","name": "(GMT+11:00) Noumea"},
    {"type":"Pacific/Pohnpei","name": "(GMT+11:00) Ponape"},
    {"type":"Asia/Kamchatka","name": "(GMT+12:00) Moscow+09 - Petropavlovsk-Kamchatskiy"},
    {"type":"Pacific/Auckland","name": "(GMT+13:00) Auckland"},
    {"type":"Pacific/Fiji","name": "(GMT+13:00) Fiji"},
    {"type":"Pacific/Funafuti","name": "(GMT+12:00) Funafuti"},
    {"type":"Pacific/Kwajalein","name": "(GMT+12:00) Kwajalein"},
    {"type":"Pacific/Majuro","name": "(GMT+12:00) Majuro"},
    {"type":"Pacific/Nauru","name": "(GMT+12:00) Nauru"},
    {"type":"Pacific/Tarawa","name": "(GMT+12:00) Tarawa"},
    {"type":"Pacific/Wake","name": "(GMT+12:00) Wake"},
    {"type":"Pacific/Wallis","name": "(GMT+12:00) Wallis"},
    {"type":"Pacific/Apia","name": "(GMT+14:00) Apia"},
    {"type":"Pacific/Enderbury","name": "(GMT+13:00) Enderbury"},
    {"type":"Pacific/Fakaofo","name": "(GMT+13:00) Fakaofo"},
    {"type":"Pacific/Tongatapu","name": "(GMT+13:00) Tongatapu"},
    {"type":"Pacific/Kiritimati","name": "(GMT+14:00) Kiritimati"}
]