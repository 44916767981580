import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  makeStyles,
  TextField
} from '@material-ui/core';
import { useState } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { toast } from 'react-toastify';
import deviceService from '../../api/deviceService';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  container: {
    maxHeight: 440
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}));

const ReferenceSensorForm = props => {
  const classes = useStyles();
  const closeModel = () => {
    //props.refresh();
    props.handleClose();
  };
  const [formData, setFormData] = useState({
    startDate: null,
    endDate: null
  });

  const onFormChange = event => {
    const { name, value } = event.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const submitForm = () => {
    if (!props.data || !props.data.linearFitData) {
      toast.error(
        'No Slope and Intercept data to store, please select correct parameters'
      );
      return false;
    }

    if (!formData.startDate) {
      toast.error('Please select start date');
      return false;
    }

    const data = props.data.linearFitData.slopeAndIntercept;
    const finalData = {
      calibaratedDate: `${props.calibartionFormData.startDate} to ${props.calibartionFormData.endDate}`,
      startDate: formData.startDate,
      endDate: formData.endDate,
      refSensors: data,

      parameter: props.calibartionFormData.parameter
    };
    deviceService
      .scaleSensor(finalData)
      .then(responseData => {
        if (responseData.status === 200) {
          toast.success('Successfully saved');
        }
      })
      .catch(e => {
        toast.error('unable to save the data');
      });
    console.log(finalData);
  };

  return (
    <Dialog
      open={props.open}
      onClose={closeModel}
      aria-labelledby="form-dialog-title"
      maxWidth="md"
      fullWidth={true}
    >
      <DialogTitle id="form-dialog-title">Save Calibiration</DialogTitle>
      <ValidatorForm onSubmit={submitForm} className={classes.form}>
        <DialogContent dividers>
          <FormControl style={{ width: '80%', marginBottom: '20px' }}>
            <TextField
              fullWidth
              id={'datetime-local'}
              label={'Start Time'}
              type="datetime-local"
              name="startDate"
              onChange={onFormChange}
              value={formData.startDate}
              InputLabelProps={{
                shrink: true
              }}
            />
          </FormControl>
          <FormControl style={{ width: '80%', marginBottom: '20px' }}>
            <TextField
              fullWidth
              id={'datetime-local'}
              label={'End Time'}
              type="datetime-local"
              name="endDate"
              onChange={onFormChange}
              value={formData.endDate}
              InputLabelProps={{
                shrink: true
              }}
            />
          </FormControl>
        </DialogContent>

        <DialogActions>
          <Button type="submit" color="primary">
            Submit
          </Button>
          <Button onClick={closeModel} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </ValidatorForm>
    </Dialog>
  );
};

export default ReferenceSensorForm;
