import {
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from "@material-ui/core";
import React, { forwardRef, useEffect, useState } from "react";
import * as moment from "moment-timezone";

import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import CreateIcon from "@material-ui/icons/Create";
import { DataGrid } from "@material-ui/data-grid";
import DeleteIcon from "@material-ui/icons/Delete";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import ErrorIcon from "@material-ui/icons/Error";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import MaterialTable from "material-table";
import { NavLink } from "react-router-dom";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import ViewColumn from "@material-ui/icons/ViewColumn";
import deviceService from "../api/deviceService";

import { Sparklines, SparklinesBars, SparklinesLine } from "react-sparklines";
import TimeSeriesChart from "../dashboard/charts/TimeSeriesChart";
import Alerts from "../alerts";
var _ = require("underscore");
const ReactHighcharts = require("react-highcharts");
require("highcharts-more")(ReactHighcharts.Highcharts);

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));
const ReportsNew = (props) => {
  const classes = useStyles();
  const [reportType, setReportType] = useState("");
  const [status, setStatus] = useState("");

  const onChangeHandler = (event) => {
    // alert(event.target.value);
    if (event.target.name === "reportType") {
      setReportType(event.target.value);
    }
    if (event.target.name === "status") {
      setStatus(event.target.value);
    }
  };

  useEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const reportType = params.get("reportType");
    if (reportType) {
      setReportType(reportType);
      if (reportType === "UnHealthy_devices") {
        const status = params.get("status");
        if (status) {
          setStatus(status);
        }
      }
    }
  }, []);

  return (
    <>
      <Grid container spacing={1} style={{ padding: "22px 18px 0px 24px" }}>
        <Grid item xs={4} md={4} lg={4}>
          <FormControl className={classes.formControl}>
            <InputLabel shrink id="demo-simple-select-placeholder-label-label">
              Reports Type
            </InputLabel>
          </FormControl>
          <Select
            value={reportType}
            onChange={onChangeHandler}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            name="reportType"
          >
            <MenuItem value="UnHealthy_devices">UnHealthy Devices</MenuItem>
            <MenuItem value="alerts">Alerts</MenuItem>
          </Select>
        </Grid>
        {reportType && reportType === "UnHealthy_devices" && (
          <Grid item xs={4} md={4} lg={4}>
            <FormControl className={classes.formControl}>
              <InputLabel
                shrink
                id="demo-simple-select-placeholder-label-label"
              >
                Status
              </InputLabel>
            </FormControl>
            <Select
              name="status"
              value={status}
              onChange={onChangeHandler}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
            >
              <MenuItem value="All">ALL</MenuItem>
              <MenuItem value="Healthy">Healthy</MenuItem>
              <MenuItem value="UnHealthy">UnHealthy</MenuItem>
            </Select>
          </Grid>
        )}
      </Grid>
      <Grid container spacing={1} style={{ padding: "22px 18px 0px 24px" }}>
        {reportType && reportType === "UnHealthy_devices" && (
          <DevicesView status={status} />
        )}
        {reportType && reportType === "alerts" && (
          <div style={{ width: "100%" }}>
            <Alerts status={status} />
          </div>
        )}
      </Grid>
    </>
  );
};

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => (
    <DeleteOutline color={"error"} {...props} ref={ref} />
  )),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  CreateIcon: forwardRef((props, ref) => <CreateIcon {...props} ref={ref} />),
};

const columns = [
  { id: "id", label: "id", minWidth: 170 },
  { id: "client", label: "client", minWidth: 100 },
  {
    id: "facility",
    label: "Facility",
    minWidth: 170,
  },
  {
    id: "zone",
    label: "Zone",
    minWidth: 170,
  },
];

const chartConfig = {
  chart: {
    height: 300,
    zoomType: "xy",
  },
  title: {
    text: "",
  },
  subtitle: {
    text: "",
  },
  xAxis: {
    crosshair: true,
    // tickmarkPlacement: "on",
    type: "datetime",
    labels: {
      formatter: function () {
        var monthStr = ReactHighcharts.Highcharts.dateFormat(
          "%e. %b %H:%M",
          this.value
        );
        return monthStr;
      },
    },
  },
  yAxis: {
    floor: 0,
    allowDecimals: false,
    title: {
      enabled: false,
      text: "",
    },
    labels: {
      format: "{value} ",
      style: {
        color: ReactHighcharts.Highcharts.getOptions().colors[0],
      },
    },
  },

  tooltip: {
    valueDecimals: 0,
  },
  plotOptions: {
    series: {
      marker: {
        enabled: false,
        symbol: "circle",
      },
      connectNulls: false,
    },
  },
  series: [],
};

const calculateTimeRange = (time) => {
  let timeFormat = "HH:mm";

  if (time === "14d" || time === "30d") {
    return "MM/DD HH:mm";
  }
  return timeFormat;
};

const getInteval = (time) => {
  let timeFormat = "1 Min";

  if (time === "6h") {
    timeFormat = "5 Min";
  }
  if (time === "24h") {
    timeFormat = "10 Min";
  }
  if (time === "14d") {
    timeFormat = "3 hour";
  }
  if (time === "30d") {
    timeFormat = "12 hour";
  }

  return timeFormat;
};

const DevicesView = (props) => {
  const { status } = props;
  const [rows, setRows] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [sensorId, setSensorId] = useState(0);
  const [config, setConfig] = useState(chartConfig);
  const [time, setTime] = useState("30m");
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    getDevices();
  }, [status]);
  const getDevices = () => {
    deviceService.getAllDevices().then((responseData) => {
      setRows(responseData.data);
      let tempData = [];
      if (status && status === "Healthy") {
        tempData = responseData.data.filter((value) => value.sparkLines);
      } else if (status && status === "UnHealthy") {
        tempData = responseData.data.filter((value) => !value.sparkLines);
      } else {
        tempData = responseData.data;
      }
      setRows(tempData);
    });
  };
  const applyFilter = (data) => {
    deviceService.getFilteredClientData(data).then(
      (res) => {
        setRows(res.data);
      },
      (err) => {}
    );
  };
  const deleteClient = (id) => {
    deviceService.deleteDevice(id).then((res) => {
      if (res.data.status == true) {
        getDevices();
      }
    });
  };
  const editClient = (serialNumber) => {
    // window.history.pushState("", "", "/home/setup?serialNumber=" + serialNumber);
    props.setSensorNumber(serialNumber);

    // props.openCreateOrEditDeviceForm();
  };

  const onDateChangeHanlder = (sensorId, time) => {
    const data = { time: time };
    deviceService.getDetailedView(sensorId, data).then((responseData) => {
      console.log(responseData.data);
      const dateFormt = calculateTimeRange(time);
      const interval = getInteval(time);
      setTime(time);
      const tempObject = Object.assign({}, config);
      tempObject.xAxis.labels = {
        formatter: function () {
          var monthStr = moment(this.value)
            .tz(rows[0].timeZone)
            .format(dateFormt);
          return monthStr;
        },
      };

      if (tempObject.series && tempObject.series.length > 0) {
        tempObject.series[0].data = responseData.data;
        tempObject.series[0].name = "Number of data points per " + interval;
      } else {
        tempObject.series = [
          {
            type: "column",
            name: "Number of data points per " + interval,
            data: responseData.data,
          },
        ];
      }

      setConfig((state) => {
        return tempObject;
      });
      //setConfig(tempObject);
    });
  };

  const showDetailedView = (event, sensorId, time) => {
    if (event) {
      event.preventDefault();
    }

    const data = { time: "30m" };
    deviceService.getDetailedView(sensorId, data).then((responseData) => {
      console.log(responseData.data);
      const dateFormt = calculateTimeRange("30m");
      setTime("30m");
      chartConfig.xAxis.labels = {
        formatter: function () {
          var monthStr = moment(this.value)
            .tz(rows[0].timeZone)
            .format(dateFormt);
          return monthStr;
        },
      };
      chartConfig.tooltip = {
        formatter: function () {
          return this.points.reduce(function (s, point) {
            return s + "<br/>" + point.series.name + ": " + point.y.toFixed(2);
          }, "<b>" +
            moment(this.x).tz(rows[0].timeZone).format(dateFormt) +
            "</b>");
        },
        shared: true,
      };
      chartConfig["series"] = [
        {
          type: "column",
          name: "Number of data points per minute",
          data: responseData.data,
        },
      ];
      setConfig(chartConfig);
      setSensorId(sensorId);
      setOpen(!open);
    });
  };
  const handleClose = () => {
    setOpen(!open);
  };
  return (
    <div className="equal-height-container" style={{ width: "100%" }}>
      <Grid container spacing={1}>
        <Grid container item xs={12} lg={12} md={12}>
          <div style={{ width: "100%" }}>
            <MaterialTable
              icons={tableIcons}
              title="Network Devices"
              columns={[
                { title: "Serial Number", field: "serial_number" },
                {
                  title: "Data rate",
                  field: "serial_number",
                  render: (rowData) => (
                    <>
                      {rowData.sparkLines && (
                        <a
                          href="#"
                          onClick={(event) =>
                            showDetailedView(event, rowData.serial_number, time)
                          }
                        >
                          <Sparklines
                            data={rowData.sparkLines}
                            width={100}
                            height={15}
                          >
                            <SparklinesBars color="#2C8EBB" />
                          </Sparklines>
                        </a>
                      )}
                      {!rowData.sparkLines && (
                        <a
                          href="#"
                          style={{ textDecoration: "none", color: "black" }}
                          onClick={(event) =>
                            showDetailedView(event, rowData.serial_number, time)
                          }
                        >
                          No Data
                        </a>
                      )}
                    </>
                  ),
                },

                {
                  title: "Last seen",
                  field: "lastSeen",
                  render: (rowData) => (
                    <>
                      {rowData.lastSeen &&
                        moment(rowData.lastSeen)
                          .tz(rowData.timeZone)
                          .format("MM/DD/YYYY h:mm a")}
                      {!rowData.lastSeen && <span>No Data</span>}
                    </>
                  ),
                },
                { title: "Client", field: "client" },
                { title: "Facility", field: "facility" },
                { title: "Zone", field: "zone" },
                {
                  title: "Room",
                  field: "room",
                },
              ]}
              data={rows}
              options={{
                actionsColumnIndex: -1,
              }}
            />
          </div>
        </Grid>
      </Grid>
      <RenderModal
        chartConfig={chartConfig}
        handleClose={handleClose}
        open={open}
        sensorId={sensorId}
        showDetailedView={onDateChangeHanlder}
        value={time}
      />
    </div>
  );
};

const Filters = (props) => {
  const [clients, setClients] = useState([]);
  const { data } = props;
  const classes = useStyles();
  const [facilities, setFacilities] = useState([]);
  const [zones, setZones] = useState([]);
  const [rooms, setRooms] = useState([]);
  const [filterForm, setFilterForm] = useState({
    client: "",
    facility: "",
    zone: "",
    room: "",
  });
  const [open, setOpen] = useState(false);
  useEffect(() => {
    let getAllClients = JSON.parse(localStorage.getItem("masterClients"));
    let getUniqClients = _.uniq(_.pluck(getAllClients, "client"));
    setClients(getUniqClients);
  }, []);
  const changeHandler = (event) => {
    const { name, value } = event.target;
    setFilterForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    if (name == "client") {
      deviceService.getFaclities({ client: value }).then(
        (res) => {
          setFacilities(res.data);
        },
        (err) => {}
      );
    } else if (name == "facility") {
      let data = { facility: value, clients: filterForm.client };
      deviceService.getZones(data).then(
        (res) => {
          setZones(res.data);
        },
        (err) => {}
      );
    } else if (name == "zone") {
      let data = {
        zone: value,
        facility: filterForm.facility,
        clients: filterForm.client,
      };
      deviceService.getRooms(data).then(
        (res) => {
          setRooms(res.data);
        },
        (err) => {}
      );
    }
  };

  const applyFilter = (event) => {
    event.preventDefault();
    props.applyFilter(filterForm);
  };

  const resetData = () => {
    const data = {
      client: "",
      facility: "",
      zone: "",
      room: "",
    };
    setFilterForm(data);
  };

  return (
    <div className="filterContainer">
      <form novalidate onSubmit={applyFilter}>
        <div className="buttonContainer">
          <Button
            variant="contained"
            type="submit"
            color="primary"
            fullWidth
            type="submit"
          >
            Apply
          </Button>
          {/* <hr style={{ border: "none" }} />

                    <Button variant="contained" type="button" onClick={resetData} fullWidth>
                        Reset
                    </Button> */}
        </div>

        <FormControl className={classes.formControl} style={{ width: "100%" }}>
          <InputLabel id="demo-simple-select-label">Client</InputLabel>
          <Select
            labelId="client-label"
            id="client"
            name="client"
            onChange={changeHandler}
          >
            {clients.map((value) => {
              return <MenuItem value={value}>{value}</MenuItem>;
            })}
          </Select>
        </FormControl>

        <FormControl className={classes.formControl} style={{ width: "100%" }}>
          <InputLabel id="demo-simple-select-label">Facilities</InputLabel>
          <Select
            labelId="client-label"
            id="facility"
            name="facility"
            onChange={changeHandler}
          >
            {facilities.map((value) => {
              return (
                <MenuItem value={value.facility}>{value.facility}</MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <FormControl className={classes.formControl} style={{ width: "100%" }}>
          <InputLabel id="demo-simple-select-label">Zone</InputLabel>
          <Select
            labelId="client-label"
            id="zone"
            name="zone"
            onChange={changeHandler}
          >
            {zones.map((value) => {
              return <MenuItem value={value.zone}>{value.zone}</MenuItem>;
            })}
          </Select>
        </FormControl>
        <FormControl className={classes.formControl} style={{ width: "100%" }}>
          <InputLabel id="demo-simple-select-label">Room</InputLabel>
          <Select
            labelId="client-label"
            id="room"
            name="room"
            onChange={changeHandler}
          >
            {rooms.map((value) => {
              return <MenuItem value={value.room}>{value.room}</MenuItem>;
            })}
          </Select>
        </FormControl>
      </form>
    </div>
  );
};

const RenderModal = (props) => {
  const classes = useStyles();
  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth="xl"
      fullWidth={true}
    >
      <DialogTitle id="form-dialog-title">
        Date rate for sensor - {props.sensorId}
        <form className={classes.form} noValidate>
          <FormControl
            style={{ float: "right", marginTop: "-35px" }}
            className={classes.formControl}
          >
            <Select
              autoFocus
              inputProps={{
                name: "max-width",
                id: "max-width",
              }}
              placeholder="Select date"
              value={props.value}
              onChange={(event) =>
                props.showDetailedView(props.sensorId, event.target.value)
              }
            >
              <MenuItem value="30m">Last 30 mins</MenuItem>
              <MenuItem value="1h">Last 1 hour</MenuItem>
              <MenuItem value="4h">Last 4 hours</MenuItem>
              <MenuItem value="6h">Last 6 hours</MenuItem>
              <MenuItem value="24h">Last 24 hours</MenuItem>
              <MenuItem value="14d">Last 14 days</MenuItem>
              <MenuItem value="30d">Last 30 days</MenuItem>
            </Select>
          </FormControl>
        </form>
      </DialogTitle>
      <DialogContent dividers>
        <ReactHighcharts
          config={props.chartConfig}
          class="chartCss"
        ></ReactHighcharts>
      </DialogContent>

      <DialogActions>
        <Button onClick={props.handleClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const RenderAlerts = () => {
  <Alerts />;
};

export default ReportsNew;
