import React from "react";
import ColorPicker from "./ColorPicker";
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { DoneAll } from "@material-ui/icons";

class ReactTable extends React.Component {
  state = {
    rows: [{}]
  };
  handleChange = (idx) => (e) => {
    const { name, value } = e.target;
    const rows = [...this.state.rows];
    rows[idx] = {
      [name]: value
    };
    this.setState({
      rows
    });
  };
  handleAddRow = () => {
    const item = {
      name: "",
      mobile: ""
    };
    this.setState({
      rows: [...this.state.rows, item]
    });
  };
  handleRemoveRow = () => {
    this.setState({
      rows: this.state.rows.slice(0, -1)
    });
  };
  handleRemoveSpecificRow = (idx) => () => {
    const rows = [...this.state.rows];
    rows.splice(idx, 1);
    this.setState({ rows });
  };
  render() {
    return (
      <div>
        <div className="container">
          <div className="row clearfix">
            <div className="col-md-12 column">
              <table
                className="table table-bordered table-hover"
                id="tab_logic"
              >
                <thead>
                  <tr>
                    <th className="text-center"> # </th>
                    <th className="text-center"> MinThreshold </th>
                    <th className="text-center"> MaxThreshold </th>
                    <th className="text-center">Color</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {this.state.rows.map((item, idx) => (
                    <tr id="addr0" key={idx}>
                      <td>{idx}</td>
                      <td>
                        <input
                          type="number"
                          name="minTemp"
                          value={this.state.rows[idx].name}
                          onChange={this.handleChange(idx)}
                          // onChange={()=>this.props.updateThreshold(this.state.rows)}
                          className="form-control"
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          name="maxTemp"
                          value={this.state.rows[idx].mobile}
                          onChange={this.handleChange(idx)}
                          className="form-control"
                        />
                      </td>
                      <td>
                        <ColorPicker />
                      </td>
                      <td>
                        <IconButton aria-label="delete"  color="secondary" onClick={this.handleRemoveSpecificRow(idx)} >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                        <IconButton aria-label="save"  color="primary" onClick={this.handleRemoveSpecificRow(idx)} >
                          <DoneAll fontSize="small" />
                        </IconButton>                        
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <button onClick={this.handleAddRow} className="btn btn-primary">
                Add Row
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ReactTable;
