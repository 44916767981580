import { ColorPicker, createColor } from "material-ui-color";
import React, { useState } from "react";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {}
});

export default function Color() {
  const [color, setColor] = useState(createColor("#000"));
  const handleChange = (value) => {
    console.log("onChange=", value);
    setColor(value);
  };

  return (
    <ThemeProvider theme={theme}>
      <div>
        <ColorPicker value={color} onChange={handleChange} />
      </div>
    </ThemeProvider>
  );
}
