import { Card, Grid, IconButton, InputBase } from '@material-ui/core';
import { GridSearchIcon } from '@material-ui/data-grid';
import { subDays } from 'date-fns';
import { useState } from 'react';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
const RenderFilter = props => {
  const [value, onChange] = useState([new Date(), new Date()]);
  return (
    <Grid container spacing={2}>
      <Grid item xs={9} md={9} lg={9}>

        

      </Grid>
      <Grid item xs={2} md={2} lg={2}>
        <DateRangePicker
          style={{ marginTop: '12px' }}
          onChange={props.changeDate}
          value={props.date}
        />
      </Grid>
    </Grid>
  );
};

export default RenderFilter;
