import { Card, CardContent, Typography } from '@material-ui/core';
import { useEffect, useState } from 'react';
import ReactHighcharts from 'react-highcharts';
import AnalyticsService from '../../api/analyticsService';
import { getBoxPlotChartConfig, monthMapObj } from '../../utils/util';
import BarLoader from 'react-bar-loader';

export const MonthlyPlot = props => {
  const { data } = props;
  const [trendsData, setTrendsData] = useState({});
  const userSettings1 = JSON.parse(localStorage.getItem('UserData'));
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (data.sensors) {
      setLoading(true);
      const finalData = data;
      finalData['type'] = 'monthlyBox';
      AnalyticsService.getTrends(finalData).then(responseData => {
        const chartConfig = getBoxPlotChartConfig(
          data.parameter,
          userSettings1
        );

        const convert = responseData.data.xaxis.map(i => monthMapObj[i]);

        chartConfig.xAxis.categories = convert;
        chartConfig.series = [
          { showInLegend: false, data: responseData.data.data }
        ];
        chartConfig.chart = { type: 'boxplot', zoomType: 'xy' };
        setTrendsData(chartConfig);
        setLoading(false);
      });
    }
  }, [data]);

  return (
    <div id="dashboard-container-33">
      {loading && <BarLoader color="#1D8BF1" height="2" fullWidth />}
      <Card style={{ height: '500px' }}>
        <CardContent className={'setFixheight'}>
          <span className="titleCss1">
            <Typography>Monthly Box trends</Typography>
          </span>
          <br />
          <ReactHighcharts
            config={trendsData}
            allowChartUpdate={true}
            className="chartCss"
          />
        </CardContent>
      </Card>
    </div>
  );
};
