import React, { useEffect, useState } from 'react';
import SettingsService from '../SettingsService';

import {
  ValidatorForm,
  TextValidator,
  SelectValidator
} from 'react-material-ui-form-validator';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { Button, MenuItem } from '@material-ui/core';
import _ from 'lodash';
import { ToastContainer, toast } from 'react-toastify';
import { DataGrid } from '@material-ui/data-grid';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/Edit';
const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary
  }
}));

const Alert = () => {
  const [displayCreated, setDisplayCreated] = useState(false);
  const [formData, setFormData] = useState({});
  const toggle = () => {
    setDisplayCreated(!displayCreated);
  };
  const showEdit = params => {
    //console.log(params.row);
    setDisplayCreated(!displayCreated);
    setFormData(params.row);
  };
  return (
    <React.Fragment>
      <ToastContainer />
      {displayCreated && (
        <RenderAlertForm editData={formData} toggle={toggle} />
      )}
      {!displayCreated && <RenderAlerts showEdit={showEdit} toggle={toggle} />}
    </React.Fragment>
  );
};

const RenderAlertsData = () => {
  const [rows, setRows] = useState([]);
};

const RenderAlertForm = props => {
  const classes = useStyles();

  const [formData, setFormData] = useState({
    id: 0,
    alertName: '',
    alertDescription: '',
    priority: '',
    sensorNumber: 0,
    parameterValue: '',
    parameterName: '',
    aggType: '',
    timeUnit: ''
  });

  const [devices, setDevices] = useState([]);

  useEffect(() => {
    fetchDevices();
    if (props.editData.alertName) {
      setFormData(props.editData);
    }
  }, [props.editData]);

  const fetchDevices = () => {
    SettingsService.getAllDevices().then(responseData => {
      setDevices(responseData.data);
    });
  };

  const handleSubmit = () => {
    SettingsService.saveAlerts(formData).then(responseData => {
      if (responseData.data.status) {
        toast.success('Saved Successfully', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
      } else {
        toast.error('Unable to Save Data', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
      }
    });
  };

  const handleChange = event => {
    const tempFormData = _.cloneDeep(formData);
    tempFormData[event.target.name] = event.target.value.toString();
    setFormData(tempFormData);
  };

  return (
    <div className={classes.root}>
      <div style={{ width: '100%' }}>
        <Button
          style={{ float: 'right' }}
          variant="contained"
          color="primary"
          onClick={props.toggle}
        >
          Back
        </Button>
      </div>
      <ValidatorForm onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TextValidator
              style={{ width: '50%' }}
              label="Name"
              onChange={event => handleChange(event)}
              name="alertName"
              value={formData.alertName}
              validators={['required']}
              errorMessages={['this field is required']}
            />
          </Grid>
          <Grid item xs={6}>
            <TextValidator
              style={{ width: '50%' }}
              label="Description"
              onChange={event => handleChange(event)}
              name="alertDescription"
              value={formData.alertDescription}
              validators={['required']}
              errorMessages={['this field is required']}
            />
          </Grid>
          <Grid item xs={6}>
            <SelectValidator
              style={{ width: '50%' }}
              label="Priority"
              onChange={event => handleChange(event)}
              name="priority"
              value={formData.priority}
              validators={['required']}
              errorMessages={['this field is required']}
            >
              <MenuItem value="Critical">Critical</MenuItem>
              <MenuItem value="High">High</MenuItem>
              <MenuItem value="Medium">Medium</MenuItem>
              <MenuItem value="Low">Low</MenuItem>
            </SelectValidator>
          </Grid>
          <Grid item xs={6}>
            <SelectValidator
              style={{ width: '50%' }}
              label="Sensor"
              onChange={event => handleChange(event)}
              name="sensorNumber"
              value={formData.sensorNumber}
              validators={['required']}
              errorMessages={['this field is required']}
            >
              {devices.map(value => {
                return (
                  <MenuItem value={value.serial_number.toString()}>
                    {value.serial_number}
                  </MenuItem>
                );
              })}
            </SelectValidator>
          </Grid>
          <Grid item xs={6}>
            <SelectValidator
              style={{ width: '50%' }}
              label="Parameter Name"
              onChange={event => handleChange(event)}
              name="parameterName"
              value={formData.parameterName}
              validators={['required']}
              errorMessages={['this field is required']}
            >
              <MenuItem value="pn03_t">pn03_t</MenuItem>
              <MenuItem value="pn05_t">pn05_t</MenuItem>
              <MenuItem value="pn10_t">pn10_t</MenuItem>
              <MenuItem value="pn50_t">pn50_t</MenuItem>
              <MenuItem value="pn100_t">pn100_t</MenuItem>
              <MenuItem value="pm25_t">pm25_t</MenuItem>
              <MenuItem value="pm100_t">pm100_t</MenuItem>
              <MenuItem value="pn05_b">pn05_b</MenuItem>
              <MenuItem value="pn10_b">pn10_b</MenuItem>
              <MenuItem value="pn25_b">pn25_b</MenuItem>
              <MenuItem value="pn50_b">pn50_b</MenuItem>
              <MenuItem value="pn100_b">pn100_b</MenuItem>
              <MenuItem value="pm10_b">pm10_b</MenuItem>
              <MenuItem value="pm25_b">pm25_b</MenuItem>
              <MenuItem value="pm100_b">pm100_b</MenuItem>
              <MenuItem value="temp">temp</MenuItem>
              <MenuItem value="hum">hum</MenuItem>
              <MenuItem value="pressure">pressure</MenuItem>
              <MenuItem value="CO2">CO2</MenuItem>
              <MenuItem value="eco2">eco2</MenuItem>
              <MenuItem value="tvoc">tvoc</MenuItem>
              <MenuItem value="rawh2">rawh2</MenuItem>
              <MenuItem value="rawethanol">rawethanol</MenuItem>
              <MenuItem value="pn03_m">pn03_m</MenuItem>
              <MenuItem value="pm2.5_m">pm2.5_m</MenuItem>
              <MenuItem value="pm10_m">pm10_m</MenuItem>
            </SelectValidator>
          </Grid>

          <Grid item xs={6}>
            <SelectValidator
              style={{ width: '50%' }}
              label="Agg type"
              onChange={event => handleChange(event)}
              name="aggType"
              value={formData.aggType}
              validators={['required']}
              errorMessages={['this field is required']}
            >
              <MenuItem value="Sum">Sum</MenuItem>
              <MenuItem value="Avg">Avg</MenuItem>
              <MenuItem value="Mean">Mean</MenuItem>
              <MenuItem value="Median">Median</MenuItem>
            </SelectValidator>
          </Grid>
          <Grid item xs={6}>
            <SelectValidator
              style={{ width: '50%' }}
              label="Operator"
              onChange={event => handleChange(event)}
              name="operator"
              value={formData.operator}
              validators={['required']}
              errorMessages={['this field is required']}
            >
              <MenuItem value="Sum">Equal</MenuItem>
              <MenuItem value="Avg">Greater Than</MenuItem>
              <MenuItem value="Mean">Less Than</MenuItem>
            </SelectValidator>
          </Grid>
          <Grid item xs={6}>
            <TextValidator
              style={{ width: '50%' }}
              label="Parameter Value"
              onChange={event => handleChange(event)}
              name="parameterValue"
              value={formData.parameterValue}
              validators={['required']}
              errorMessages={['this field is required']}
            ></TextValidator>
          </Grid>
          <Grid item xs={6}>
            <TextValidator
              style={{ width: '50%' }}
              label="Time Value"
              onChange={event => handleChange(event)}
              name="timeValue"
              value={formData.timeValue}
              validators={['required']}
              errorMessages={['this field is required']}
            ></TextValidator>
          </Grid>

          <Grid item xs={6}>
            <SelectValidator
              style={{ width: '50%' }}
              label="Time Unit"
              onChange={event => handleChange(event)}
              name="timeUnit"
              value={formData.timeUnit}
              validators={['required']}
              errorMessages={['this field is required']}
            >
              <MenuItem value="Seconds">Seconds</MenuItem>
              <MenuItem value="Minutes">Minutes</MenuItem>
              <MenuItem value="Hours">Hours</MenuItem>
            </SelectValidator>
          </Grid>
        </Grid>
        <br />
        <Button variant="contained" color="primary" type="submit">
          Save Alerts
        </Button>
      </ValidatorForm>
    </div>
  );
};

const RenderAlerts = props => {
  const [rows, setRows] = useState([]);

  const deleteRow = id => {
    SettingsService.deleteAlerts(id).then(responseData => {
      if (responseData.status === 200) {
        toast.success('Deleted Successfully', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
        fetchAllAlerts();
      }
    });
  };

  const columns = [
    { field: 'id', headerName: 'ID', width: 90 },
    { field: 'alertName', headerName: 'Name', width: 250 },
    { field: 'alertDescription', headerName: 'Description', width: 300 },
    { field: 'priority', headerName: 'Priority', width: 100 },
    { field: 'createdBy', headerName: 'createdBy', width: 250 },
    {
      field: 'Action',
      headerName: 'Action',
      flex: 0.3,
      renderCell: params => (
        <React.Fragment>
          <EditIcon
            onClick={() => props.showEdit(params)}
            style={{ cursor: 'pointer' }}
            fontSize="small"
          />{' '}
          <DeleteOutlineIcon
            onClick={() => deleteRow(params.row.id)}
            style={{ cursor: 'pointer' }}
            fontSize="small"
          />
        </React.Fragment>
      )
    }
  ];

  const fetchAllAlerts = () => {
    SettingsService.getAllAlerts().then(responseData => {
      setRows(responseData.data);
    });
  };

  useEffect(() => {
    fetchAllAlerts();
  }, []);

  return (
    <React.Fragment>
      <div style={{ width: '100%' }}>
        <Button
          style={{ float: 'right' }}
          variant="contained"
          color="primary"
          onClick={props.toggle}
        >
          Create Alert
        </Button>
      </div>

      <div style={{ height: 400, width: '100%', marginTop: '12px' }}>
        <DataGrid rows={rows} columns={columns} pageSize={5} />
      </div>
    </React.Fragment>
  );
};

export default Alert;
