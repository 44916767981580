import addNoDataModule from 'highcharts/modules/no-data-to-display';

const Highcharts = require('react-highcharts');
const ReactHighcharts = require('react-highcharts');
var temperatureData = [7.0, 6.9, 9.5, 14.5, 18.2];
const airQualityIndexData = [];
var relativeHumidityData = [];
var cO2Data = [];
var vocSData = [];
var particleConcentrationData = [];

if (typeof Highcharts === 'object') {
  addNoDataModule(Highcharts);
}

export const lineChart = {
  chart: {
    zoomType: 'xy',
    height: 300,
    events: {
      load() {
        this.showLoading();
        setTimeout(this.hideLoading.bind(this), 2000);
      }
    }
  },
  lang: {
    noData: 'No Data'
  },
  noData: {
    style: {
      fontWeight: 'bold',
      fontSize: '15px',
      color: '#303030'
    }
  },
  title: {
    text: ''
  },

  xAxis: {
    type: 'datetime',
    crosshair: true,
    allowDecimals: false,
    labels: {
      formatter: function () {
        var monthStr = ReactHighcharts.Highcharts.dateFormat(
          '%e. %b %H:%M',
          this.value
        );
        return monthStr;
      }
    }
  },

  yAxis: [
    {
      // Relative Humdity yAxis
      gridLineWidth: 0,
      title: {
        text: 'Relative Humdity',
        enabled: false,
        style: {
          color: ReactHighcharts.Highcharts.getOptions().colors[0]
        }
      },
      labels: {
        format: '{value}',
        style: {
          color: ReactHighcharts.Highcharts.getOptions().colors[0]
        }
      },
      allowDecimals: false
    },
    {
      // VOCs yAxis
      gridLineWidth: 0,
      allowDecimals: false,
      title: {
        enabled: false,
        text: 'VOCs',
        style: {
          color: ReactHighcharts.Highcharts.getOptions().colors[1]
        }
      },
      labels: {
        format: '{value} ',
        style: {
          color: ReactHighcharts.Highcharts.getOptions().colors[1]
        }
      },

      opposite: true
    },

    {
      // Temperature yAxis
      gridLineWidth: 0,
      title: {
        text: 'Temperature',
        enabled: false,
        style: {
          color: ReactHighcharts.Highcharts.getOptions().colors[2]
        }
      },
      labels: {
        format: '{value}',
        style: {
          color: ReactHighcharts.Highcharts.getOptions().colors[2]
        }
      },
      allowDecimals: false,
      opposite: true
    },
    {
      // Air Quality yAxis
      labels: {
        format: '{value} ',
        style: {
          color: ReactHighcharts.Highcharts.getOptions().colors[3]
        }
      },
      title: {
        enabled: false,
        text: 'Air Quality Index',
        style: {
          color: ReactHighcharts.Highcharts.getOptions().colors[3]
        }
      },
      allowDecimals: false,
      opposite: true,

      opposite: true
    },

    {
      // particle Concentration yAxis
      gridLineWidth: 0,
      title: {
        enabled: false,
        text: 'Particle Concentration',
        style: {
          color: ReactHighcharts.Highcharts.getOptions().colors[4]
        }
      },
      allowDecimals: false,
      labels: {
        format: '{value} ',
        style: {
          color: ReactHighcharts.Highcharts.getOptions().colors[4]
        }
      },
      opposite: true
    },
    {
      // Co2 yAxis
      gridLineWidth: 0,
      allowDecimals: false,
      title: {
        text: 'Co2',
        enabled: false,
        style: {
          color: ReactHighcharts.Highcharts.getOptions().colors[5]
        }
      },
      labels: {
        format: '{value} ',
        style: {
          color: ReactHighcharts.Highcharts.getOptions().colors[5]
        }
      },
      opposite: true
    },
    {
      // Co2 yAxis
      gridLineWidth: 0,
      allowDecimals: false,
      title: {
        text: 'PM2.5',
        enabled: false,
        style: {
          color: ReactHighcharts.Highcharts.getOptions().colors[6]
        }
      },
      labels: {
        format: '{value} ',
        style: {
          color: ReactHighcharts.Highcharts.getOptions().colors[6]
        }
      },
      opposite: true
    },
    {
      // Co2 yAxis
      gridLineWidth: 0,
      allowDecimals: false,
      title: {
        text: 'PM100',
        enabled: false,
        style: {
          color: ReactHighcharts.Highcharts.getOptions().colors[7]
        }
      },
      labels: {
        format: '{value} ',
        style: {
          color: ReactHighcharts.Highcharts.getOptions().colors[7]
        }
      },
      opposite: true
    }
  ],
  tooltip: {
    shared: true,
    valueDecimals: 0
  },
  plotOptions: {
    series: {
      events: {
        legendItemClick: function () {
          var temp = JSON.parse(localStorage.getItem('filters'));
          var temp1 = [];
          temp1 = temp.timeSeries;
          let name = '';
          if (this.name.startsWith('Particle')) {
            name = 'particleConcentration';
          } else if (this.name.startsWith('Relative')) {
            name = 'relativeHumidity';
          } else if (this.name.startsWith('Pressure')) {
            name = 'pressure';
          } else if (this.name.startsWith('CO2')) {
            name = 'co2';
          } else if (this.name.startsWith('VOCs')) {
            name = 'tvoc';
          } else if (this.name.startsWith('Temp')) {
            name = 'temp';
          } else if (this.name.startsWith('PM2.5')) {
            name = 'pn25_m';
          } else if (this.name.startsWith('PM10')) {
            name = 'pm10_m';
          }
          if (this.visible == false) {
            temp1.push(name);
          } else {
            temp1.splice(temp1.indexOf(name), 1);
          }
          temp.timeSeries = temp1;
          localStorage.setItem('filters', JSON.stringify(temp));
          localStorage.setItem('timeTrendsFilters', JSON.stringify(temp));
        }
      },
      connectNulls: false,
      marker: {
        enabled: false,
        symbol: 'circle'
      }
    }
  },

  series: [
    {
      name: 'Air Quality Index',
      type: 'spline',
      // yAxis: 1,
      data: [],
      tooltip: {
        valueSuffix: 'ppm'
      }
    },
    {
      name: 'Particle Concentration',
      type: 'spline',
      // yAxis: 2,
      data: [],
      tooltip: {
        valueSuffix: 'ppm'
      }
    },
    {
      name: 'Temperature',
      type: 'spline',
      data: [],
      tooltip: {
        valueSuffix: ' °C'
      }
    },
    {
      name: 'Relative Humidity',
      type: 'spline',
      // yAxis: 4,
      data: [],
      tooltip: {
        valueSuffix: 'K'
      }
    },
    {
      name: 'Co2',
      type: 'spline',
      // yAxis: 2,
      data: [],
      tooltip: {
        valueSuffix: 'R'
      }
    },
    {
      name: 'VOCs',
      type: 'spline',
      // yAxis: 2,
      data: [],
      tooltip: {
        valueSuffix: ' ml'
      }
    },
    {
      name: 'PM2.5',
      type: 'spline',
      // yAxis: 2,
      data: [],
      tooltip: {
        valueSuffix: ' ml'
      }
    },
    {
      name: 'PM10',
      type: 'spline',
      // yAxis: 2,
      data: [],
      tooltip: {
        valueSuffix: ' ml'
      }
    }
  ],

  responsive: {
    rules: [
      {
        condition: {
          maxWidth: 500
        },
        chartOptions2: {
          legend: {
            floating: false,
            layout: 'horizontal',
            align: 'center',
            verticalAlign: 'bottom',
            x: 0,
            y: 0
          },
          yAxis: [
            {
              labels: {
                align: 'right',
                x: 0,
                y: -6
              },
              showLastLabel: false
            },
            {
              labels: {
                align: 'left',
                x: 0,
                y: -6
              },
              showLastLabel: false
            },
            {
              visible: false
            }
          ]
        }
      }
    ]
  }
};
