import {
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  Switch,
  TextField,
  Typography
} from '@material-ui/core';
import TimeZones, { timeZones } from './TimeZones';
import { ToastContainer, toast } from 'react-toastify';

import Alert from './components/Alerts';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Divider from '@material-ui/core/Divider';
import DraftsIcon from '@material-ui/icons/Drafts';
import InboxIcon from '@material-ui/icons/Inbox';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import React from 'react';
import ReferencedScalingFactor from './components/ReferencedScalingFactor';
import ScallingFactors from './components/ScallingFactors';
import SettingsService from './SettingsService';
import ThresholdAccordion from './components/ThresholdAccordion';
import { makeStyles } from '@material-ui/core/styles';

export class Settings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedIndex: 3,
      pnType: '1l',
      pmType: 'ug/m3',
      pressure: 'h20',
      relativeHumidity: '%',
      temperature: 'deg',
      tvoc: 'ppb',
      co2: 'ppm',
      highResTime: '',
      lowResTime: '',
      timeZone: '',
      liveRefreshTime: '',
      sensorStatusTime: '',
      timeZones: timeZones,
      pressureMax: '',
      pressureMin: '',
      tvocMin: '',
      tvocMax: '',
      co2Min: '',
      co2Max: '',
      humidityMax: '',
      humidityMin: '',
      tempMin: '',
      tempMax: '',
      pn10Min: '',
      pn10Max: '',
      pn25Min: '',
      p25Max: '',
      pn03Min: '',
      pn03Max: '',
      constantForMeanCalculation: '',
      alertEmail: '',
      longitude: '',
      latitude: '',
      location: '',
      refereceEPA: '',
      sensorNetworkLost: '',
      sensorAdded: '',
      otherAlerts: '',
      tempThreshold: '',
      pressureThreshold: '',
      ParticleConThreshold: '',
      CO2Threshold: '',
      vocsThreshold: '',
      reativeHumidityThreshold: '',
      PM25Threshold: '',
      PM10Threshold: '',
      threshold: []
    };

    this.handleListItemClick = this.handleListItemClick.bind(this);
    this.handleFormChange = this.handleFormChange.bind(this);
    this.getSavedData = this.getSavedData.bind(this);
    this.saveData = this.saveData.bind(this);
  }
  handleListItemClick(event, index) {
    this.setState({ selectedIndex: index });
  }

  handleFormChange(event) {
    const { name, value } = event.target;
    this.setState(prevState => ({
      ...prevState,
      [name]: value
    }));
  }

  updateThreshold = data => {
    this.setState({ threshold: data });
  };

  saveData() {
    console.log(this.state);
    localStorage.setItem('UserData', JSON.stringify(this.state));
    SettingsService.saveUserSettings(this.state).then(
      res => {
        if (res.data.status == true) {
          toast.success('Saved Successfully', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
        } else {
          toast.success(res.data.error, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
        }
      },
      err => {}
    );
  }
  componentDidMount() {
    this.getSavedData();
  }
  getSavedData() {
    SettingsService.getUserSettings().then(
      res => {
        this.setState({
          pnType: res.data.pnType,
          pmType: res.data.pmType,
          pressure: res.data.pressure,
          relativeHumidity: res.data.relativeHumidity,
          temperature: res.data.temperature,
          tvoc: res.data.tvoc,
          co2: res.data.co2,
          highResTime: res.data.highResTime,
          lowResTime: res.data.lowResTime,
          timeZone: res.data.timeZone,
          liveRefreshTime: res.data.liveRefreshTime,
          sensorStatusTime: res.data.sensorStatusTime,
          pressureMax: res.data.pressureMax,
          pressureMin: res.data.pressureMin,
          tvocMin: res.data.tvocMin,
          tvocMax: res.data.tvocMax,
          co2Min: res.data.co2Min,
          co2Max: res.data.co2Max,
          humidityMax: res.data.humidityMax,
          humidityMin: res.data.humidityMin,
          tempMin: res.data.tempMin,
          tempMax: res.data.tempMax,
          pn10Min: res.data.pn10Min,
          pn10Max: res.data.pn10Max,
          pn25Min: res.data.pn25Min,
          p25Max: res.data.p25Max,
          pn03Min: res.data.pn03Min,
          pn03Max: res.data.pn03Max,
          constantForMeanCalculation: res.data.constantForMeanCalculation,
          alertEmail: res.data.alertEmail
        });
        localStorage.setItem('UserData', JSON.stringify(res.data));
      },
      err => {}
    );
  }
  render() {
    return (
      <div style={{ flexGrow: 1 }}>
        <br />
        <ToastContainer />
        <Grid container spacing={1} style={{ padding: '0px 10px 0px 10px' }}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                <Typography
                  variant="h6"
                  gutterBottom
                  style={{ color: '#4E98B9', fontSize: '20px' }}
                >
                  Settings
                </Typography>
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                <Button
                  color="primary"
                  variant="contained"
                  style={{ float: 'right' }}
                  onClick={this.saveData}
                >
                  Save Setting
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <br />
        <div
          style={{
            flexGrow: 1,
            display: 'flex',
            height: '-webkit-fill-available'
          }}
        >
          <div style={{ width: '100%' }}>
            <div
              style={{
                backgroundColor: 'white',
                float: 'left',
                width: '25%',
                marginLeft: '1%',
                height: '100%',
                boxShadow:
                  '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)'
              }}
            >
              <div style={{ width: '100%' }}>
                <List component="nav" aria-label="secondary mailbox folder">
                  <ListItem
                    button
                    disabled
                    selected={this.state.selectedIndex === 2}
                    onClick={event => this.handleListItemClick(event, 2)}
                  >
                    <ListItemText primary="General Settings" />
                  </ListItem>
                  <ListItem
                    button
                    selected={this.state.selectedIndex === 3}
                    onClick={event => this.handleListItemClick(event, 3)}
                  >
                    <ListItemText primary="Units" />
                  </ListItem>

                  <ListItem
                    button
                    selected={this.state.selectedIndex === 4}
                    onClick={event => this.handleListItemClick(event, 4)}
                  >
                    <ListItemText primary="Downloads & Timings" />
                  </ListItem>

                  <ListItem
                    button
                    selected={this.state.selectedIndex === 7}
                    onClick={event => this.handleListItemClick(event, 7)}
                  >
                    <ListItemText primary="Scaling Factors" />
                  </ListItem>

                  <ListItem
                    button
                    selected={this.state.selectedIndex === 8}
                    onClick={event => this.handleListItemClick(event, 8)}
                  >
                    <ListItemText primary="Threshold" />
                  </ListItem>
                </List>
              </div>
            </div>
            <div
              style={{
                float: 'right',
                height: '100%',
                width: '72%',
                paddingRight: '1%',
                paddingLeft: '1%'
              }}
            >
              <div
                style={{
                  backgroundColor: '#fff',
                  width: '100%',
                  // marginLeft: "0px",
                  paddingTop: '1%',
                  paddingBottom: '1%',
                  boxShadow:
                    '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)'
                }}
              >
                {this.state.selectedIndex == 2 ? (
                  <Typography variant="h5">General Settings</Typography>
                ) : this.state.selectedIndex == 3 ? (
                  <div style={{ margin: '30px' }}>
                    <Typography variant="h5">Units</Typography>
                    <Grid container spacing={2} md={12}>
                      <Grid Container item md={6}>
                        <br />
                        <br />
                        <FormControl component="fieldset">
                          <FormLabel component="legend">Temperature</FormLabel>
                          <RadioGroup
                            row
                            aria-label="temperature"
                            name="temperature"
                            value={this.state.temperature}
                            onChange={this.handleFormChange}
                          >
                            <FormControlLabel
                              value="℃"
                              control={<Radio color="primary" />}
                              label="℃"
                            />
                            <FormControlLabel
                              value="℉"
                              control={<Radio color="primary" />}
                              label="℉"
                            />
                          </RadioGroup>
                        </FormControl>
                        <br />
                        <br />
                        <FormControl component="fieldset">
                          <FormLabel component="legend">
                            PN0.3 to PN1.0
                          </FormLabel>
                          <RadioGroup
                            row
                            aria-label="PN0.3 to PN1.0"
                            name="pnType"
                            value={this.state.pnType}
                            onChange={this.handleFormChange}
                          >
                            <FormControlLabel
                              value="#/0.1L"
                              control={<Radio color="primary" />}
                              label="#/0.1L"
                            />
                            <FormControlLabel
                              value="#/cm3"
                              control={<Radio color="primary" />}
                              label="#/cm3"
                            />

                            <FormControlLabel
                              value="#/L"
                              control={<Radio color="primary" />}
                              label="#/L"
                            />
                          </RadioGroup>
                        </FormControl>
                        <br />
                        <br />

                        <FormControl component="fieldset">
                          <FormLabel component="legend">
                            PM1.0 to PM10
                          </FormLabel>
                          <RadioGroup
                            row
                            aria-label="PM1.0 to PM10"
                            name="pmType"
                            onChange={this.handleFormChange}
                            value={this.state.pmType}
                          >
                            <FormControlLabel
                              value="ug/m^3"
                              control={<Radio color="primary" />}
                              label="ug/m^3"
                            />
                          </RadioGroup>
                        </FormControl>

                        <br />
                        <br />
                        <FormControl component="fieldset">
                          <FormLabel component="legend">CO2</FormLabel>
                          <RadioGroup
                            row
                            aria-label="Co2"
                            name="co2"
                            value={this.state.co2}
                            onChange={this.handleFormChange}
                          >
                            <FormControlLabel
                              value="ppm"
                              control={<Radio color="primary" />}
                              label="ppm"
                            />
                            <FormControlLabel
                              value="ug/m3"
                              control={<Radio color="primary" />}
                              label="ug/m3"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                      <Grid Container item md={6}>
                        <div>
                          <br />
                          <br />
                          <FormControl component="fieldset">
                            <FormLabel component="legend">tvoc</FormLabel>
                            <RadioGroup
                              row
                              aria-label="tvoc"
                              value={this.state.tvoc}
                              name="tvoc"
                              onChange={this.handleFormChange}
                            >
                              <FormControlLabel
                                value="ppb"
                                control={<Radio color="primary" />}
                                label="ppb"
                              />
                              <FormControlLabel
                                value="ppm"
                                control={<Radio color="primary" />}
                                label="ppm"
                              />
                            </RadioGroup>
                          </FormControl>
                          <br />
                          <br />
                          <FormControl component="fieldset">
                            <FormLabel component="legend">
                              Relative Humidity
                            </FormLabel>
                            <RadioGroup
                              row
                              aria-label="Relative Humidity"
                              name="relativeHumidity"
                              value={this.state.relativeHumidity}
                              onChange={this.handleFormChange}
                            >
                              <FormControlLabel
                                value="%"
                                control={<Radio color="primary" />}
                                label="%"
                              />
                            </RadioGroup>
                          </FormControl>
                          <br />
                          <br />
                          <FormControl component="fieldset">
                            <FormLabel component="legend">Pressure</FormLabel>
                            <RadioGroup
                              row
                              aria-label="pressure"
                              name="pressure"
                              onChange={this.handleFormChange}
                              value={this.state.pressure}
                            >
                              <FormControlLabel
                                value="''H2O"
                                control={<Radio color="primary" />}
                                label="''H2O"
                              />
                              <FormControlLabel
                                value="atm"
                                control={<Radio color="primary" />}
                                label="atm"
                              />
                              <FormControlLabel
                                value="kPa"
                                control={<Radio color="primary" />}
                                label="kPa"
                              />
                            </RadioGroup>
                          </FormControl>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                ) : this.state.selectedIndex == 4 ? (
                  <div style={{ margin: '30px' }}>
                    <Typography variant="h5">Downloads Parameters</Typography>
                    <br />
                    <br />
                    <Grid container spacing={2} md={12}>
                      <Grid Container item md={6}>
                        <FormControl component="fieldset">
                          <FormLabel component="legend">
                            High Resolution
                          </FormLabel>
                          <br />
                          <RadioGroup
                            row
                            aria-label="High Resolution"
                            name="highResTime"
                            onChange={this.handleFormChange}
                            value={this.state.highResTime}
                          >
                            <FormControlLabel
                              value="realtime"
                              control={<Radio color="primary" />}
                              label="Real Time"
                            />
                            <FormControlLabel
                              value="1min"
                              control={<Radio color="primary" />}
                              label="1 Minute"
                            />
                          </RadioGroup>
                        </FormControl>
                        <br />
                        <br />
                        <Typography variant="h6">Time Selection</Typography>
                        <FormControl fullWidth>
                          {/* <InputLabel id="timezone">Time Zone</InputLabel> */}
                          <Select
                            fullWidth
                            id="timezone"
                            value={this.state.timeZone}
                            name="timeZone"
                            onChange={this.handleFormChange}
                          >
                            {this.state.timeZones.map(data => (
                              <MenuItem value={data.type}>{data.name}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid container item md={6}>
                        <FormControl
                          component="fieldset"
                          row
                          style={{ width: '100%' }}
                        >
                          <FormLabel component="legend">
                            Low Resolution
                          </FormLabel>
                          <br />
                          <RadioGroup
                            row
                            aria-label="1 Hour"
                            name="lowResTime"
                            onChange={this.handleFormChange}
                            value={this.state.lowResTime}
                          >
                            <FormControlLabel
                              value="1hr"
                              control={<Radio color="primary" />}
                              label="1 Hour"
                            />
                            <FormControlLabel
                              value="24hrs"
                              control={<Radio color="primary" />}
                              label="24 Hours"
                            />
                          </RadioGroup>
                        </FormControl>
                        <br />
                        <br />
                        <br />
                        <br />
                        <FormControl
                          row
                          component="fieldset"
                          style={{ width: '100%' }}
                        >
                          <FormLabel component="legend">
                            Live Data Refresh Date
                          </FormLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            row
                            name="liveRefreshTime"
                            onChange={this.handleFormChange}
                            value={this.state.liveRefreshTime}
                          >
                            <MenuItem value={10000}>10 Seconds</MenuItem>
                            <MenuItem value={20000}>20 Seconds</MenuItem>
                            <MenuItem value={30000}>30 Seconds</MenuItem>
                            <MenuItem value={40000}>40 Seconds</MenuItem>
                            <MenuItem value={50000}>50 Seconds</MenuItem>
                            <MenuItem value={60000}>60 Seconds</MenuItem>
                            <MenuItem value={0}>Off</MenuItem>
                          </Select>
                        </FormControl>
                        <br />
                        <br />
                        <br />
                        <FormControl
                          row
                          component="fieldset"
                          style={{ width: '100%' }}
                        >
                          <FormLabel component="legend">
                            Time For Indication Of Sensor Status
                          </FormLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            row
                            onChange={this.handleFormChange}
                            value={this.state.sensorStatusTime}
                            name="sensorStatusTime"
                          >
                            <MenuItem value={'5'}>5 Minutes</MenuItem>
                            <MenuItem value={'10'}>10 Minutes</MenuItem>
                            <MenuItem value={'15'}>15 Minutes</MenuItem>
                            <MenuItem value={'30'}>30 Minutes</MenuItem>
                            <MenuItem value={'45'}>45 Minutes</MenuItem>
                            <MenuItem value={'60'}>60 Minutes</MenuItem>
                            <MenuItem value={'360'}>6 Hours</MenuItem>
                            <MenuItem value={'1440'}>24 Hours</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </div>
                ) : this.state.selectedIndex == 5 ? (
                  <div style={{ margin: '30px' }}>
                    <Typography variant="h5">
                      Parameters Value Selection
                    </Typography>
                    <br />
                    <br />
                    <Grid container spacing={2} md={12}>
                      <Grid Container item md={6}>
                        <div>
                          <legend>
                            Ratio Constant for Calculation Mean pn03_m
                          </legend>
                          <TextField
                            fullWidth
                            id="constantForMeanCalculation"
                            label="Ratio Constant For Calculation Mean pn03_m"
                            type="number"
                            value={this.state.constantForMeanCalculation}
                            onChange={this.handleFormChange}
                            name="constantForMeanCalculation"
                          />
                        </div>
                        <br />
                        <br />
                        <div>
                          <legend>PN03 To PN1.0</legend>
                          <TextField
                            id="pn03topn1Min"
                            style={{ float: 'left' }}
                            label="Minimum"
                            onChange={this.handleFormChange}
                            value={this.state.pn03Min}
                            type="number"
                            name="pn03Min"
                          />
                          <TextField
                            id="pn03topn1Max"
                            label="Maximum"
                            style={{ float: 'right' }}
                            type="number"
                            name="pn03Max"
                            onChange={this.handleFormChange}
                            value={this.state.pn03Max}
                          />
                        </div>
                        <br />
                        <br />
                        <br />
                        <div>
                          <legend>PN 2.5 to PN10:</legend>
                          <TextField
                            style={{ float: 'left' }}
                            label="Minimum"
                            type="number"
                            name="pn25Min"
                            onChange={this.handleFormChange}
                            value={this.state.pn25Min}
                          />
                          <TextField
                            style={{ float: 'right' }}
                            label="Maximum"
                            type="number"
                            name="pn25Max"
                            onChange={this.handleFormChange}
                            value={this.state.pn25Max}
                          />
                        </div>
                        <br />
                        <br />
                        <br />
                        <div>
                          <legend>PN 1.0,2.5,10: </legend>
                          <TextField
                            style={{ float: 'left' }}
                            label="Minimum"
                            type="number"
                            name="pn10Min"
                            onChange={this.handleFormChange}
                            value={this.state.pn10Min}
                          />
                          <TextField
                            style={{ float: 'right' }}
                            label="Maximum"
                            type="number"
                            name="pn10Max"
                            onChange={this.handleFormChange}
                            value={this.state.pn10Max}
                          />
                        </div>
                      </Grid>
                      <Grid Container item md={6}>
                        <div>
                          <legend>Temperature:</legend>
                          <TextField
                            style={{ float: 'left' }}
                            label="Minimum"
                            type="number"
                            name="tempMin"
                            onChange={this.handleFormChange}
                            value={this.state.tempMin}
                          />
                          <TextField
                            style={{ float: 'right' }}
                            label="Maximum"
                            type="number"
                            name="tempMax"
                            onChange={this.handleFormChange}
                            value={this.state.tempMax}
                          />
                        </div>
                        <br />
                        <br />
                        <br />
                        <div>
                          <legend>Relative Humidity</legend>
                          <TextField
                            style={{ float: 'left' }}
                            label="Minimum"
                            type="number"
                            name="humidityMin"
                            onChange={this.handleFormChange}
                            value={this.state.humidityMin}
                          />
                          <TextField
                            style={{ float: 'right' }}
                            label="Maximum"
                            type="number"
                            name="humidityMax"
                            onChange={this.handleFormChange}
                            value={this.state.humidityMax}
                          />
                        </div>
                        <br />
                        <br />
                        <br />
                        <div>
                          <legend>CO2(ppm)</legend>
                          <TextField
                            style={{ float: 'left' }}
                            label="Minimum"
                            type="number"
                            name="co2Min"
                            onChange={this.handleFormChange}
                            value={this.state.co2Min}
                          />
                          <TextField
                            style={{ float: 'right' }}
                            label="Maximum"
                            type="number"
                            name="co2Max"
                            onChange={this.handleFormChange}
                            value={this.state.co2Max}
                          />
                        </div>
                        <br />
                        <br />
                        <br />
                        <div>
                          <legend>tvoc(ppb)</legend>
                          <TextField
                            style={{ float: 'left' }}
                            label="Minimum"
                            type="number"
                            name="tvocMin"
                            onChange={this.handleFormChange}
                            value={this.state.tvocMin}
                          />
                          <TextField
                            style={{ float: 'right' }}
                            label="Maximum"
                            type="number"
                            onChange={this.handleFormChange}
                            value={this.state.tvocMax}
                            name="tvocMax"
                          />
                        </div>
                        <br />
                        <br />
                        <br />
                        <div>
                          <legend>Pressure(H2O)</legend>
                          <TextField
                            style={{ float: 'left' }}
                            label="Minimum"
                            type="number"
                            name="pressureMin"
                            onChange={this.handleFormChange}
                            value={this.state.pressureMin}
                          />
                          <TextField
                            style={{ float: 'right' }}
                            label="Maximum"
                            type="number"
                            name="pressureMax"
                            onChange={this.handleFormChange}
                            value={this.state.pressureMax}
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                ) : this.state.selectedIndex === 6 ? (
                  <div style={{ margin: '30px' }}>
                    <Typography variant="h5">Alerts & Notifications</Typography>
                    <br />
                    <br />
                    <Grid container spacing={2} md={12}>
                      <Alert />
                    </Grid>
                  </div>
                ) : this.state.selectedIndex === 7 ? (
                  <div style={{ margin: '30px' }}>
                    <Grid container spacing={1} md={12}>
                      <ScallingFactors />
                    </Grid>
                  </div>
                ) : this.state.selectedIndex === 8 ? (
                  <div style={{ margin: '30px' }}>
                    <ThresholdAccordion />
                  </div>
                ) : (
                  <div style={{ margin: '30px', height: 'inherit' }}>
                    <Grid container spacing={1} md={12}>
                      <ReferencedScalingFactor
                        updateThreshold={this.updateThreshold}
                      />
                    </Grid>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
