import axiosInstance from '../_helpers/interceptor';
import { DEV_DOMAIN_URL } from '../environment';

class DashboardService {
  getAllClients() {
    return axiosInstance.get(DEV_DOMAIN_URL + '/device/get-distinct-devices');
  }
  getAllFacility(data) {
    return axiosInstance.post(DEV_DOMAIN_URL + '/getfacilitydetails', data);
  }
  getAllZones(data) {
    return axiosInstance.post(DEV_DOMAIN_URL + '/getzonedetails', data);
  }
  getAllSensors(data) {
    return axiosInstance.post(DEV_DOMAIN_URL + '/getsensorids', data);
  }
  getAllParticleData() {
    return axiosInstance.get(DEV_DOMAIN_URL + '/getData');
  }
  getTimeSeriesChart() {
    return axiosInstance.get(DEV_DOMAIN_URL + '/getParticleChartData');
  }
  getRangeChart(data) {
    return axiosInstance.post(DEV_DOMAIN_URL + '/get-range-chart', data);
  }

  getChartData(data) {
    return axiosInstance.post(DEV_DOMAIN_URL + '/get-chart-data', data);
  }
  getRooms(data) {
    return axiosInstance.post(DEV_DOMAIN_URL + '/getrooms', data);
  }
  getFloorData(data) {
    return axiosInstance.post(DEV_DOMAIN_URL + '/get-floor-chart', data);
  }
  getRealTimeData(data) {
    return axiosInstance.post(DEV_DOMAIN_URL + '/get-real-time-data', data);
  }
  exportToCSV(data) {
    return axiosInstance.post(DEV_DOMAIN_URL + '/export-csv', data);
  }
  exportToRawCSV(data) {
    return axiosInstance.post(DEV_DOMAIN_URL + '/export-raw-csv', data);
  }
  getDetailedTimeChart(data) {
    return axiosInstance.post(DEV_DOMAIN_URL + '/get-detailed-chart', data);
  }
  getOverViewChart(data) {
    return axiosInstance.post(DEV_DOMAIN_URL + '/get-overview-chart', data);
  }
  sendDataToKafka(data) {
    return axiosInstance.post(DEV_DOMAIN_URL + '/send-to-kafka', data);
  }
  getDNDRealTimeData(data) {
    return axiosInstance.post(DEV_DOMAIN_URL + '/dnd/get-dnd', data);
  }
  getOneMinInterval() {
    return axiosInstance.get(DEV_DOMAIN_URL + '/dnd/get-1-interval');
  }
  getTypeDetails() {
    return axiosInstance.get(DEV_DOMAIN_URL + '/getProps');
  }

  getGeoMap(data) {
    return axiosInstance.post(DEV_DOMAIN_URL + '/get-geo-map', data);
  }
}

export default new DashboardService();
