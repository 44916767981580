import * as moment from 'moment-timezone';

import { MenuItem, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import BarLoader from 'react-bar-loader';
import AnalyticsService from '../api/analyticsService';
import Grid from '@material-ui/core/Grid';

import { Line } from '@ant-design/charts';

var _ = require('underscore');

const ReactHighcharts = require('react-highcharts');
require('highcharts-more')(ReactHighcharts.Highcharts);

const LogEntryAnalysis = ({ selectedRows }) => {
  const [chartData, setChartData] = useState([]);
  const [parameter, setParameter] = useState('pn03_m');
  const [loading, setLoading] = useState(true);
  const handleParameterChange = value => {
    setParameter(value);
  };

  useEffect(() => {
    setLoading(true);
    const tempSensor = [];
    for (const ele of selectedRows.sensors) {
      tempSensor.push(ele.sensor);
    }
    const data = {
      sensors: tempSensor,
      timeSeriesType: parameter,
      startDate: selectedRows.startDate,
      endDate: selectedRows.endDate
    };
    AnalyticsService.loadAnyaticalData(data).then(res => {
      console.log(res.data);
      setLoading(false);
      var tempData = res.data.detailed;
      var tempData1 = [];
      for (const element of tempData) {
        for (const element1 of element.data) {
          tempData1.push({
            label: element1[0],
            value: element1[1],
            category: element.name.toString()
          });
        }
      }
      const annotations = [];
      for (const ele of selectedRows.sensors) {
        if (ele.endDate != 0 && ele.startDate != 0) {
          annotations.push(
            {
              type: 'region',
              start: [getNearestTime(ele.startDate, tempData1), 'min'],
              end: [getNearestTime(ele.endDate, tempData1), 'max']
            },
            {
              type: 'dataMarker',
              position: [getNearestTime(ele.startDate, tempData1), 'median'],
              text: {
                content: ele.sensor + '-' + ele.entryLog,
                style: {
                  textAlign: 'left'
                }
              },
              line: {
                length: 20
              },
              point: {
                style: {
                  fill: '#f5222d',
                  stroke: '#f5222d'
                }
              },
              autoAdjust: false
            }
          );
        }
      }
      var config = {
        data: tempData1,
        xField: 'label',
        yField: 'value',
        seriesField: 'category',

        xAxis: {
          label: {
            formatter: function formatter(v) {
              return moment(parseInt(v)).tz('Etc/GMT').format('MM/DD HH:mm:ss');
            }
          },

          tickCount: 12
        },
        tooltip: {
          formatter: function formatter(v) {
            if (v.value === null) {
              return {
                title: moment(parseInt(v.label))
                  .tz('Etc/GMT')
                  .format('MM/DD/YYYY HH:mm:ss'),
                name: v.category,
                value: 0
              };
            } else {
              return {
                title: moment(parseInt(v.label))
                  .tz('Etc/GMT')
                  .format('MM/DD/YYYY HH:mm:ss'),
                name: v.category,
                value: v.value
              };
            }
          }
        },

        annotations: annotations
      };
      setChartData(config);
    });
  }, [selectedRows, parameter]);

  return (
    <React.Fragment>
      {loading && <BarLoader color="#1D8BF1" height="2" fullWidth />}
      {selectedRows && selectedRows.startDate && (
        <DisplayEventLogChart
          chartData={chartData}
          startDate={selectedRows.startDate}
          endDate={selectedRows.endDate}
          sensors={selectedRows.sensors}
          handleParameterChange={handleParameterChange}
        />
      )}
    </React.Fragment>
  );
};

const DisplayEventLogChart = props => {
  const [value, setValue] = useState('pn03_m');
  const [data, setData] = useState([]);
  useEffect(() => {
    var object = { target: { value: value } };
  }, []);

  const loadData = event => {
    setValue(event.target.value);
    props.handleParameterChange(event.target.value);
  };
  const annotations = [];
  for (const ele of props.sensors) {
    if (ele.endDate != 0 && ele.starDate != 0) {
      annotations.push(
        {
          type: 'region',
          start: [getNearestTime(ele.startDate, data), 'min'],
          end: [getNearestTime(ele.endDate, data), 'max']
        },
        {
          type: 'dataMarker',
          position: [getNearestTime(ele.startDate, data), 'median'],
          text: {
            content: ele.sensor + '-' + ele.entryLog,
            style: {
              textAlign: 'left'
            }
          },
          line: {
            length: 20
          },
          point: {
            style: {
              fill: '#f5222d',
              stroke: '#f5222d'
            }
          },
          autoAdjust: false
        }
      );
    }
  }
  var config = props.chartData;

  return (
    <div style={{ width: '100%' }}>
      <Grid
        style={{ marginTop: '10px', background: '#ffff' }}
        container
        spacing={3}
      >
        <Grid item xs={10}></Grid>
        <Grid item xs={2}>
          <TextField value={value} onChange={loadData} select>
            <MenuItem value="pressure">Pressure</MenuItem>
            <MenuItem value="pn03_m" selected>
              Particle Concentration
            </MenuItem>
            <MenuItem value="temp">Temperature</MenuItem>
            <MenuItem value="hum">Relative Humidity</MenuItem>
            <MenuItem value="rawethanol">CO2</MenuItem>
            <MenuItem value="tvoc">VOCs</MenuItem>
            <MenuItem value="pn25_m">PM2.5</MenuItem>
            <MenuItem value="pm10_m">PM10</MenuItem>
          </TextField>
        </Grid>

        <Grid item xs={12}>
          {config && config.data && <Line {...config} />}
        </Grid>
      </Grid>
    </div>
  );
};

function getNearestTime(startTime, lables) {
  for (let i = 0; i < lables.length; i++) {
    if (lables[i] && lables[i + 1]) {
      const currentDate = lables[i].label;
      const nextDate = lables[i + 1].label;
      if (currentDate >= startTime && currentDate <= nextDate) {
        return currentDate;
      }
    }
  }
}

export default LogEntryAnalysis;
