import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import 'antd/dist/antd.css';
import './App.css';
import './login/login.css';
 
import { Link, Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import React, { Component } from 'react';

import Analytics from './analytics/Analytics';
import Dashboard from './dashboard/Dashboard';
import Device from './setup/Setup';
import ForgotPassword from './login/forgot-password';
import HomePage from './home/home';
import Loader from 'react-loader-spinner';
import Login from './login/login';
import { ProtectedRoute } from './_helpers/protected.route';
import Register from './login/register';
import RegisterVerification from './login/register-verification/register-verification';
import Reports from './reports/Reports';
import { Settings } from './settings/Settings';
import Setup from './setup/Setup';
import Users from './users/users';
import Client from './setup/Client';
import { ToastContainer } from 'react-toastify';
import DND from './dnd';
import Rules from './rules';
import Alerts from './alerts';
import ReportsNew from './reports/reports-new';
import subHours from 'date-fns/subHours';
import RuleView from './rules/components/RuleView';

function App() {
  return (
    <div>
      <Router>
        <Switch>
          <Route exact path="/login" component={Login}></Route>
          <Route exact path="/register" component={Register}></Route>
          <Route
            exact
            path="/verification-code"
            component={RegisterVerification}
          ></Route>
          <Route
            exact
            path="/forgot-password"
            component={ForgotPassword}
          ></Route>
          <Route exact path="/dashboard">
            <HomePage />
            <Dashboard />
          </Route>
          <Route exact path="/">
            <HomePage />
            <Dashboard />
          </Route>
          <Route exact path="/analytics">
            <HomePage />
            <Analytics />
          </Route>
          <Route exact path="/reports">
            <HomePage />
            <ReportsNew />
          </Route>
          <Route exact path="/setup">
            <HomePage />
            <Device />
          </Route>
          <Route exact path="/users">
            <HomePage />
            <Users />
          </Route>
          <Route exact path="/settings">
            <HomePage />
            <Settings />
          </Route>

          <Route exact path="/clients">
            <HomePage />
            <Client />
          </Route>
          <Route
            exact
            path={`/rules`}
            render={routeProps => (
              <>
                <HomePage />
                <Rules {...routeProps} />
              </>
            )}
          ></Route>

          <Route
            exact
            path={`/rules/:id`}
            render={routeProps => (
              <>
                <HomePage />
                <RuleView {...routeProps} />
              </>
            )}
          ></Route>
          <Route
            exact
            path={`/alerts`}
            render={routeProps => (
              <>
                <HomePage />
                <Rules {...routeProps} />
              </>
            )}
          ></Route>
        </Switch>
      </Router>
      <ToastContainer />
    </div>
  );
}

export default App;
