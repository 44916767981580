import React, { useState, useEffect } from 'react';
import {
  Button,
  CardContent,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  InputLabel,
  Typography,
  Card,
  Checkbox,
  FormControlLabel,
  Switch,
  MenuItem,
  TextField
} from '@material-ui/core';
import './setup.css';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import FileBase64 from 'react-file-base64';
import 'react-toastify/dist/ReactToastify.css';
import deviceService from '../../api/deviceService';
import { ToastContainer, toast } from 'react-toastify';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(3),
      width: '40ch'
    }
  }
}));
export class DeviceForm extends React.Component {
  constructor(props) {
    super();
    this.state = {
      props: props,
      id: 0,
      serial_number: '',
      client: '',
      facility: '',
      zone: '',
      room: '',
      x: 0,
      y: 0,
      floorPlan: null,
      location: 'indoor',
      clientData: []
    };
    this.handleChangeIndoor = this.handleChangeIndoor.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
    this.getFiles = this.getFiles.bind(this);
    this.clearFormData = this.clearFormData.bind(this);
    this.saveData = this.saveData.bind(this);
  }

  changeHandler(event) {
    const { name, value } = event.target;
    this.setState(prevState => ({
      ...prevState,
      [name]: value
    }));
  }

  uploadFile(event) {
    this.setState({ floorPlan: event.target.files[0] });
  }

  getFiles(file) {
    this.setState({ floorPlan: file.base64 });
  }

  saveData(event) {
    event.preventDefault();
    let data = {
      id: this.state.id,
      serial_number: this.state.serial_number,
      client: this.state.client,
      facility: this.state.facility,
      zone: this.state.zone,
      room: this.state.room,
      x: this.state.x,
      y: this.state.y,
      floorPlan: this.state.floorPlan,
      location: this.state.location
    };
    deviceService.saveOrUpdateData(data).then(responseData => {
      if (responseData.status === 200) {
        toast.success('Device Added Successfully', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
      } else {
        toast.error('Unable to add the device', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
      }
    });
  }

  // const getClientData = (name) => {};
  componentDidMount() {
    var c = this.state.props.sensorNumber;
    console.log(c);
    if (c != 0) {
      deviceService.getClientData(c).then(
        res => {
          if (res.data.length > 0) {
            let clientData = res.data[0];
            console.table(clientData);
            this.setState({
              id: clientData.id,
              serial_number: clientData.serial_number,
              client: clientData.client,
              facility: clientData.facility,
              zone: clientData.zone,
              room: clientData.room,
              x: clientData.x,
              y: clientData.y,
              floorPlan: clientData.floorPlan,
              location: clientData.location
            });
          }
        },
        err => {}
      );
    }
    deviceService.getClients().then(responseData => {
      if (responseData.status === 200) {
        this.setState(prevState => ({
          ...prevState,
          ['clientData']: responseData.data
        }));
      }
    });
  }
  componentDidUpdate() {}
  handleChangeIndoor(event) {
    const { name, checked } = event.target;
    var temp = '';
    if (checked == true) {
      temp = 'indoor';
    } else {
      temp = 'outdoor';
    }
    this.setState({ location: temp });
  }
  clearFormData() {
    this.setState({
      id: 0,
      serial_number: '',
      client: '',
      facility: '',
      zone: '',
      room: '',
      x: 0,
      y: 0,
      floorPlan: null,
      location: 'indoor'
    });
  }
  render() {
    return (
      <React.Fragment>
        <div>
          <ToastContainer />
          <Grid container spacing={3} style={{ padding: '20px' }}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                  <Typography
                    ariant="h6"
                    gutterBottom
                    style={{ color: '#4E98B9', fontSize: '20px' }}
                  >
                    Add New Device to Network
                  </Typography>
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                  <Button
                    variant="contained"
                    style={{ float: 'right' }}
                    onClick={this.clearFormData}
                  >
                    Reset
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ float: 'right', marginRight: '10px' }}
                    onClick={this.saveData}
                  >
                    Save
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    style={{ float: 'right', marginRight: '10px' }}
                    onClick={this.state.props.openCreateOrEditDeviceForm}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <form noValidate autoComplete="off">
          <div className="equal-height-container">
            <Grid container spacing={1}>
              <Grid container item xs={6}>
                <Card className="first">
                  <CardContent>
                    <div className="btndevice">Device Location</div>

                    <Grid container spacing={3}>
                      <Grid container item xs={6} spacing={3}>
                        <FormControl
                          style={{ width: '80%', marginBottom: '20px' }}
                        >
                          <InputLabel htmlFor="my-input">
                            Device Serial Number
                          </InputLabel>
                          <Input
                            onChange={this.changeHandler}
                            name="serial_number"
                            value={this.state.serial_number}
                            id="my-input"
                            aria-describedby="my-helper-text"
                          />
                        </FormControl>
                      </Grid>
                      <Grid container item xs={6} spacing={3}>
                        <FormControl
                          style={{ width: '80%', marginBottom: '20px' }}
                        >
                          <TextField
                            onChange={this.changeHandler}
                            name="client"
                            label="Client"
                            value={this.state.client}
                            select
                          >
                            {this.state.clientData.map(value => {
                              return (
                                <MenuItem
                                  key={value.id}
                                  value={value.clientName}
                                >
                                  {value.clientName}
                                </MenuItem>
                              );
                            })}
                          </TextField>
                        </FormControl>
                      </Grid>

                      <Grid container item xs={6} spacing={3}>
                        <FormControl
                          style={{ width: '80%', marginBottom: '20px' }}
                        >
                          <InputLabel htmlFor="my-input">Facility</InputLabel>
                          <Input
                            onChange={this.changeHandler}
                            name="facility"
                            value={this.state.facility}
                            id="my-input"
                            aria-describedby="my-helper-text"
                          />
                        </FormControl>
                      </Grid>
                      <Grid container item xs={6} spacing={3}>
                        <FormControl
                          style={{ width: '80%', marginBottom: '20px' }}
                        >
                          <InputLabel htmlFor="my-input">Zone</InputLabel>
                          <Input
                            onChange={this.changeHandler}
                            name="zone"
                            value={this.state.zone}
                            id="my-input"
                            aria-describedby="my-helper-text"
                          />
                        </FormControl>
                      </Grid>
                      <Grid container item xs={6} spacing={3}>
                        <FormControl
                          style={{ width: '80%', marginBottom: '20px' }}
                        >
                          <InputLabel htmlFor="my-input">Room</InputLabel>
                          <Input
                            onChange={this.changeHandler}
                            name="room"
                            value={this.state.room}
                            id="my-input"
                            aria-describedby="my-helper-text"
                          />
                        </FormControl>
                      </Grid>

                      <Grid container item xs={6} spacing={3}>
                        <FormControl
                          style={{ width: '80%', marginBottom: '20px' }}
                        >
                          <InputLabel htmlFor="my-input">X Position</InputLabel>
                          <Input
                            onChange={this.changeHandler}
                            name="x"
                            value={this.state.x}
                            id="my-input"
                            aria-describedby="my-helper-text"
                          />
                        </FormControl>
                      </Grid>

                      <Grid container item xs={6} spacing={3}>
                        <FormControl
                          style={{ width: '80%', marginBottom: '20px' }}
                        >
                          <InputLabel htmlFor="my-input">Y Position</InputLabel>
                          <Input
                            onChange={this.changeHandler}
                            name="y"
                            value={this.state.y}
                            id="my-input"
                            aria-describedby="my-helper-text"
                          />
                        </FormControl>
                      </Grid>

                      <Grid container item xs={6} spacing={3}>
                        <FileBase64
                          multiple={false}
                          onDone={this.getFiles.bind(this)}
                        />
                      </Grid>
                      <Grid container item xs={6} spacing={3}>
                        <Grid
                          component="label"
                          container
                          alignItems="center"
                          spacing={1}
                        >
                          <Grid item>Outdoor</Grid>
                          <Grid item>
                            <FormControlLabel
                              style={{ textTransform: 'capitalize' }}
                              control={
                                <Switch
                                  checked={this.state.location == 'indoor'}
                                  onChange={this.handleChangeIndoor}
                                  name="location"
                                  color="primary"
                                />
                              }
                              label="Indoor"
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid container item xs={6} style={{ width: '100%' }}>
                <Card style={{ width: '100%' }}>
                  <CardContent style={{ width: '100%' }}>
                    {this.state.floorPlan != null ? (
                      <img
                        src={this.state.floorPlan}
                        alt="Floor Plan Image"
                        width="95%"
                        height="100%"
                      />
                    ) : (
                      <Typography>No Floor Plan Selected</Typography>
                    )}
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </div>
        </form>
      </React.Fragment>
    );
  }
}

const AntSwitch = withStyles(theme => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex'
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    '&$checked': {
      transform: 'translateX(12px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main
      }
    }
  },
  thumb: {
    width: 12,
    height: 15,
    boxShadow: 'none'
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
    marginBottom: '20px'
  },
  checked: {}
}))(Switch);
