import React, { useState } from 'react';
import {
  TextField,
  Typography,
  Button,
  Paper,
  makeStyles
} from '@material-ui/core';
import RegisterFormData from './../RegiserFormData';
import { authenticationService } from './../service/authenticatioin-service';
import { Link } from 'react-router-dom';
const useStyles = makeStyles(theme => ({
  backImg: {
    backgroundImage: "url('./img/dark_background.jpg')"
  }
}));

export default function RegisterVerification() {
  const customClasses = useStyles();

  const [verificationCode, setVerificationCode] = useState('');

  const verifyUser = event => {
    event.preventDefault();
    authenticationService.confirmuser(verificationCode, username);
  };

  const resendCode = () => {
    authenticationService.resendCode(username);
  };

  var url = new URL(window.location.href);
  var username = url.searchParams.get('username');
  if (username == null) {
    window.location.href = '/login';
  }

  return (
    <div id="login" className={customClasses.backImg}>
      <div style={{ paddingRight: '5%', marginBottom: '3%' }}>
        <div className="absolutePositionCss">
          <span>
            <img src="./img/telosair_white.png" alt="Telosair" />
          </span>
        </div>
        <div className="mt-mr">
          <span className="deeperCss">Deeper Insights |</span>
          <span className="healthierCss"> Healthier Air</span>
        </div>
      </div>
      <Paper elevation={3} class="cardHeight">
        <form onSubmit={verifyUser}>
          {' '}
          <Typography class="welcomeCss">
            <b> Confirmation Code </b>
          </Typography>
          <p>
            Please check your email for the validation code and enter it here:
          </p>
          <div class="userNameCss">
            <TextField
              variant="standard"
              label="code"
              fullWidth
              type="number"
              name="verificationCode"
              onChange={event => setVerificationCode(event.target.value)}
            />
          </div>
          <br />
          <div class="userNameCss">
            <Link to="/register">
              <Button
                style={{ float: 'left' }}
                type="button"
                variant="contained"
                color="primary"
              >
                Back
              </Button>
            </Link>
            &nbsp;&nbsp;&nbsp;
            <Button
              variant="contained"
              color="primary"
              type="submit"
              className="pull-right"
              style={{ marginLeft: '5px' }}
            >
              Verify
            </Button>
            &nbsp;&nbsp;&nbsp;
            <Button
              onClick={resendCode}
              variant="contained"
              color="primary"
              type="button"
              className="pull-right"
            >
              Resend Code
            </Button>
            &nbsp;&nbsp;
          </div>
        </form>
        <br />
      </Paper>
    </div>
  );
}
