import React from 'react';

import HighchartsReact from 'highcharts-react-official';

import Highcharts from 'highcharts';
import _ from 'lodash';
import moment from 'moment';
import { lineChart } from './line-chart';
const ReactHighcharts = require('react-highcharts');
const TimeSeriesChart = React.memo(
  ({ config, userSettings }) => {
    const configData = _.cloneDeep(getChartConfig(config, userSettings));
    return <HighchartsReact highcharts={Highcharts} options={configData} />;
  },
  (prevProps, nextProps) => {
    if (_.isEqual(prevProps.config, nextProps.config)) {
      return true; // props are equal
    }
    return false; // props are not equal -> update the component
  }
);

const getChartConfig = (chartData, userSettings) => {
  //const xAxis = chartData.data.timeChart.xAxis;
  var date = JSON.parse(localStorage.getItem('filters'));
  const savedTimeSeriesFilters = JSON.parse(localStorage.getItem('filters'))
    .timeSeries;

  for (let i = 0; i < chartData.length; i++) {
    if (chartData[i].name === 'pn03_m' || chartData[i].name === 'pn03') {
      chartData[i]['name'] =
        'Particle Concentration (' + userSettings.pnType + ')';
      chartData[i].type = 'spline';
      chartData[i].yAxis = 0;
      chartData[i].valuePrefix = userSettings.pnType;
      chartData[i].visible = savedTimeSeriesFilters.includes(
        'particleConcentration'
      );
      chartData[i].color = ReactHighcharts.Highcharts.getOptions().colors[0];

      //chartData[i].allowDecimals = true;
    }
    if (chartData[i].name === 'pressure') {
      chartData[i]['name'] = "Pressure ('' of " + userSettings.pressure + ')';
      chartData[i].valuePrefix = " '' of " + userSettings.pressure;
      chartData[i].visible = savedTimeSeriesFilters.includes('pressure');
      chartData[i].type = 'spline';
      chartData[i].yAxis = 1;
      chartData[i].allowDecimals = true;
      chartData[i].color = ReactHighcharts.Highcharts.getOptions().colors[1];
    }

    if (chartData[i].name === 'hum') {
      chartData[i]['name'] =
        'Relative Humidity (' + userSettings.relativeHumidity + ')';
      chartData[i].valuePrefix = ' ' + userSettings.relativeHumidity;
      chartData[i].visible = savedTimeSeriesFilters.includes(
        'relativeHumidity'
      );
      chartData[i].type = 'spline';
      chartData[i].yAxis = 2;
      chartData[i].allowDecimals = true;
      chartData[i].color = ReactHighcharts.Highcharts.getOptions().colors[2];
    }
    if (chartData[i].name === 'temp') {
      chartData[i]['name'] = 'Temp (' + userSettings.temperature + ')';
      chartData[i].valuePrefix = ' ' + userSettings.temperature;
      chartData[i].visible = savedTimeSeriesFilters.includes('temp');
      chartData[i].type = 'spline';
      chartData[i].yAxis = 3;
      chartData[i].allowDecimals = true;
      chartData[i].color = ReactHighcharts.Highcharts.getOptions().colors[3];
    }
    if (chartData[i].name === 'tvoc') {
      chartData[i]['name'] = 'VOCs (' + userSettings.tvoc + ')';
      chartData[i].valuePrefix = ' ' + userSettings.tvoc;
      chartData[i].visible = savedTimeSeriesFilters.includes('tvoc');
      chartData[i].type = 'spline';
      chartData[i].yAxis = 4;
      chartData[i].allowDecimals = true;
      chartData[i].color = ReactHighcharts.Highcharts.getOptions().colors[4];
    }
    if (chartData[i].name === 'rawethanol' || chartData[i].name === 'co2') {
      chartData[i]['name'] = 'CO2 (' + userSettings.co2 + ')';
      chartData[i].valuePrefix = ' ' + userSettings.co2;
      chartData[i].visible = savedTimeSeriesFilters.includes('co2');
      chartData[i].type = 'spline';
      chartData[i].yAxis = 5;
      chartData[i].allowDecimals = true;
      chartData[i].color = ReactHighcharts.Highcharts.getOptions().colors[5];
    }

    if (chartData[i].name === 'pn25_m') {
      chartData[i]['name'] = 'PM2.5 (' + userSettings.pmType + ')';
      chartData[i].valuePrefix = ' ' + userSettings.pmType;
      chartData[i].visible = savedTimeSeriesFilters.includes('pn25_m');
      chartData[i].type = 'spline';
      chartData[i].yAxis = 6;
      chartData[i].allowDecimals = true;
      chartData[i].color = ReactHighcharts.Highcharts.getOptions().colors[6];
    }
    if (chartData[i].name === 'pm10_m') {
      chartData[i]['name'] = 'PM10 ( ' + userSettings.pmType + ')';
      chartData[i].valuePrefix = ' ' + userSettings.pmType;
      chartData[i].visible = savedTimeSeriesFilters.includes('pm10_m');
      chartData[i].type = 'spline';
      chartData[i].yAxis = 7;
      chartData[i].allowDecimals = true;
      chartData[i].color = ReactHighcharts.Highcharts.getOptions().colors[7];
    }
  }
  const dateFormt = calculateTimeRange(date.startDate, date.endDate);
  // lineChart.xAxis.min = xAxis[0];
  // lineChart.xAxis.max = xAxis[xAxis.length - 1];
  const timeZone = userSettings.timeZone;
  lineChart.xAxis.labels = {
    formatter: function () {
      var monthStr = moment(this.value).tz(timeZone).format(dateFormt);
      return monthStr;
    }
  };

  //lineChart.plotOptions.series.connectNulls = inteval;
  lineChart.tooltip = {
    formatter: function () {
      return this.points.reduce(function (s, point) {
        return s + '<br/>' + point.series.name + ': ' + point.y.toFixed(2);
      }, '<b>' + moment(this.x).tz(timeZone).format(dateFormt) + '</b>');
    },
    shared: true
  };

  lineChart.series = chartData;
  //lineChart.xAxis.categories = res.data.timeChart.xAxis;
  //

  if (lineChart.series[0].data.length === 0) {
    lineChart.title.text = 'No Data Available';
  } else {
    lineChart.title.text = ' ';
  }

  return lineChart;
};

function calculateTimeRange(startDate, endDate) {
  var a = moment(startDate);
  var b = moment(endDate);

  var diff = b.diff(a, 'days');
  if (diff <= 1) {
    return 'HH:mm';
  } else if (diff > 1 && diff <= 30) {
    return 'MM/DD HH:mm';
  } else if (diff >= 30 && diff <= 365) {
    return 'MM/DD';
  } else if (diff >= 365) {
    return 'yyyy/MM/DD';
  }
}

export default TimeSeriesChart;
