import { Button, Grid, Card, CardContent } from '@material-ui/core';
import { ThreeSixtyTwoTone } from '@material-ui/icons';
import { Typography } from 'antd';
import { sub, subHours } from 'date-fns';
import React from 'react';
import RenderFilter from './components/RenderFilter';
import SettingsService from '../settings/SettingsService';
import RenderAlertData from './components/RenderAlertData';

import { Link } from 'react-router-dom';
export default class Alerts extends React.PureComponent {
  constructor() {
    super();

    this.state = {
      date: [],
      data: [],
      searchString: '*'
    };
    this.changeDate = this.changeDate.bind(this);
  }

  componentDidMount() {
    const query = new URLSearchParams(this.props.location.search);
    this.setState({
      date: [parseInt(query.get('startDate')), parseInt(query.get('endDate'))]
    });
    const data = {
      startDate: parseInt(query.get('startDate')),
      endDate: parseInt(query.get('endDate')),
      searchString: this.state.searchString
    };
    SettingsService.getAletHistory(data).then(responseData => {
      this.setState({ data: responseData.data });
    });
  }

  changeDate(date) {
    console.log(date);
    this.setState({ date: date });
    const data = {
      startDate: date[0].getTime(),
      endDate: date[1].getTime(),
      searchString: this.state.searchString
    };

    const searchParams = new URLSearchParams(this.props.location.search);
    searchParams.set('startDate', date[0].getTime());
    searchParams.set('endDate', date[1].getTime());

    this.props.history.replace({
      pathname: '/alerts',
      search: searchParams.toString()
    });
    SettingsService.getAletHistory(data).then(responseData => {
      this.setState({ data: responseData.data });
    });

    localStorage.setItem('alertStartDate', date[0].getTime());
    localStorage.setItem('alertEndDate', date[1].getTime());
  }
  render() {
    console.log('testing');
    return (
      <>
        <Card>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <Typography
                style={{ marginLeft: '25px', marginTop: '25px' }}
                variant="srOnly"
                component="h4"
              >
                Alerts
              </Typography>
            </Grid>

            <Grid item xs={4}>
              <Link to="/rules">
                <Button
                  style={{
                    float: 'right',
                    marginTop: '12px',
                    marginRight: '6px'
                  }}
                  variant="contained"
                  color="primary"
                >
                  View Rules
                </Button>
              </Link>
              <Link to="/rules?action=create">
                <Button
                  style={{
                    float: 'right',
                    marginTop: '12px',
                    marginRight: '6px'
                  }}
                  variant="contained"
                  color="primary"
                >
                  Create Rules
                </Button>
              </Link>
            </Grid>
          </Grid>
        </Card>
        <hr />
        <RenderFilter changeDate={this.changeDate} date={this.state.date} />
        <RenderAlertData data={this.state.data} />
      </>
    );
  }
}
