const Highcharts = require("react-highcharts");
const ReactHighcharts = require("react-highcharts");

export const columnRange = {
    chart: {
        type: "boxplot",
        zoomType: "xy",
        height: 300,
    },

    title: {
        text: "",
    },

    xAxis: {
        categories: [],
        title: {
            text: "",
            visible: false,
        },
    },
    yAxis: {
        floor: 0,
        title: {
            text: "PN03_M uq/m3",
        },
    },

    tooltip: {
        valueSuffix: "°C",
    },

    legend: {
        enabled: false,
    },

    series: [],
};
