export function utcDateConversion(eventValue) {
  const dateObj = new Date(eventValue);
  var egetYear = dateObj.getFullYear();
  var egetMonth = dateObj.getMonth();
  var egetHours = dateObj.getHours();
  var egetDate = dateObj.getDate();
  var egetMinutes = dateObj.getMinutes();
  var utcMilllisecondsSinceEpoch = new Date(
    Date.UTC(egetYear, egetMonth, egetDate, egetHours, egetMinutes)
  ).getTime();
  return utcMilllisecondsSinceEpoch;
}
