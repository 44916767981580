import { DEV_DOMAIN_URL } from '../environment';
import axiosInstance from '../_helpers/interceptor';

class AnalyticsService {
  logEvent(data) {
    if (data.id && data.id != 0) {
      return axiosInstance.put(`${DEV_DOMAIN_URL}/activities`, data);
    }
    return axiosInstance.post(DEV_DOMAIN_URL + '/activities', data);
  }
  getAllLogEntries() {
    return axiosInstance.get(DEV_DOMAIN_URL + '/activities');
  }
  getAllDevices() {
    return axiosInstance.get(DEV_DOMAIN_URL + '/device/get-all-devices');
  }
  getDistinctDevices() {
    return axiosInstance.get(DEV_DOMAIN_URL + '/device/get-distinct-devices');
  }
  loadAnyaticalData(data) {
    return axiosInstance.post(DEV_DOMAIN_URL + '/activities/analysis', data);
  }
  getZones(data) {
    return axiosInstance.post(DEV_DOMAIN_URL + '/device/get-all-zones', data);
  }
  getRooms(data) {
    return axiosInstance.post(DEV_DOMAIN_URL + '/device/get-all-rooms', data);
  }
  getClientData(name) {
    return axiosInstance.get(DEV_DOMAIN_URL + '/device/getDevice/' + name);
  }
  getFilteredClientData(data) {
    return axiosInstance.post(DEV_DOMAIN_URL + '/device/getFilteredData', data);
  }
  deleteDevice(id) {
    return axiosInstance.delete(
      DEV_DOMAIN_URL + '/device/deleteDeviceById/' + id
    );
  }
  getAllTimeTrends(data) {
    return axiosInstance.post(
      DEV_DOMAIN_URL + '/analytics/get-temporal-trends',
      data
    );
  }
  getDailyHeatMap(data) {
    return axiosInstance.post(
      DEV_DOMAIN_URL + '/analytics/get-daily-heat-trends',
      data
    );
  }
  deleteLogEntry(id) {
    return axiosInstance.post(`${DEV_DOMAIN_URL}/activities/delete`, id);
  }
  getArchivedLogActivity() {
    return axiosInstance.get(DEV_DOMAIN_URL + '/activities/archived');
  }

  restoreLogActivity(id) {
    return axiosInstance.put(`${DEV_DOMAIN_URL}/activities/archived`, id);
  }

  getTrends(data) {
    return axiosInstance.post(`${DEV_DOMAIN_URL}/analytics/trend`, data);
  }
}

export default new AnalyticsService();
