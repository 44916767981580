import {
  Typography,
  TextField,
  Button,
  makeStyles,
  Paper
} from '@material-ui/core';
import React, { useState } from 'react';
import { authenticationService } from './service/authenticatioin-service';

const useStyles = makeStyles(theme => ({
  backImg: {
    backgroundImage: "url('./img/dark_background.jpg')"
  }
}));

export default function ForgotPassword() {
  const customClasses = useStyles();
  const [email, setEmail] = useState(null);
  const [verificationCode, setVerificationCode] = useState(null);
  const [newPassword, setNewPassword] = useState(null);
  const [confirmNewPassword, setConfirmNewPassword] = useState(null);
  const forgotPassword = event => {
    event.preventDefault();
    authenticationService.forgotPassword(email);
  };
  var url = new URL(window.location.href);
  var username = url.searchParams.get('username');

  const changePassword = event => {
    event.preventDefault();
    if (newPassword == confirmNewPassword) {
      authenticationService.restPassword(
        username,
        verificationCode,
        newPassword
      );
    } else {
      alert("Passwords didn't match");
    }
  };

  return (
    <div id="login" className={customClasses.backImg}>
      <div style={{ paddingRight: '5%', marginBottom: '3%' }}>
        <div className="absolutePositionCss">
          <span>
            <img src="./img/telosair_white.png" alt="Telosair" />
          </span>
        </div>
        <div className="mt-mr">
          <span className="deeperCss">Deeper Insights |</span>
          <span className="healthierCss"> Healthier Air</span>
        </div>
      </div>
      <Paper elevation={3} class="cardHeight">
        <Typography class="welcomeCss">
          <b>Forgot Password!</b>{' '}
        </Typography>
        {username == undefined ? (
          <div>
            Please enter the email address for your account. A verification code
            will be send to you. Once you have received the verification code.
            you will be able to choose a new password for your account.
            <form onSubmit={forgotPassword}>
              <div class="userNameCss">
                <TextField
                  variant="standard"
                  label="Email"
                  required
                  fullWidth
                  onChange={event => setEmail(event.target.value)}
                />
              </div>
              <div class="userNameCss">
                <Button
                  variant="contained"
                  color="primary"
                  style={{ float: 'left' }}
                  href="login"
                >
                  Back
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  style={{ float: 'right' }}
                >
                  Reset Password
                </Button>
              </div>
            </form>
          </div>
        ) : (
          <div>
            Verification code is sent to the mail
            <form onSubmit={changePassword}>
              <div class="userNameCss">
                <TextField
                  variant="standard"
                  label="Verification Code"
                  required
                  fullWidth
                  name="Verification Code"
                  type="number"
                  onChange={event => setVerificationCode(event.target.value)}
                />
              </div>
              <div class="userNameCss">
                <TextField
                  variant="standard"
                  label="New Password"
                  required
                  fullWidth
                  name="newPassword"
                  type="password"
                  onChange={event => setNewPassword(event.target.value)}
                ></TextField>
              </div>
              <div class="userNameCss">
                <TextField
                  variant="standard"
                  label="Confirm New Password"
                  required
                  fullWidth
                  name="confirmNewPassword"
                  type="password"
                  onChange={event => setConfirmNewPassword(event.target.value)}
                ></TextField>
              </div>

              <div class="userNameCss">
                <Button
                  variant="contained"
                  color="primary"
                  style={{ float: 'left' }}
                  href="login"
                >
                  Back
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  style={{ float: 'right' }}
                >
                  Reset Password
                </Button>
              </div>
            </form>
          </div>
        )}

        <br />
        <br />
        <br />
      </Paper>
    </div>
  );
}
