export default class RegisterFormData {
  userName;
  password;
  firstName;
  lastName;

  constructor(data) {
    this.userName = data.userName;
    this.password = data.password;
    this.firstName = data.firstName;
    this.lastName = data.lastName;
  }
}
