import { Grid } from "@material-ui/core";
import { Typography } from "antd";
import React from "react";
import RealTimeDND from "./components/RealTimeDND";
import RenderTimeSeriesDND from "./components/TimeSeriresDND";

export default class DND extends React.PureComponent {
  render() {
    return (
      <React.Fragment>
        <Grid item xs={12} style={{ marginLeft: "30px", marginTop: "30px" }}>
          <Grid container spacing={2}>
            <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
              <Typography
                variant="h6"
                gutterBottom
                style={{ color: "#4E98B9", fontSize: "20px" }}
              >
                DND Real Time
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item md={6} lg={6}>
            <RealTimeDND />
          </Grid>

          <Grid item md={6} lg={6}>
            <RenderTimeSeriesDND />
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}
