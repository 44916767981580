import { areaChart } from '../dashboard/charts/area-chart';

export const getBoxPlotChartConfig = (parameter, userSettings1) => {
  const tempData = JSON.parse(JSON.stringify(areaChart));
  if (parameter === 'pn03_m') {
    tempData.yAxis.title.text = ' ' + userSettings1.pnType;
    tempData.tooltip.valueSuffix = ' ' + userSettings1.pnType;
  }
  if (parameter === 'temp') {
    tempData.yAxis.title.text = ' ' + ' ' + userSettings1.temperature;
    tempData.tooltip.valueSuffix = ' ' + userSettings1.temperature;
  }
  if (parameter === 'hum') {
    tempData.yAxis.title.text = ' ' + userSettings1.relativeHumidity;
    tempData.tooltip.valueSuffix = ' ' + userSettings1.relativeHumidity;
  }
  if (parameter === 'rawethanol') {
    tempData.yAxis.title.text = ' ' + userSettings1.co2;
    tempData.tooltip.valueSuffix = ' ' + userSettings1.co2;
  }
  if (parameter === 'tvoc') {
    tempData.yAxis.title.text = ' ' + userSettings1.tvoc;
    tempData.tooltip.valueSuffix = ' ' + userSettings1.tvoc;
  }
  if (parameter === 'pressure') {
    tempData.yAxis.title.text = '"" of ' + userSettings1.pressure;
    tempData.tooltip.valueSuffix = ' "" of ' + userSettings1.pressure;
  }

  if (parameter === 'pn25_m') {
    tempData.yAxis.title.text = ' ' + userSettings1.pnType;
    tempData.tooltip.valueSuffix = ' ' + userSettings1.pnType;
  }
  if (parameter === 'pm10_m') {
    tempData.yAxis.title.text = ' ' + userSettings1.pnType;
    tempData.tooltip.valueSuffix = ' ' + userSettings1.pnType;
  }
  return tempData;
};

export const textToNumber = {
  SUNDAY: 1,
  MONDAY: 2,
  TUESDAY: 3,
  WEDNESDAY: 4,
  THURSDAY: 5,
  FRIDAY: 6,
  SATURDAY: 7
};

export const numberToText = {
  1: 'Sunday',
  2: 'Monday',
  3: 'Tuesday',
  4: 'Wednesday',
  5: 'Thursday',
  6: 'Friday',
  7: 'Saturday'
};

export const weeklyMapObj = {
  1: 'Sunday',
  2: 'Monday',
  3: 'Tuesday',
  4: 'Wednesday',
  5: 'Thursday',
  6: 'Friday',
  7: 'Saturday'
};

export const monthMapObj = {
  1: 'Jan',
  2: 'Feb',
  3: 'Mar',
  4: 'Apr',
  5: 'May',
  6: 'Jun',
  7: 'Jul',
  8: 'Aug',
  9: 'Sep',
  10: 'Oct',
  11: 'Nov',
  12: 'Dec'
};

export function sort(o) {
  return Object.keys(o)
    .sort(
      (a, b) =>
        new Date(a.split(' ')[0]).getTime() -
        new Date(b.split(' ')[0]).getTime()
    )
    .reduce((a, k) => {
      a[k] = o[k];
      return a;
    }, {});
}
